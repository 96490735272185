async function checkIfLoggedin() {
    // check if cookie companyID , key and secret exists
    if (
        document.cookie.includes('companyID') &&
        document.cookie.includes('key') &&
        document.cookie.includes('secret')
    ) {
        return true
    } else {
        return false
    }
}

export default checkIfLoggedin
