import React, { useEffect } from 'react'
import CountrySelector from './countryselector'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import axios from 'axios'
import CryptoJS from 'crypto-js'

function Input(props) {
    let companyID = (
        document.cookie
            .split('; ')
            .find((row) => row.startsWith('companyID')) ?? ''
    ).split('=')[1]
    let key = (
        document.cookie.split('; ').find((row) => row.startsWith('key')) ?? ''
    ).split('=')[1]
    let secret = (
        document.cookie.split('; ').find((row) => row.startsWith('secret')) ??
        ''
    ).split('=')[1]
    const [value, setValue] = React.useState(props.value ?? '')

    const uniqueID =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
    const [countedChars, setCountedChars] = React.useState(0)
    const [checked, setChecked] = React.useState(props.checked ?? false)

    switch (props.type) {
        case 'textarea':
            return [
                <div className={props.inline === true ? 'flex' : ''}>
                    <label
                        htmlFor="email"
                        className={
                            'text-sm font-bold leading-6 text-gray-900 first-letter:uppercase ml-1 ' +
                            (props.inline != null
                                ? ' inline-block  w-1/2 mr-2 '
                                : 'block')
                        }
                    >
                        {props.label}
                    </label>
                    <div
                        className={
                            'mt-1' +
                            (props.inline === true ? 'inline-block' : 'block')
                        }
                    >
                        <textarea
                            style={{ whiteSpace: 'pre-wrap' }}
                            onKeyUp={(event) => {
                                setCountedChars(event.target.value.length)
                            }}
                            onChange={props.onChange ?? null}
                            rows={props.rows ?? '7'}
                            type={props.type ?? 'text'}
                            name={props.name ?? ''}
                            id={props.id ?? ''}
                            className={
                                props.className +
                                ' block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset outline-none focus:ring-orange-600 sm:text-sm sm:leading-6'
                            }
                            placeholder={props.placeholder ?? ''}
                        >
                            {props.value
                                ? props.value
                                      .replace(/\\n/g, '\n')
                                      .replaceAll('\\', '')
                                : ''}
                        </textarea>
                    </div>
                    {props.help ? <HelpIcon help={props.help} /> : ''}
                    {props.inlineHelp ? (
                        <HelpIcon inlineHelp={props.inlineHelp} />
                    ) : (
                        ''
                    )}
                    <span className={'text-[12px] italic opacity-60'}>
                        {props.advicedChars
                            ? 'Aanbevolen aantal karakters: ' +
                              props.advicedChars +
                              (countedChars > 0
                                  ? ' (' + countedChars + ' gebruikt)'
                                  : '')
                            : ''}
                    </span>
                    <p
                        className="mt-2 text-sm text-gray-500"
                        id="email-description"
                    >
                        {props.description}
                    </p>
                </div>,

                <p className="text-[13px] mb-5 opacity-50 hidden inlinehelp p-3 bg-gray-200 rounded-xl text-black">
                    {props.inlineHelp}
                </p>,
            ]
            break

        case 'checkbox':
            return [
                <div className={'flex'}>
                    <div
                        className={
                            'mt-1 ' +
                            (props.inline != null
                                ? ' inline-block w-5 '
                                : 'inline-block w-[calc(100%-40px)]')
                        }
                    >
                        <span>{props.prefix ?? ''}</span>
                        <input
                            className={
                                props.className +
                                ' w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                            }
                            type={props.type ?? 'text'}
                            name={props.name ?? ''}
                            id={props.id ?? ''}
                            onChange={function (event) {
                                setChecked(!checked)
                                props.onChange(event)
                            }}
                            placeholder={props.placeholder ?? ''}
                            checked={checked}
                            value={props.value ?? ''}
                        />
                    </div>

                    <label
                        htmlFor="email"
                        className={
                            'text-sm font-bold leading-6 text-gray-900 first-letter:uppercase ml-1 ' +
                            (props.inline !== null
                                ? 'inline-block mr-2 w-1/2 '
                                : 'block')
                        }
                    >
                        {props.label}
                    </label>
                    {props.help ? <HelpIcon help={props.help} /> : ''}
                    {props.inlineHelp ? (
                        <HelpIcon inlineHelp={props.inlineHelp} />
                    ) : (
                        ''
                    )}
                    <p
                        className="mt-2 text-sm text-gray-500"
                        id="email-description"
                    >
                        {props.description}
                    </p>
                </div>,
                <p className="text-[13px] mb-5 opacity-50 hidden inlinehelp p-3 bg-gray-200 rounded-xl text-black">
                    {props.inlineHelp}
                </p>,
            ]
            break

        case 'select':
            console.log(props.value)
            return [
                <div className={''}>
                    <label
                        htmlFor="email"
                        className={
                            'text-sm font-bold leading-6 text-gray-900 first-letter:uppercase ml-1 ' +
                            (props.inline == true
                                ? 'inline-block w-1/2 mr-2 align-top'
                                : 'block')
                        }
                    >
                        {props.label}
                    </label>
                    <div
                        className={
                            'mt-1 ' +
                            (props.inline === true ? 'inline-block' : 'block')
                        }
                    >
                        <select
                            name={props.name ?? ''}
                            id={props.id ?? ''}
                            onChange={props.onChange}
                            multiple={props.multiple ?? false}
                            className={
                                props.className +
                                ' block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset outline-none focus:ring-orange-600 sm:text-sm sm:leading-6 ' +
                                (props.help || props.inlineHelp
                                    ? props.inline === true
                                        ? ' inline-block grow '
                                        : ' !inline-block !w-[calc(100%-40px)]'
                                    : '')
                            }
                            defaultValue={props.value ?? ''}
                        >
                            {props.options
                                ? props.options.map((option) => {
                                      return (
                                          <option
                                              disabled={
                                                  option.disabled ?? false
                                              }
                                              value={option.value}
                                          >
                                              {option.label}
                                          </option>
                                      )
                                  })
                                : null}
                        </select>
                        {props.help ? <HelpIcon help={props.help} /> : ''}
                        {props.inlineHelp ? (
                            <HelpIcon inlineHelp={props.inlineHelp} />
                        ) : (
                            ''
                        )}
                    </div>
                    <p
                        className="mt-2 text-sm text-gray-500"
                        id="email-description"
                    >
                        {props.description}
                    </p>
                </div>,

                <p className="text-[13px] mb-5 opacity-50 hidden inlinehelp p-3 bg-gray-200 rounded-xl text-black">
                    {props.inlineHelp}
                </p>,
            ]
            break

        default:
            let thisValue
            if (props.type === 'number') {
                if (props.decimals === 0 && props.value > 0) {
                    thisValue = parseInt(
                        (props.value ?? '0').toString().replaceAll(',', '.'),
                    )
                } else if (props.value > 0) {
                    thisValue = parseFloat(
                        (props.value ?? '0').toString().replaceAll(',', '.'),
                    ).toFixed(props.decimals ?? 2)
                } else {
                    thisValue = props.value ?? ''
                }
            } else {
                thisValue = props.value ?? ''
            }

            return [
                <div className={props.inline === true ? 'wrap' : ''}>
                    {props.label ? (
                        <label
                            htmlFor="email"
                            className={
                                'text-sm font-bold leading-6 text-gray-900 first-letter:uppercase ml-1 ' +
                                (props.inline === true
                                    ? 'inline-block mr-2 w-1/2 '
                                    : 'block')
                            }
                        >
                            {props.label}
                        </label>
                    ) : (
                        ''
                    )}

                    {props.help ? <HelpIcon help={props.help} /> : ''}
                    {props.inlineHelp ? (
                        <HelpIcon inlineHelp={props.inlineHelp} />
                    ) : (
                        ''
                    )}
                    <div
                        className={
                            'mt-1 ' +
                            (props.inline === true
                                ? 'inline-block grow overflow-hidden '
                                : 'block')
                        }
                    >
                        {props.prefix ? (
                            <span className={'text-[18px] inline-block mr-1'}>
                                {props.prefix ?? ''}
                            </span>
                        ) : (
                            <span></span>
                        )}
                        <input
                            type={props.type ?? 'text'}
                            name={props.name ?? ''}
                            id={props.id ?? ''}
                            defaultValue={thisValue ?? ''}
                            onChange={props.onChange ?? null}
                            min={props.min ?? null}
                            max={props.max ?? null}
                            step={props.step ?? 1}
                            onBlur={props.onBlur ?? null}
                            disabled={props.disabled ?? false}
                            onClick={(e) => {
                                e.preventDefault()
                            }}
                            onDoubleClick={(e) => {
                                e.preventDefault()
                            }}
                            className={
                                props.forceClassname ??
                                props.className +
                                    ' min-w-0 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset outline-none focus:ring-orange-600 sm:text-sm sm:leading-6 ' +
                                    (props.inline === true
                                        ? ' !inline-block !w-auto '
                                        : props.help ||
                                            props.inlineHelp ||
                                            props.prefix
                                          ? ' !inline-block !w-[calc(100%-50px)] '
                                          : '')
                            }
                            placeholder={props.placeholder ?? ''}
                        />
                    </div>
                    <p
                        className="mt-2 text-sm text-gray-500"
                        id="email-description"
                    >
                        {props.description}
                    </p>
                </div>,

                <p className="text-[13px] mb-5 opacity-50 hidden inlinehelp p-3 bg-gray-200 rounded-xl text-black">
                    {props.inlineHelp}
                </p>,
            ]
            break
    }

    function HelpIcon(props) {
        // return questionmark icon
        return (
            <QuestionMarkCircleIcon
                onClick={(event) => {
                    if (props.help) {
                        window.open(props.help)
                    }
                    // find next element after event.target with class inlinehelp and toggle visibility
                    if (props.inlineHelp) {
                        // hide alle elements with class inlinehelp
                        let elements =
                            document.getElementsByClassName('inlinehelp')
                        for (let i = 0; i < elements.length; i++) {
                            elements[i].classList.add('hidden')
                        }

                        // get parent of event.target
                        let parent = event.target.parentNode.parentNode
                        // get next sibling of parent
                        let nextSibling = parent.nextSibling
                        // if next sibling is not undefined
                        if (nextSibling !== null) {
                            // if next sibling has class inlinehelp
                            if (nextSibling.classList.contains('inlinehelp')) {
                                // toggle visibility
                                nextSibling.classList.toggle('hidden')
                            }
                        }
                    }
                }}
                width={'25px'}
                className={
                    'float-right inline-block ml-[5px] cursor-pointer opacity-60 hover:opacity-100'
                }
            ></QuestionMarkCircleIcon>
        )
    }
}

export default Input
