async function checkIfAdmin() {
    // check if cookie companyID , key and secret exists
    if (
        document.cookie.includes('adminID') &&
        document.cookie.includes('adminKey') &&
        document.cookie.includes('adminSecret')
    ) {
        return true
    } else {
        return false
    }
}

export default checkIfAdmin
