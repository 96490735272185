import React from 'react'
import InputElement from './inputElement'
import { Audio } from 'react-loader-spinner'
import Geocode from 'react-geocode'
import axios from 'axios'
import ImageUpload from './imageUpload'
Geocode.setApiKey('AIzaSyDXrmfagMSZv7ApA1mkEF_tDhcVNC_43W8')
Geocode.setLanguage('en')

export default function CompanyDetails(props) {
    const [street, setStreet] = React.useState(props.company.street)
    const [city, setCity] = React.useState(props.company.city)
    const [zip, setZip] = React.useState(props.company.zip)

    async function updateStreet(streetname) {
        props.company.street = streetname
        await setStreet(streetname)
        Geocode.fromAddress(
            props.company.street + ' ' + props.company.city,
        ).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location
                console.log(lat, lng)
                axios
                    .post(
                        'https://api.escaped.online/POST/UPDATE_COMPANY_META',
                        {
                            variable: 'location',
                            value: '{"lat":' + lat + ', "lng":' + lng + '}',
                            ID: props.company.ID,
                        },
                    )
                    .then((response) => {
                        // console.log(response);
                    })
            },
            (error) => {
                console.error(error)
            },
        )
    }

    async function updateCity(cityname) {
        props.company.city = cityname
        await setCity(cityname)
        Geocode.fromAddress(
            props.company.street + ' ' + props.company.city,
        ).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location
                console.log(lat, lng)
                axios
                    .post(
                        'https://api.escaped.online/POST/UPDATE_COMPANY_META',
                        {
                            variable: 'location',
                            value: '{"lat":' + lat + ', "lng":' + lng + '}',
                            ID: props.company.ID,
                        },
                    )
                    .then((response) => {
                        // console.log(response);
                    })
            },
            (error) => {
                console.error(error)
            },
        )
    }

    if (props.company.name) {
        const openHours = JSON.parse(
            props.company.openingHours !== undefined
                ? props.company.openingHours.replaceAll('\\', '')
                : '[]',
        )

        return [
            <div
                className={
                    'w-[32vw] bg-white m-[2vh] mt-5 rounded shadow-lg p-[20px] box-border clear-none max-md:w-full'
                }
            >
                <h2 className={'mb-5'}>Company Details</h2>

                <ImageUpload
                    type={'image'}
                    width={3}
                    height={2}
                    dataType={'company_meta'}
                    company={props.company}
                    value={props.company.image}
                    label={'Room Image'}
                    variable={'image'}
                />

                <InputElement
                    company={props.company}
                    dataType={'company'}
                    variable="name"
                    label="Company Name"
                    value={props.company.name}
                />

                <InputElement
                    company={props.company}
                    dataType={'company_meta'}
                    type="textArea"
                    variable="description"
                    label="Description"
                    value={props.company.description}
                />

                <InputElement
                    company={props.company}
                    dataType={'company_meta'}
                    variable="monday_start"
                    label="Monday"
                    class="third"
                    value={
                        props.company.monday_start ??
                        (openHours[1] == undefined
                            ? ''
                            : openHours[1].hours.substring(0, 5).trim())
                    }
                />
                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="monday_end"
                    class="third"
                    value={
                        props.company.monday_end ??
                        (openHours[1] == undefined
                            ? ''
                            : openHours[1].hours.substring(14, 9).trim())
                    }
                />

                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="tuesday_start"
                    label="Tuesday"
                    class="third"
                    value={
                        props.company.tuesday_start ??
                        (openHours[2] == undefined
                            ? ''
                            : openHours[2].hours.substring(0, 5).trim())
                    }
                />
                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="tuesday_end"
                    class="third"
                    value={
                        props.company.tuesday_end ??
                        (openHours[2] == undefined
                            ? ''
                            : openHours[2].hours.substring(14, 9).trim())
                    }
                />

                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="wednesday_start"
                    label="Wednesday"
                    class="third"
                    value={
                        props.company.wednesday_start ??
                        (openHours[3] == undefined
                            ? ''
                            : openHours[3].hours.substring(0, 5).trim())
                    }
                />
                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="wednesday_end"
                    class="third"
                    value={
                        props.company.wednesday_end ??
                        (openHours[3] == undefined
                            ? ''
                            : openHours[3].hours.substring(14, 9).trim())
                    }
                />

                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="thursday_start"
                    label="Thursday"
                    class="third"
                    value={
                        props.company.thursday_start ??
                        (openHours[4] == undefined
                            ? ''
                            : openHours[4].hours.substring(0, 5).trim())
                    }
                />
                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="thursday_end"
                    class="third"
                    value={
                        props.company.thursday_end ??
                        (openHours[4] == undefined
                            ? ''
                            : openHours[4].hours.substring(14, 9).trim())
                    }
                />

                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="friday_start"
                    label="Friday"
                    class="third"
                    value={
                        props.company.friday_start ??
                        (openHours[5] == undefined
                            ? ''
                            : openHours[5].hours.substring(0, 5).trim())
                    }
                />
                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="friday_end"
                    class="third"
                    value={
                        props.company.friday_end ??
                        (openHours[5] == undefined
                            ? ''
                            : openHours[5].hours.substring(14, 9).trim())
                    }
                />

                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="saturday_start"
                    label="Saturday"
                    class="third"
                    value={
                        props.company.saturday_start ??
                        (openHours[6] == undefined
                            ? ''
                            : openHours[6].hours.substring(0, 5).trim())
                    }
                />
                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="saturday_end"
                    class="third"
                    value={
                        props.company.saturday_end ??
                        (openHours[6] == undefined
                            ? ''
                            : openHours[6].hours.substring(14, 9).trim())
                    }
                />

                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="sunday_start"
                    label="Sunday"
                    class="third"
                    value={
                        props.company.sunday_start ??
                        (openHours[7] == undefined
                            ? ''
                            : openHours[6].hours.substring(0, 5).trim())
                    }
                />
                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="sunday_end"
                    class="third"
                    value={
                        props.company.sunday_end ??
                        (openHours[7] == undefined
                            ? ''
                            : openHours[0].hours.substring(14, 9).trim())
                    }
                />

                <InputElement
                    dataType={'company'}
                    company={props.company}
                    variable="street"
                    value={props.company.street}
                    label="Street"
                    onChange={updateStreet}
                />

                <InputElement
                    dataType={'company'}
                    company={props.company}
                    variable="zip"
                    value={props.company.zip}
                    label="Zip code"
                />

                <InputElement
                    dataType={'company'}
                    company={props.company}
                    variable="city"
                    value={props.company.city}
                    label="City"
                    onChange={updateCity}
                />

                <InputElement
                    dataType={'company'}
                    company={props.company}
                    variable="phone"
                    value={props.company.phone}
                    label="Phone"
                />

                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="email"
                    value={props.company.email}
                    label="Email"
                />

                <InputElement
                    dataType={'company_meta'}
                    company={props.company}
                    variable="website"
                    value={props.company.website}
                    label="Website"
                />

                <InputElement
                    type={'singleToggle'}
                    dataType={'company'}
                    company={props.company}
                    value={props.company.status}
                    label={'status'}
                    variable={'status'}
                    options={[
                        'draft',
                        'checked',
                        'confirmed',
                        'published',
                        'remove',
                    ]}
                />
            </div>,
        ]
    } else {
        return (
            <companyDetails>
                <Audio
                    height="30"
                    width="30"
                    radius="9"
                    color="orange"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                />
            </companyDetails>
        )
    }
}
