import React, { useEffect, useState } from 'react'
import Loading from './loading'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import Input from './input'
import CountrySelector from './countryselector'
import NoAccessYet from './noaccessyet'
import ConsoleImageUpload from './consoleImageUpload'
import showMessage from '../functions/showmessage'

function MyCompanyDetails(props) {
    const [companyDetails, setCompanyDetails] = useState(null)
    const [openingTimes, setOpeningTimes] = useState(null)
    const [description, setDescription] = useState(null)
    const [address, setAddress] = useState(null)
    const [latlng, setLatlng] = useState(null)

    let companyID = (
        document.cookie
            .split('; ')
            .find((row) => row.startsWith('companyID')) ?? ''
    ).split('=')[1]
    let key = (
        document.cookie.split('; ').find((row) => row.startsWith('key')) ?? ''
    ).split('=')[1]
    let secret = (
        document.cookie.split('; ').find((row) => row.startsWith('secret')) ??
        ''
    ).split('=')[1]

    let updateTimeout = 0
    function update(event) {
        if (
            event.target.attributes.name !== undefined &&
            event.target.value !== undefined
        ) {
            // current time in seconds
            let requesttime = Math.floor(Date.now())
            // convert to utf8 bytes
            var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
            hmac.update(requesttime.toString() + key.toString())
            var hash = hmac.finalize()
            // convert to base64
            hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

            clearTimeout(updateTimeout)
            updateTimeout = setTimeout(function () {
                let value = event.target.value
                value = value.replace(/"/g, '`')
                value = value.replace(/'/g, '`')

                axios
                    .post(
                        'https://api.escaped.online/CONSOLE/UPDATE_COMPANY_META',
                        {
                            key: key,
                            hash: hash,
                            requesttime: requesttime,
                            companyID: companyID,
                            variable: event.target.attributes.name.value,
                            value: value,
                        },
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            showMessage('Opgeslagen')
                        } else {
                            showMessage('Opslaan mislukt')
                        }
                        console.log(response)
                    })
            }, 500)
        }
    }
    function handleFileUpload(files) {
        // current time in seconds
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        //get base64 of image
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = function () {
            //set base64 string to variable imageString
            let imageString = reader.result
            // set imageString to background of .dropzone
            document.querySelector('.dropzone').style.backgroundImage =
                `url(${imageString})`

            axios
                .post(
                    'https://api.escaped.online/CONSOLE/UPDATE_COMPANY_META',
                    {
                        key: key,
                        hash: hash,
                        requesttime: requesttime,
                        companyID: companyID,
                        variable: 'image',
                        value: imageString,
                    },
                )
                .then((response) => {
                    if (response.status === 200) {
                        showMessage('Opgeslagen')
                    } else {
                        showMessage('Opslaan mislukt')
                    }
                })
        }
    }
    function loadCompanyDetails() {
        // current time in seconds
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        axios
            .get(
                'https://api.escaped.online/CONSOLE/COMPANYDETAILS/?companyID=' +
                    companyID +
                    '&key=' +
                    key +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.data.error) {
                } else {
                    setDescription(
                        response.data.description.replaceAll('\\n', '\n') ?? '',
                    )
                    setCompanyDetails(response.data)
                    setAddress(
                        response.data.street +
                            ' ' +
                            response.data.zip +
                            ' ' +
                            response.data.city,
                    )
                }
            })
    }

    function loadOpeningTimes() {
        // current time in seconds
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        axios
            .get(
                'https://api.escaped.online/CONSOLE/OPENINGTIMES/?companyID=' +
                    companyID +
                    '&key=' +
                    key +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.data.error) {
                } else {
                    setOpeningTimes(response.data)
                }
            })
    }

    function updateLatLongAddress() {
        if (latlng == null) {
            axios
                .get(
                    'https://maps.googleapis.com/maps/api/geocode/json?address=' +
                        encodeURIComponent(address) +
                        '&key=AIzaSyDXrmfagMSZv7ApA1mkEF_tDhcVNC_43W8',
                )
                .then((response) => {
                    if (response.data.error) {
                        // console.log(response.data.error);
                    } else {
                        setLatlng(response.data.results[0].geometry.location)
                        console.log(response.data.results[0].geometry.location)
                    }
                })
        }
    }

    useEffect(() => {
        if (address != null) {
            updateLatLongAddress()
        }
        if (companyDetails == null) {
            loadCompanyDetails()
        }
        if (openingTimes == null) {
            loadOpeningTimes()
        }
    })

    console.log(companyDetails)

    if (
        !document.cookie.split('; ').find((row) => row.startsWith('companyID'))
    ) {
        return <NoAccessYet />
    }

    function OpeningTimes() {
        const days = [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
        ]
        const settings = [
            'monday_start',
            'monday_end',
            'tuesday_start',
            'tuesday_end',
            'wednesday_start',
            'wednesday_end',
            'thursday_start',
            'thursday_end',
            'friday_start',
            'friday_end',
            'saturday_start',
            'saturday_end',
            'sunday_start',
            'sunday_end',
        ]

        let openingTimesArray = []
        for (const [key, value] of days.entries()) {
            openingTimesArray.push(
                <div className={'grid grid-cols-3 gap-x-2'} key={key}>
                    <label className={'text-right mr-5'}>{value}</label>
                    {settings.map((setting, index) => {
                        if (setting.includes(value)) {
                            return (
                                <Input
                                    onChange={(event) => {
                                        update(event)
                                    }}
                                    type={'company'}
                                    key={index}
                                    name={setting}
                                    ID={companyID}
                                    value={openingTimes[setting]}
                                />
                            )
                        }
                    })}
                </div>,
            )
        }
        return openingTimesArray
    }

    // loop through object company detail
    let companyDetailsArray = []
    if (companyDetails) {
        for (const [key, value] of Object.entries(companyDetails)) {
            switch (key) {
                case 'status':
                case 'last_active':
                case 'ID':
                case 'description':
                case 'image':
                    continue

                case 'countryCode':
                    companyDetailsArray.push(
                        <Input
                            onChange={function (event) {
                                update(event)
                            }}
                            ID={companyID}
                            type={'select'}
                            options={[
                                { value: 'NL', label: 'Nederland' },
                                { value: 'BE', label: 'België' },
                            ]}
                            name={key}
                            key={key}
                            label={'Country'}
                            value={value}
                        />,
                    )
                    break

                default:
                    companyDetailsArray.push(
                        <Input
                            onChange={function (event) {
                                update(event)
                            }}
                            ID={companyID}
                            key={key}
                            name={key}
                            label={key}
                            value={value}
                        />,
                    )
                    break
            }
        }

        ;<div>Lat long:</div>
    }

    return [
        <div className={'round bg-white rounded-md shadow p-5 w-full mb-5'}>
            <div
                className={
                    'shadow m-2 text-center py-2 px-5 italic bg-orange-600 text-white text-sm w-auto inline-block rounded-[50px]'
                }
            >
                Heeft jouw bedrijf meerdere lokaties? Dan ontvang je van ons per
                lokatie een login link!
            </div>
            {companyDetails ? (
                <div className={'grid grid-cols-3 gap-x-10 max-md:grid-cols-1'}>
                    <div
                        className={
                            'grid grid-cols-2 col-span-2 gap-x-10 max-md:grid-cols-1'
                        }
                    >
                        {companyDetailsArray}
                    </div>
                    <div>
                        {latlng != null &&
                        latlng.lat != null &&
                        latlng.lng != null ? (
                            <img
                                className={'rounded-l shadow-gray-400 shadow'}
                                src={
                                    'https://maps.googleapis.com/maps/api/staticmap?center=' +
                                    latlng.lat +
                                    ',' +
                                    latlng.lng +
                                    '&zoom=16&size=400x400&key=AIzaSyDXrmfagMSZv7ApA1mkEF_tDhcVNC_43W8'
                                }
                            />
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </div>,

        <div className={'grid grid-cols-2 gap-x-10 max-md:grid-cols-1'}>
            <div
                className={
                    'round bg-white rounded-md shadow p-5 w-full mb-5 row-span-2'
                }
            >
                <h1 className={'mb-5 block font-bold'}>Openingstijden</h1>
                {openingTimes !== null ? <OpeningTimes /> : <Loading />}
            </div>
            <div className={'round bg-white rounded-md shadow p-5 w-full mb-5'}>
                <h1 className={'mb-5 block font-bold'}>Bedrijfslogo</h1>
                <ConsoleImageUpload
                    className={'min-h-[200px]'}
                    fileTypes={['.jpeg', '.jpg', '.png']}
                    handleFileUpload={(files) => {
                        handleFileUpload(files)
                    }}
                    updateValue={async (event) => {
                        await update(event)
                    }}
                    dropzoneClassName={
                        'rounded w-full h-20 bg-contain bg-center bg-no-repeat'
                    }
                    type={'image'}
                    dataType={'room_meta'}
                    value={
                        companyDetails !== null
                            ? companyDetails.image ?? '{}'
                            : '{}'
                    }
                    variable={'logo'}
                />
            </div>
            <div className={'round bg-white rounded-md shadow p-5 w-full mb-5'}>
                <h1 className={'mb-5 block font-bold'}>Omschrijving</h1>
                {description !== null ? (
                    <Input
                        rows={3}
                        onChange={(event) => {
                            update(event)
                        }}
                        ID={companyID}
                        description={
                            'We gebruiken deze omschrijving in de App bij jouw bedrijfsprofiel'
                        }
                        type={'textarea'}
                        value={description}
                        name={'description'}
                    />
                ) : (
                    <Loading />
                )}
            </div>
        </div>,
    ]
}

export default MyCompanyDetails
