import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Iframe from 'react-iframe'
import ConfigFields from './configfields'
import axios from 'axios'
import LoginForm from './loginForm'

function Configurator() {
    const { id } = useParams()
    let [company, setCompany] = useState([])
    useEffect(() => {
        console.log(process.env.API_URL ?? 'http://localhost:8080')
        console.log(id)
        axios
            .get(
                'https://api.escaped.online/GET/COMPANIES_TO_PROCESS/' +
                    (id ?? ''),
            )
            .then((response) => {
                setCompany(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    return [
        <ConfigFields company={company} />,
        // replace http:// for https:// in iframe url
        <Iframe
            url={
                company.website
                    ? company.website.replace('http://', 'https://')
                    : ''
            }
            id="previewScreen"
            className="previewScreen"
            display="block"
            position="relative"
        />,
    ]
}

export default Configurator
