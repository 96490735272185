import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import showMessage from '../functions/showmessage'
import loadRooms from '../functions/loadrooms'
import Loading from './loading'
import sendReviewInvitation from '../functions/reviews/sendreviewinvitation'

function NewReviewPopup(props) {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [rooms, setRooms] = useState([])
    const [error, setError] = useState('')
    const [playedRoom, setPlayedRoom] = useState('')
    const [status, setStatus] = useState('')
    const [showSendBtn, setShowSendBtn] = useState(true)

    useEffect(() => {
        loadRooms().then((response) => {
            setRooms(response)
        })
    }, [])

    async function checkReviewInvitation(e) {
        e.preventDefault()
        setError('')

        if (
            email.trim() === '' ||
            !email.includes('@') ||
            !email.includes('.') ||
            email.length < 5 ||
            email.length > 100
        ) {
            setError('Vul een emailadres in')
            return false
        }

        if (message.trim() === '') {
            setError('Vul een bericht in')
            return false
        }

        if (playedRoom === '') {
            setError('Selecteer een kamer')
            return false
        }

        setShowSendBtn(false)

        setStatus(
            <div className={'text-center'}>
                <Loading message={'Bezig met versturen...'}></Loading>
            </div>,
        )
        await sendReviewInvitation(email, message, playedRoom)
            .then((response) => {
                if (response === false) {
                    setStatus(
                        <div className={'text-center text-red-600'}>
                            Er is iets mis gegaan bij het versturen van de
                            uitnodiging
                        </div>,
                    )
                    setShowSendBtn(true)
                    return
                }
                setStatus(
                    <div className={'text-center text-green-600'}>
                        Uitnodiging verstuurd
                    </div>,
                )
                showMessage('Uitnodiging verstuurd')
                props.closePopUp()
            })
            .catch((error) => {
                setStatus(
                    <div className={'text-center text-red-600'}>
                        Er is iets mis gegaan bij het versturen van de
                        uitnodiging
                    </div>,
                )
                setShowSendBtn(true)
            })
    }

    return (
        <div className={'popup_bg'}>
            <div className={'popup'}>
                <div>
                    <button onClick={props.closePopUp} className={'closer'}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </button>
                </div>

                <h3 className={'text-xl font-bold'}>Nodig een reviewer uit</h3>
                <p className={'mt-2 text-sm'}>
                    Je kunt zelf actief (oud) spelers uitnodigen om een review
                    in de app te plaatsen. Ze hoeven hier zelf niet de app voor
                    te hebben. De mail die je hier verstuurt bevat een unieke
                    link waarmee ze de review kunnen plaatsen.
                </p>
                <p className={'mt-2 text-red-600'}>{error}</p>
                <form
                    onSubmit={(event) => {
                        return checkReviewInvitation(event)
                    }}
                    method={'post'}
                    className={'mt-5'}
                >
                    <div className={'mb-5'}>
                        <label
                            htmlFor="email"
                            className={'block text-sm font-bold mb-1'}
                        >
                            E-mailadres
                        </label>
                        <input
                            type="email"
                            onChange={(e) => {
                                setEmail(e.target.value.trim())
                            }}
                            name={'email'}
                            id={'email'}
                            className={
                                'w-full p-3 border border-gray-300 rounded'
                            }
                            placeholder={'E-mailadres van de reviewer'}
                        />
                    </div>
                    <div className={'mb-5'}>
                        <label
                            htmlFor="message"
                            className={'block text-sm font-bold mb-1'}
                        >
                            Bericht
                        </label>
                        <textarea
                            name={'message'}
                            onChange={(e) => {
                                setMessage(e.target.value.trim())
                            }}
                            id={'message'}
                            className={
                                'w-full p-3 border border-gray-300 rounded'
                            }
                            placeholder={'Stuur een bericht naar de reviewer'}
                        ></textarea>
                    </div>

                    <div className={'mb-5'}>
                        <label
                            htmlFor="room"
                            className={'block text-sm font-bold mb-1'}
                        >
                            Kamer
                        </label>
                        <select
                            name={'room'}
                            onChange={(e) => {
                                setPlayedRoom(e.target.value)
                            }}
                            id={'room'}
                            className={
                                'w-full p-3 border border-gray-300 rounded'
                            }
                        >
                            <option selected disabled value={''}>
                                Selecteer een kamer
                            </option>
                            {rooms.map((room) => {
                                return (
                                    <option key={room.ID} value={room.ID}>
                                        {room.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <span>{status}</span>

                    {showSendBtn ? (
                        <div className={'text-right'}>
                            <button type={'submit'} className={'btn primary'}>
                                Verstuur
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                </form>
            </div>
        </div>
    )
}

export default NewReviewPopup
