import { Buffer } from 'buffer'

async function replaceInlineImagesForBlob(html) {
    let htmlBody = html

    // Find all base64 image tags in the html message.
    const base64ImageTags =
        htmlBody.match(
            /<img src="data:image\/(png|jpeg|gif);base64,([^"]+)"[^>]*>/gm,
        ) || []

    const response = new Promise((resolve, reject) => {
        const inlineImages = []

        let i = 0
        base64ImageTags.forEach(async (base64ImageTag) => {
            // get all data between "" of  src in base64ImageTag including data:image/png;base64,
            const base64Data = base64ImageTag.match(
                /src="data:image\/(png|jpeg|gif);base64,([^"]+)"/,
            )[2]
            const format = base64ImageTag.match(
                /src="data:image\/(png|jpeg|gif);base64,([^"]+)"/,
            )[1]

            const imageName = `image${Math.floor(Math.random() * 1000000)}@cid`
            const newImageTag = base64ImageTag.replace(
                /src="[^"]+"/,
                `src="cid:${imageName}"`,
            )

            htmlBody = htmlBody.replace(base64ImageTag, newImageTag)

            inlineImages.push({
                data: new Blob([Buffer.from(base64Data, 'base64')], {
                    type: 'image/' + format,
                }),
                filename: 'room' + i + '.png',
                cid: imageName,
            })

            i++
            if (i === base64ImageTags.length) {
                resolve({ inlineImages: inlineImages, htmlBody: htmlBody })
            }
        })
    }).then((response) => {
        return response
    })

    return response.then((response) => {
        return {
            htmlBody: response.htmlBody,
            inlineImages: response.inlineImages,
        }
    })
}

export default replaceInlineImagesForBlob
