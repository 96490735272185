import { React, useEffect, useState } from 'react'
import Room from './room'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

export default function Rooms(props) {
    let [rooms, setRooms] = useState(props.roomsArray)

    useEffect(() => {
        if (!rooms) {
            setRooms(props.roomsArray)
        }
    })
    function addNewRoom() {
        axios
            .post('https://api.escaped.online/POST/ADD_ROOM', {
                companyID: props.company.ID,
            })
            .then((response) => {
                setRooms(response.data)
            })
    }

    return [
        <div
            className={
                'w-[32vw] bg-white m-[2vh] mt-0 rounded shadow-lg p-[20px] box-border clear-none max-md:w-full'
            }
        >
            <h2>Your Rooms</h2>
            {rooms
                ? rooms.map((room) => {
                      return (
                          <Room
                              id={room.id}
                              room={room}
                              title={room.name}
                              status={room.status}
                          />
                      )
                  })
                : ''}
            <roomRule onClick={addNewRoom}>
                <title>
                    <FontAwesomeIcon icon={faPlus} /> Add new room
                </title>
            </roomRule>
        </div>,
    ]
}
