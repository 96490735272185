import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import checkIfAdmin from '../functions/login/checkifadmin'

const AdminStatistics = () => {
    const [loading, setLoading] = useState(true)
    const [statistics, setStatistics] = useState([])

    useEffect(() => {
        loadStatistics()
    }, [])

    const loadStatistics = async () => {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        await axios
            .get(
                'https://api.escaped.online/CONSOLE/STATISTICS/?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    setStatistics(response.data)
                    setLoading(false)
                } else {
                    alert(
                        'Er is een fout opgetreden, probeer het later nog eens',
                    )
                }
            })
            .catch((error) => {
                if (error.status !== 200) {
                    alert(
                        'Er is een fout opgetreden, probeer het later nog eens',
                    )
                }
            })
    }

    const statisticsList = []

    if (!loading) {
        for (const [key, value] of Object.entries(statistics)) {
            let substatistics = []
            if (value.statistics !== undefined && value.statistics !== null) {
                for (const [subkey, subvalue] of Object.entries(
                    value.statistics,
                )) {
                    substatistics.push(
                        <div
                            key={key}
                            className="mx-auto flex max-w-xs flex-col gap-y-4"
                        >
                            <dt className="text-base leading-7 text-gray-600">
                                {subvalue.label}
                            </dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                {subvalue.value}
                            </dd>
                        </div>,
                    )
                }
                statisticsList.push(
                    <div className="bg-white py-3 sm:py-4 mb-10">
                        <div
                            className={
                                'px-5 mb-10 font-black text-center uppercase'
                            }
                        >
                            {value.label}
                        </div>
                        <div className="mx-auto max-w-7xl px-2 lg:px-3">
                            <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                                {substatistics}
                            </dl>
                        </div>
                    </div>,
                )
            }
        }
    }

    return statisticsList
}

export default AdminStatistics
