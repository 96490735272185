import axios from 'axios'
import CryptoJS from 'crypto-js'

export default async function getCurrentOnboardStatus() {
    let companyid = document.cookie
        .split('; ')
        .find((row) => row.startsWith('companyID'))
        .split('=')[1]
    let key = document.cookie
        .split('; ')
        .find((row) => row.startsWith('key'))
        .split('=')[1]
    let secret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('secret'))
        .split('=')[1]

    let requesttime = Math.floor(Date.now())
    // convert to utf8 bytes
    var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
    hmac.update(requesttime.toString() + key.toString())
    var hash = hmac.finalize()
    // convert to base64
    hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

    const result = await axios.get(
        'https://api.escaped.online/CONSOLE/ONBOARDING/?companyID=' +
            companyid +
            '&key=' +
            key +
            '&hash=' +
            hash +
            '&requesttime=' +
            requesttime,
    )

    return result.data
}

export async function hideOnboardingPanel(e) {
    // fade parent of target element out
    e.target.parentElement.parentElement.parentElement.remove()

    let companyid = document.cookie
        .split('; ')
        .find((row) => row.startsWith('companyID'))
        .split('=')[1]
    let key = document.cookie
        .split('; ')
        .find((row) => row.startsWith('key'))
        .split('=')[1]
    let secret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('secret'))
        .split('=')[1]

    let requesttime = Math.floor(Date.now())
    // convert to utf8 bytes
    var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
    hmac.update(requesttime.toString() + key.toString())
    var hash = hmac.finalize()
    // convert to base64
    hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

    const result = await axios.post(
        'https://api.escaped.online/CONSOLE/ONBOARDING',
        {
            companyID: companyid,
            key: key,
            hash: hash,
            requesttime: requesttime,
            hide: true,
        },
    )
}
