function showMessage(message) {
    // unique id
    let id = Math.random().toString(36).substr(2, 9)
    // add div to page
    let div = document.createElement('div')
    div.id = id
    div.className =
        'fixed bottom-5 right-5 bg-orange-600 text-white p-2 rounded z-[9999]'
    div.innerHTML = message ?? 'Opgeslagen'

    document.body.appendChild(div)

    // remove div after 3 seconds
    setTimeout(() => {
        document.getElementById(id).remove()
    }, 3000)
}

export default showMessage
