import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage, faPause, faPlus } from '@fortawesome/free-solid-svg-icons'
import { TagIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import loadReviews from '../functions/reviews/loadreviews'
import pauseIcon from '@heroicons/react/20/solid/esm/PauseIcon'
import pauseReview from '../functions/reviews/pausereview'
import Loading from './loading'
import NewReviewPopup from './newreview'

function Myreviews() {
    const [reviews, setReviews] = useState(null)
    const [newReviewPopup, setNewReviewPopup] = useState(false)

    useEffect(() => {
        loadReviews().then((response) => {
            console.log(response)
            setReviews(response)
        })
    }, [])

    async function pauseReviewNow(review) {
        await pauseReview(review).catch((error) => {
            alert('Er is iets mis gegaan bij het pauzeren van de review')
        })
        loadReviews().then((response) => {
            setReviews(response)
        })
    }

    return [
        <ReviewTools></ReviewTools>,
        <Reviews></Reviews>,
        newReviewPopup ? (
            <NewReviewPopup closePopUp={closePopUp}></NewReviewPopup>
        ) : (
            <></>
        ),
    ]

    function closePopUp() {
        setNewReviewPopup(false)
    }

    function ReviewTools() {
        return (
            <div className={'text-right'}>
                <button
                    onClick={() => setNewReviewPopup(true)}
                    className={'btn primary ml-3 mb-3 inline-block'}
                >
                    <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
                    Review uitnodiging
                </button>
            </div>
        )
    }

    function Reviews() {
        if (reviews === null) {
            return <Loading />
        } else if (reviews.length === 0) {
            return <div>Er zijn geen reviews gevonden</div>
        } else {
            return (
                <div className={'grid grid-cols-2 gap-4 max-md:grid-cols-1'}>
                    {reviews.map((review, index) => (
                        <div
                            className={
                                'relative column pb-8 bg-white p-10 rounded shadow-md ' +
                                (review.status === 'paused'
                                    ? '!bg-[#FBDFD3]'
                                    : '')
                            }
                            id={review.id}
                        >
                            {review.imageUrl == null ||
                            review.imageUrl === '' ? (
                                <div
                                    className={
                                        'flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 float-left mr-5'
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faMessage}
                                        className={'text-black'}
                                    />
                                </div>
                            ) : (
                                <img
                                    className={
                                        'flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 float-left mr-5'
                                    }
                                    src={review.imageUrl}
                                    alt={''}
                                />
                            )}
                            <div
                                className={
                                    'absolute top-3 right-3 opacity-20 hover:opacity-100 cursor-pointer ' +
                                    (review.status === 'paused' ? 'hidden' : '')
                                }
                                title={
                                    'Pauzeer review voor 24 uur, we nemen contact met je op'
                                }
                                onClick={() => pauseReviewNow(review)}
                            >
                                <FontAwesomeIcon
                                    className={'cursor-pointer'}
                                    icon={faPause}
                                ></FontAwesomeIcon>
                            </div>

                            <div className="relative flex items-start space-x-3">
                                {review.type === 'comment' ? (
                                    <>
                                        <div className="min-w-0 flex-1">
                                            <div
                                                className={
                                                    'flex justify-between'
                                                }
                                            >
                                                <div>
                                                    <div className="text-sm">
                                                        <a
                                                            href={
                                                                review.person
                                                                    .href
                                                            }
                                                            className="text-black font-bold text-lg"
                                                        >
                                                            {review.person.name}
                                                        </a>
                                                    </div>
                                                    <p className="mt-0.5 text-sm text-gray-500">
                                                        Gaf {review.date} een
                                                        review
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        'bg-[#EC6906] font-bold text-xl text-white bg-black block w-9 h-9 text-center rounded-3xl p-1 '
                                                    }
                                                >
                                                    {review.value}
                                                </div>
                                            </div>

                                            <div className="mt-2 text-sm text-gray-700 clear-both w-full">
                                                <p>{review.comment}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : review.type === 'assignment' ? (
                                    <>
                                        <div>
                                            <div className="relative px-1">
                                                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                                    <UserCircleIcon
                                                        className="h-5 w-5 text-gray-500"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="min-w-0 flex-1 py-1.5">
                                            <div className="text-sm text-gray-500">
                                                <a
                                                    href={review.person.href}
                                                    className="font-medium text-gray-900"
                                                >
                                                    {review.person.name}
                                                </a>{' '}
                                                assigned{' '}
                                                <a
                                                    href={review.assigned.href}
                                                    className="font-medium text-gray-900"
                                                >
                                                    {review.assigned.name}
                                                </a>{' '}
                                                <span className="whitespace-nowrap">
                                                    {review.date}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ) : review.type === 'tags' ? (
                                    <>
                                        <div>
                                            <div className="relative px-1">
                                                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                                    <TagIcon
                                                        className="h-5 w-5 text-gray-500"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="min-w-0 flex-1 py-0">
                                            <div className="text-sm leading-8 text-gray-500">
                                                <span className="mr-0.5">
                                                    <a
                                                        href={
                                                            review.person.href
                                                        }
                                                        className="font-medium text-gray-900"
                                                    >
                                                        {review.person.name}
                                                    </a>{' '}
                                                    added tags
                                                </span>{' '}
                                                <span className="mr-0.5">
                                                    {review.tags.map((tag) => (
                                                        <Fragment
                                                            key={tag.name}
                                                        >
                                                            <a
                                                                href={tag.href}
                                                                className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                            >
                                                                <svg
                                                                    // className={classNames(tag.color, 'h-1.5 w-1.5')}
                                                                    viewBox="0 0 6 6"
                                                                    aria-hidden="true"
                                                                >
                                                                    <circle
                                                                        cx={3}
                                                                        cy={3}
                                                                        r={3}
                                                                    />
                                                                </svg>
                                                                {tag.name}
                                                            </a>{' '}
                                                        </Fragment>
                                                    ))}
                                                </span>
                                                <span className="whitespace-nowrap">
                                                    {review.date}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    ))}
                </div>
            )
        }
    }
}

export default Myreviews
