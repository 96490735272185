import { React, useState } from 'react'
import axios from 'axios'

function InputElement(props) {
    let returnList = props.labelForEveryValue
        ? []
        : [props.label ? <label>{props.label}</label> : '']
    let optionsArray = []
    let [value, setValue] = useState(
        props.value &&
            props.value.constructor === Array &&
            props.value.length > 0
            ? props.value
            : [],
    )

    function updateValue(event, dataType, value = null) {
        if (dataType) {
            value = value ?? event.target.value
            value = value.replace(/"/g, '`')
            value = value.replace(/'/g, '`')

            switch (dataType) {
                case 'company':
                    axios
                        .post(
                            'https://api.escaped.online/POST/UPDATE_COMPANY',
                            {
                                variable: removeTags(
                                    event.target.attributes.name.value,
                                ),
                                value: value,
                                ID: props.company.ID,
                            },
                        )
                        .then((response) => {
                            console.log(response)
                        })
                    break

                case 'company_meta':
                    value = value ?? event.target.value
                    value = value.replace(/"/g, '`')
                    value = value.replace(/'/g, '`')

                    axios
                        .post(
                            'https://api.escaped.online/POST/UPDATE_COMPANY_META',
                            {
                                variable: event.target.attributes.name.value,
                                value: value,
                                ID: props.company.ID,
                            },
                        )
                        .then((response) => {
                            // console.log(response);
                        })
                    break

                case 'room':
                    axios
                        .post('https://api.escaped.online/POST/UPDATE_ROOM', {
                            variable: event.target.attributes.name.value,
                            value: value,
                            ID: props.room.ID,
                        })
                        .then((response) => {
                            // console.log(response);
                        })
                    break

                case 'room_meta':
                    axios
                        .post(
                            'https://api.escaped.online/POST/UPDATE_ROOM_META',
                            {
                                variable: event.target.attributes.name.value,
                                value: value,
                                ID: props.room.ID,
                            },
                        )
                        .then((response) => {
                            // console.log(response);
                        })
                    break

                default:
                    break
            }

            if (props.onChange) {
                props.onChange(event.target.value)
            }
        }
    }

    function toggleValueAndUpdate(event, dataType) {
        // get value of clicked option
        let variable = event.target.attributes.variable.value
        let option = event.target.attributes.value.value

        // if target has class active
        if (event.target.classList.contains('active')) {
            event.target.classList.remove('active')

            axios.post('https://api.escaped.online/POST/UPDATE_ROOM_META', {
                variable: variable,
                value: option,
                ID: props.room.ID,
                remove: true,
            })
        } else {
            event.target.classList.add('active')

            axios.post('https://api.escaped.online/POST/UPDATE_ROOM_META', {
                variable: variable,
                value: option,
                ID: props.room.ID,
                append: true,
            })
        }
    }

    function singleToggleValueAndUpdate(event) {
        // get value of clicked option
        let variable = event.target.attributes.variable.value
        let option = event.target.attributes.value.value
        let dataType = event.target.attributes.dataType.value

        // get all elements with attribute variable
        document
            .querySelectorAll('[variable="' + variable + '"]')
            .forEach((element) => {
                element.classList.remove('active')
            })
        // add class active to event target
        event.target.classList.add('active')

        updateValue(event, dataType, option)
    }

    // if optionsarray is empty then show warning
    if ((!props.minValue || !props.maxValue) && props.warningIfNoMinMax) {
        returnList.push(
            <div className={'warning'}>{props.warningIfNoMinMax}</div>,
        )
    }

    switch (props.type) {
        case 'textArea':
            // add textare to returList and convert defaultValue so it show \n as new line
            // strip all html elements from value
            returnList.push(
                <textarea
                    onBlur={(event) => {
                        updateValue(event, props.dataType)
                    }}
                    name={props.variable}
                    width={props.size + '%'}
                    className={props.class}
                >
                    {removeTags(props.value ?? '') ?? ''}
                </textarea>,
            )
            break

        case 'select':
            optionsArray = []
            // make an array of options
            if (props.minValue && props.maxValue) {
                for (let i = props.minValue; i <= props.maxValue; i++) {
                    optionsArray.push(i)
                }
            } else if (props.options) {
                optionsArray = props.options
            }

            let i = -1
            returnList.push(
                <select
                    onChange={(event) => {
                        updateValue(event, props.dataType)
                        props.updateState &&
                            props.updateState(event.target.value)
                    }}
                    name={props.variable}
                    className={props.class}
                >
                    <option value="" selected>
                        -
                    </option>
                    {optionsArray.map((option) => {
                        i++

                        if (
                            props.value === option ||
                            (props.defaultValue === option &&
                                (!props.value || props.value === ''))
                        ) {
                            return (
                                <option value={option} selected>
                                    {props.optionLabels
                                        ? props.optionLabels[i] == ''
                                            ? 'Naamloze agenda'
                                            : props.optionLabels[i]
                                        : option}
                                </option>
                            )
                        }
                        return (
                            <option value={option}>
                                {props.optionLabels
                                    ? props.optionLabels[i] == ''
                                        ? 'Naamloze agenda'
                                        : props.optionLabels[i]
                                    : option}
                            </option>
                        )
                    })}
                </select>,
            )

            break

        case 'radio':
            // make an array of options
            optionsArray = props.options
            returnList.push(
                <div className={'radioGroup'}>
                    {optionsArray.map((option) => {
                        if (props.value === option) {
                            return (
                                <div>
                                    <input
                                        id={'option_' + option.slug}
                                        onChange={(event) => {
                                            updateValue(event, props.dataType)
                                        }}
                                        type="radio"
                                        name={props.variable}
                                        value={option}
                                        checked
                                    />
                                    <label for={'option_' + option.slug}>
                                        {option.slug}
                                    </label>
                                </div>
                            )
                        }
                        return (
                            <div>
                                <input
                                    onChange={(event) => {
                                        updateValue(event, props.dataType)
                                    }}
                                    id={'option_' + option.slug}
                                    type="radio"
                                    name={props.variable}
                                    value={option}
                                />
                                <label for={'option_' + option.slug}>
                                    {option.slug}
                                </label>
                            </div>
                        )
                    })}
                </div>,
            )

            break

        case 'checkbox':
            // make an array of options
            optionsArray = props.options
            returnList.push(
                <div className={'radioGroup'}>
                    {optionsArray.map((option) => {
                        if (props.value === option) {
                            return (
                                <div>
                                    <input
                                        id={'option_' + option.slug}
                                        type="checkbox"
                                        name={props.variable}
                                        value={option}
                                        checked
                                    />
                                    <label for={'option_' + option.slug}>
                                        {option.slug}
                                    </label>
                                </div>
                            )
                        }
                        return (
                            <div>
                                <input
                                    id={'option_' + option.slug}
                                    type="checkbox"
                                    name={props.variable}
                                    value={option}
                                />
                                <label for={'option_' + option.slug}>
                                    {option.slug}
                                </label>
                            </div>
                        )
                    })}
                </div>,
            )

            break

        case 'toggle':
            // make a Toggle element for every option in the options array
            optionsArray = props.options
            // sort optionsarray
            optionsArray.sort()
            returnList.push(
                <div className={'toggleGroup'}>
                    {optionsArray.map((option) => {
                        if (value.includes(option)) {
                            return (
                                <div
                                    className={'active'}
                                    onClick={toggleValueAndUpdate}
                                    variable={props.variable}
                                    value={option}
                                >
                                    {option}
                                </div>
                            )
                        }

                        return (
                            <div
                                onClick={toggleValueAndUpdate}
                                variable={props.variable}
                                value={option}
                            >
                                {option}
                            </div>
                        )
                    })}
                </div>,
            )

            break

        case 'singleToggle':
            // make a Toggle element for every option in the options array
            optionsArray = props.options
            returnList.push(
                <div className={'toggleGroup'}>
                    {optionsArray.map((option) => {
                        return (
                            <div
                                className={
                                    props.value === option ? 'active' : ''
                                }
                                onClick={singleToggleValueAndUpdate}
                                variable={props.variable}
                                dataType={props.dataType}
                                name={props.variable}
                                value={option}
                            >
                                {option}
                            </div>
                        )
                    })}
                </div>,
            )

            break

        default:
            if (props.inputForEveryValue) {
                const minValue = parseInt(props.minValue)
                const maxValue = parseInt(props.maxValue)
                for (let i = minValue; i <= maxValue; i++) {
                    let rule = []
                    if (props.labelForEveryValue) {
                        rule.push(
                            <label>
                                {props.label +
                                    ' ' +
                                    i +
                                    ' ' +
                                    (props.labelAddition ?? '')}
                            </label>,
                        )
                    }

                    if (props.subValueOptions) {
                        for (let j = 0; j < props.subValueOptions.length; j++) {
                            const subValueOption = props.subValueOptions[j]
                            rule.push(
                                <input
                                    onFocus={props.onFocus}
                                    onBlur={(event) => {
                                        updateValue(event, props.dataType)
                                    }}
                                    type="text"
                                    name={
                                        props.variable +
                                        '_' +
                                        i +
                                        '_' +
                                        subValueOption
                                    }
                                    className={props.class}
                                    defaultValue={
                                        props.meta[
                                            props.variable +
                                                '_' +
                                                i +
                                                '_' +
                                                subValueOption
                                        ] ??
                                        props.meta[props.variable + '_' + i]
                                    }
                                />,
                            )
                        }
                    } else {
                        rule.push(
                            <input
                                onFocus={props.onFocus}
                                onBlur={(event) => {
                                    updateValue(event, props.dataType)
                                }}
                                type="text"
                                name={props.variable + '_' + i}
                                className={props.class}
                                defaultValue={
                                    props.meta[props.variable + '_' + i]
                                }
                            />,
                        )
                    }

                    returnList.push(rule)
                }
            } else {
                if (props.subValueOptions) {
                    for (let j = 0; j < props.subValueOptions.length; j++) {
                        const subValueOption = props.subValueOptions[j]
                        returnList.push(
                            <input
                                onFocus={props.onFocus}
                                onBlur={(event) => {
                                    updateValue(event, props.dataType)
                                }}
                                type="text"
                                name={props.variable + '_' + subValueOption}
                                className={props.class}
                                defaultValue={
                                    props.meta[
                                        props.variable + '_' + subValueOption
                                    ] ?? props.meta[props.variable]
                                }
                            />,
                        )
                    }
                } else {
                    returnList.push(
                        <input
                            onFocus={props.onFocus}
                            onBlur={(event) => {
                                updateValue(event, props.dataType)
                            }}
                            type="text"
                            name={props.variable}
                            className={props.class}
                            defaultValue={props.value}
                        />,
                    )
                }
            }
            break
    }

    function removeTags(str) {
        if (str === null || str === '') return false
        else str = str.toString()

        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/gi, '')
    }

    return returnList
}

export default InputElement
