import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Loading from './loading'
import CryptoJS from 'crypto-js'
import showMessage from '../functions/showmessage'
import Select from '../ui/forms/select'
import Input from './input'
import LastminuteSuggestions from './LastminuteSuggestions'
import { format } from 'date-fns'
import { TrashIcon } from '@heroicons/react/20/solid'
import moment from 'moment'

const LastMinutes = () => {
    const [rooms, setRooms] = useState([])
    const [selectedRoomId, setSelectedRoomId] = useState()
    const [selectedRoom, setSelectedRoom] = useState()
    const [lastMinutes, setLastMinutes] = useState([])
    const [loadingLastMinutes, setLoadingLastMinutes] = useState(true)
    const [lastMinuteDetailsPopup, setLastMinuteDetailsPopup] = useState(false)
    const [focusedLastMinute, setFocusedLastMinute] = useState({})
    const [formErrors, setFormErrors] = useState([])

    useEffect(() => {
        loadLastMinutes()
    }, [])

    useEffect(() => {
        // update focusedLastMinute
        if (focusedLastMinute != null && lastMinutes.length > 0) {
            setSelectedRoomId(focusedLastMinute.room_id ?? 0)
        }
    }, [focusedLastMinute])

    useEffect(() => {
        // update focusedLastMinute
        setFocusedLastMinute({ ...focusedLastMinute, room_id: selectedRoomId })
        setSelectedRoom(rooms.find((room) => room.id === selectedRoomId))
    }, [selectedRoomId])

    useEffect(() => {
        setFormErrors([])
    }, [lastMinuteDetailsPopup])

    async function addNewLastMinute() {
        let newID = await addNewLastMinuteAPI()
        const newLastminutes = await loadLastMinutes()
        setFocusedLastMinute(
            newLastminutes.find((lastminute) => lastminute.ID === newID),
        )
        setLastMinuteDetailsPopup(true)
    }

    async function loadLastMinutes() {
        let companyID = (
            document.cookie
                .split('; ')
                .find((row) => row.startsWith('companyID')) ?? ''
        ).split('=')[1]
        let key = (
            document.cookie.split('; ').find((row) => row.startsWith('key')) ??
            ''
        ).split('=')[1]
        let secret = (
            document.cookie
                .split('; ')
                .find((row) => row.startsWith('secret')) ?? ''
        ).split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        return await axios
            .get(
                'https://api.escaped.online/CONSOLE/LASTMINUTES/?companyID=' +
                    companyID +
                    '&key=' +
                    key +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then(async (response) => {
                if (response.data.error === true) {
                    showMessage(
                        'Er is iets mis gegaan bij het ophalen van de last minutes',
                    )
                    return []
                } else {
                    setLastMinutes(response.data['lastminutes'])
                    setRooms(response.data['rooms'] ?? [])
                    setLoadingLastMinutes(false)
                }
                return response.data['lastminutes']
            })
            .then((lastminutes) => {
                setLoadingLastMinutes(false)
                return lastminutes
            })
            .catch((error) => {
                showMessage(
                    'Er is iets mis gegaan bij het ophalen van de last minutes',
                )
                return []
            })
    }

    async function updateLastMinute() {
        let currentFormErrors = []

        // loop through all elements check if all is set in focusedLastMinute
        for (const [key, value] of Object.entries(focusedLastMinute)) {
            if (
                (value === null ||
                    value === '' ||
                    value === undefined ||
                    value === 0) &&
                key !== 'name'
            ) {
                if (key === 'room_id') {
                    currentFormErrors.push('Kies een kamer')
                } else if (key === 'price') {
                    // set price to 0
                    setFocusedLastMinute({ ...focusedLastMinute, price: 0 })
                } else {
                    currentFormErrors.push('Vul alle velden in')
                }
            }
        }

        // check if date is not already in current lastminutes
        if (
            lastMinutes.find(
                (lastminute) =>
                    new Date(lastminute.datetime).getTime() ===
                        new Date(focusedLastMinute.datetime).getTime() &&
                    lastminute.room_id === focusedLastMinute.room_id &&
                    lastminute.ID !== focusedLastMinute.ID,
            ) !== undefined
        ) {
            currentFormErrors.push(
                'Je hebt al een last minute op deze datum en tijd geplaatst',
            )
        }

        setFormErrors(currentFormErrors)
        if (currentFormErrors.length > 0) {
            return
        }

        // convert datetime to timestamp
        // let datetime = new Date(focusedLastMinute.datetime);
        // focusedLastMinute.datetime = datetime.getFullYear() + '-' + (datetime.getMonth() + 1) + '-' + datetime.getDate() + ' ' + datetime.getHours() + ':' + datetime.getMinutes() + ':00';

        let companyID = (
            document.cookie
                .split('; ')
                .find((row) => row.startsWith('companyID')) ?? ''
        ).split('=')[1]
        let key = (
            document.cookie.split('; ').find((row) => row.startsWith('key')) ??
            ''
        ).split('=')[1]
        let secret = (
            document.cookie
                .split('; ')
                .find((row) => row.startsWith('secret')) ?? ''
        ).split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        let date = await moment(focusedLastMinute.datetime).format(
            'YYYY-MM-DD HH:mm:ss',
        )

        return await axios
            .post('https://api.escaped.online/CONSOLE/LASTMINUTES/UPDATE/', {
                companyID: companyID,
                key: key,
                hash: hash,
                requesttime: requesttime,
                value: { ...focusedLastMinute, datetime: date },
            })
            .then((response) => {
                if (response.data.error === true) {
                    console.log(response.data)
                    showMessage(
                        'Er is iets mis gegaan bij het updaten van de last minute',
                    )
                    return []
                } else {
                    console.log(response.data)
                    setLoadingLastMinutes(true)
                    loadLastMinutes()
                    setLastMinuteDetailsPopup(false)
                }
            })
            .catch((error) => {
                console.log(error)
                showMessage(
                    'Er is iets mis gegaan bij het updaten van de last minute',
                )
                return []
            })
            .then((response) => {
                setLoadingLastMinutes(false)
            })
    }

    async function addNewLastMinuteAPI() {
        let companyID = (
            document.cookie
                .split('; ')
                .find((row) => row.startsWith('companyID')) ?? ''
        ).split('=')[1]
        let key = (
            document.cookie.split('; ').find((row) => row.startsWith('key')) ??
            ''
        ).split('=')[1]
        let secret = (
            document.cookie
                .split('; ')
                .find((row) => row.startsWith('secret')) ?? ''
        ).split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        return await axios
            .post('https://api.escaped.online/CONSOLE/LASTMINUTES/ADD/', {
                companyID: companyID,
                key: key,
                hash: hash,
                requesttime: requesttime,
            })
            .then(async (response) => {
                return response.data.insertId
            })
            .catch((error) => {
                console.log(error)
                showMessage(
                    'Er is iets mis gegaan bij het aanmaken van de last minute',
                )
                return []
            })
    }

    async function deleteLastMinute(id) {
        if (
            window.confirm(
                'Weet je zeker dat je deze last minute wilt verwijderen?',
            )
        ) {
            let companyID = (
                document.cookie
                    .split('; ')
                    .find((row) => row.startsWith('companyID')) ?? ''
            ).split('=')[1]
            let key = (
                document.cookie
                    .split('; ')
                    .find((row) => row.startsWith('key')) ?? ''
            ).split('=')[1]
            let secret = (
                document.cookie
                    .split('; ')
                    .find((row) => row.startsWith('secret')) ?? ''
            ).split('=')[1]

            let requesttime = Math.floor(Date.now())
            // convert to utf8 bytes
            var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
            hmac.update(requesttime.toString() + key.toString())
            var hash = hmac.finalize()
            // convert to base64
            hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

            await axios
                .delete(
                    'https://api.escaped.online/CONSOLE/LASTMINUTES?companyID=' +
                        companyID +
                        '&key=' +
                        key +
                        '&hash=' +
                        hash +
                        '&requesttime=' +
                        requesttime +
                        '&lastminuteid=' +
                        id,
                )
                .then((response) => {
                    setLoadingLastMinutes(true)
                    loadLastMinutes()
                })
                .catch((error) => {
                    console.log(error)
                    showMessage(
                        'Er is iets mis gegaan bij het verwijderen van de last minute',
                    )
                })
        }
    }

    function LastminuteTools() {
        return (
            <div className={'text-right'}>
                <button
                    onClick={addNewLastMinute}
                    className={
                        'btn primary ml-3 mb-3 inline-block cursor-pointer'
                    }
                >
                    <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
                    Nieuwe Last Minute
                </button>
            </div>
        )
    }

    function lastminuteStatus(status) {
        switch (status) {
            case 'published':
                return 'Actief'

            case 'concept':
                return 'Concept'

            case 'completed':
                return 'Voltooid'

            case 'canceled':
                return 'Geannuleerd'
        }
    }

    function lastminuteStatusColor(status) {
        switch (status) {
            case 'published':
                return 'orange-500'

            case 'concept':
                return 'gray-500'

            case 'completed':
                return 'black'

            case 'canceled':
                return 'red-600'
        }
    }

    function LastminutesList() {
        if (lastMinutes === null) {
            return <Loading></Loading>
        }

        if (lastMinutes.length === 0) {
            return (
                <div className={'block w-full'}>
                    <div className={'text-center text-2xl text-gray-500 mt-5'}>
                        Je hebt nog geen last minutes geplaatst.
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'block w-full mt-5 rounded overflow-hidden'}>
                    {lastMinutes.map((lastminute) => {
                        const date = new Date(lastminute.datetime)
                        const status = lastminuteStatus(lastminute.status)
                        const color = lastminuteStatusColor(lastminute.status)

                        return (
                            <div
                                className={
                                    'flex w-full px-5 py-4 bg-white border-b border-gray-300 hover:bg-gray-300 cursor-pointer'
                                }
                                onClick={() => {
                                    setFocusedLastMinute(lastminute)
                                    setLastMinuteDetailsPopup(true)
                                }}
                            >
                                <div
                                    className={
                                        'mr-4 text-md text-white px-2  max-sm:text-xs rounded-md bg-' +
                                        color
                                    }
                                >
                                    {status}
                                </div>
                                <div
                                    className={
                                        'mr-4 text-md max-md:hidden opacity-50'
                                    }
                                >
                                    {date.getDate() +
                                        '-' +
                                        (date.getMonth() + 1) +
                                        '-' +
                                        date.getFullYear() +
                                        ' ' +
                                        (date.getHours() < 10
                                            ? '0' + date.getHours()
                                            : date.getHours()) +
                                        ':' +
                                        (date.getMinutes() < 10
                                            ? '0' + date.getMinutes()
                                            : date.getMinutes())}
                                </div>
                                {/*datum_lang*/}
                                <div
                                    className={
                                        'mr-4 text-md md:hidden opacity-50  max-sm:text-xs'
                                    }
                                >
                                    {date.getDate() +
                                        '-' +
                                        (date.getMonth() + 1) +
                                        ' ' +
                                        (date.getHours() < 10
                                            ? '0' + date.getHours()
                                            : date.getHours()) +
                                        ':' +
                                        (date.getMinutes() < 10
                                            ? '0' + date.getMinutes()
                                            : date.getMinutes())}
                                </div>
                                {/*datum_kort*/}
                                <div className={'mr-4 text-md max-sm:text-xs'}>
                                    {lastminute.name}
                                </div>
                                <div
                                    className={
                                        'flex-1 float-right items-end text-right whitespace-nowrap max-sm:text-sm'
                                    }
                                >
                                    <div
                                        className={
                                            'inline-block text-md font-bold'
                                        }
                                    >
                                        €
                                    </div>
                                    <div
                                        className={
                                            'inline-block text-md font-bold'
                                        }
                                    >
                                        {lastminute.price ?? 0}
                                    </div>
                                </div>
                                {lastminute.status === 'concept' ? (
                                    <TrashIcon
                                        className={
                                            'w-5 h-5 ml-3 opacity-30 hover:opacity-100  max-sm:w-4'
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            deleteLastMinute(
                                                lastminute.ID,
                                            ).then(() => {
                                                loadLastMinutes()
                                            })
                                        }}
                                    />
                                ) : null}
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    function LastMinuteDetailsPopup() {
        const statusOptions = [
            {
                id: 'concept',
                name: 'Concept',
                color: 'gray-400',
                textColor: 'white',
            },
            {
                id: 'published',
                name: 'Actief',
                color: 'orange-500',
                textColor: 'white',
            },
            {
                id: 'completed',
                name: 'Voltooid',
                color: 'black',
                textColor: 'white',
            },
            {
                id: 'canceled',
                name: 'Geannuleerd',
                color: 'red-600',
                textColor: 'white',
            },
        ]

        const selected =
            focusedLastMinute != null
                ? statusOptions.find(
                      (item) => item.id === focusedLastMinute.status,
                  )
                : statusOptions[0]

        const [date, setDate] = useState(
            focusedLastMinute?.datetime !== undefined &&
                new Date(focusedLastMinute?.datetime) != 'Invalid Date'
                ? new Date(focusedLastMinute?.datetime)
                : new Date(),
        )
        const [formattedDate, setFormattedDate] = useState(
            date.getFullYear() +
                '-' +
                (date.getMonth() < 9
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1) +
                '-' +
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()),
        )
        const [formattedTime, setFormattedTime] = useState(
            (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
                ':' +
                (date.getMinutes() < 10
                    ? '0' + date.getMinutes()
                    : date.getMinutes()),
        )
        const [dateFormattedOnStart, setDateFormattedOnStart] = useState(false)

        const [updatedDate, setUpdatedDate] = useState(false)
        const [updatedTime, setUpdatedTime] = useState(false)

        useEffect(() => {
            if (updatedDate) {
                let time = format(date, 'HH:mm') + ':00'
                let newDate = new Date(updatedDate + ' ' + time).toISOString()

                setFocusedLastMinute({
                    ...focusedLastMinute,
                    datetime: newDate,
                })
            }

            if (updatedTime) {
                let time = updatedTime + ':00'
                let newDate = new Date(formattedDate + ' ' + time).toISOString()

                setFocusedLastMinute({
                    ...focusedLastMinute,
                    datetime: newDate,
                })
            }
        }, [updatedDate, updatedTime])

        if (lastMinuteDetailsPopup) {
            return (
                <div
                    className={
                        'fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 z-50'
                    }
                >
                    <div
                        className={
                            'min-h-[60vh] px-10 py-10 w-[50vw] max-h-[90vh] overflow-y-scroll overflow-x-hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow-md max-sm:w-full max-sm:max-h-[90vh] max-sm:h-[90vh]'
                        }
                    >
                        {/*Close button right op*/}
                        <button
                            className={'btn primary absolute top-4 right-4'}
                            onClick={() => {
                                setLastMinuteDetailsPopup(false)
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faClose}
                                className={'text-white'}
                            />
                        </button>

                        <h1>Last Minute Details</h1>
                        <ul className={'list-disc ml-5'}>
                            <li
                                key={focusedLastMinute.ID + '1'}
                                className={'mt-2'}
                            >
                                Last minutes met een startijd binnen de komende
                                48 uur worden in onze app getoond.
                            </li>
                            <li key={focusedLastMinute.ID + '2'} className={''}>
                                Vergeet niet om de lastminute op voltooid te
                                zetten zodra deze is geboekt.
                            </li>
                            <li
                                key={focusedLastMinute.ID + '2'}
                                className={'mb-3'}
                            >
                                Vul om een omschrijving in zodat de gebruiker
                                weet hoe die de aanbieding/last minute kan
                                bemachtigen (bellen/online boeken, etc)
                            </li>
                        </ul>

                        <Select
                            label={'Status'}
                            items={statusOptions}
                            selected={selected}
                            onChange={(value) => {
                                setFocusedLastMinute({
                                    ...focusedLastMinute,
                                    status: value,
                                })
                            }}
                        />

                        <div className={'grid grid-cols-2 gap-2'}>
                            <Input
                                id={'newdatepickerDateInput'}
                                value={formattedDate}
                                type={'date'}
                                onBlur={(e) => {
                                    setUpdatedDate(e.target.value)
                                }}
                            />
                            <Input
                                id={'newdatepickerTimeInput'}
                                value={formattedTime}
                                step={60}
                                type={'time'}
                                onBlur={(e) => {
                                    setUpdatedTime(e.target.value)
                                }}
                            />
                        </div>

                        <Select
                            label={'Room'}
                            items={rooms}
                            selected={
                                selectedRoom != null
                                    ? selectedRoom
                                    : {
                                          id: 0,
                                          name: 'Selecteer een kamer',
                                          color: 'white',
                                          textColor: 'black',
                                      }
                            }
                            onChange={(value) => {
                                setSelectedRoomId(value)
                            }}
                        />

                        <Input
                            prefix={'€'}
                            type={'number'}
                            value={focusedLastMinute.price ?? 0}
                            onBlur={(e) => {
                                setFocusedLastMinute({
                                    ...focusedLastMinute,
                                    price: e.target.value,
                                })
                            }}
                        />

                        <textarea
                            className={
                                'w-full p-3 border border-gray-300 rounded'
                            }
                            defaultValue={
                                focusedLastMinute.description ??
                                'Bel ons om deze Last Minute te boeken!'
                            }
                            placeholder={
                                'Bel ons om deze Last Minute te boeken!'
                            }
                            onBlur={(e) => {
                                setFocusedLastMinute({
                                    ...focusedLastMinute,
                                    description: e.target.value,
                                })
                            }}
                        ></textarea>

                        {formErrors.length > 0 ? (
                            <div className={'text-red-500'}>
                                {formErrors.map((error) => (
                                    <div>{error}</div>
                                ))}
                            </div>
                        ) : null}

                        <button
                            className={'btn primary mt-4'}
                            onClick={updateLastMinute}
                        >
                            Opslaan
                        </button>
                        {/*<p className={"pt-5 opacity-25"}>ID:{focusedLastMinute.ID}</p>*/}
                    </div>
                </div>
            )
        }
        return null
    }

    return [
        <LastminuteTools />,
        <LastminuteSuggestions
            focussedLastMinute={focusedLastMinute}
            setFocussedLastMinute={setFocusedLastMinute}
            showPopup={setLastMinuteDetailsPopup}
            newLastMinute={addNewLastMinute}
            saveLastminute={updateLastMinute}
        />,
        <LastminutesList />,
        <LastMinuteDetailsPopup />,
    ]
}

export default LastMinutes
