import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
} from 'mdb-react-ui-kit'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { decode as base64_decode, encode as base64_encode } from 'base-64'

function LoginForm(props) {
    const { companyid } = useParams()
    const { key } = useParams()
    const { secret } = useParams()
    const [message, setMessage] = React.useState('')
    const [showPasswordForgotten, setShowPasswordForgotten] =
        React.useState(false)
    const [emailSent, setEmailSent] = React.useState(false)

    useEffect(() => {
        checkLogOut()

        // check if hash is set, then login by hash
        if (companyid && key && secret) {
            checkLoginByUrlParams(
                companyid,
                key,
                secret,
                setLoggedIn,
                setCookie,
                true,
            )
        } else {
            checkLoggedIn()
        }
    })

    function checkLoginByForm(username, password, setLoggedIn, setCookie) {
        setLoggingIn(true)
        if (!username || username === '' || !password || password === '') {
            setMessage('Voer een gebruikersnaam en wachtwoord in')
            setLoggingIn(false)
            return false
        }

        // make md5 hash of password
        let hash = CryptoJS.MD5(password)

        axios
            .get(
                'https://api.escaped.online/CHECKADMINLOGIN/?user_email=' +
                    username +
                    '&key=' +
                    hash,
            )
            .then((response) => {
                if (response.data.error == 'true') {
                    setLoggedIn(false)
                    setLoggingIn(false)
                    setMessage('Gebruikersnaam of wachtwoord onjuist')
                    // logout();
                    console.log(response.data)
                    return false
                } else {
                    // set cookie for whole domain
                    setCookie('adminID', response.data.ID, {
                        domain: window.location.host.split(':')[0],
                        path: '/',
                    })
                    setCookie('adminKey', response.data.key, {
                        domain: window.location.host.split(':')[0],
                        path: '/',
                    })
                    setCookie('adminSecret', response.data.secret, {
                        domain: window.location.host.split(':')[0],
                        path: '/',
                    })

                    setLoggedIn(true)
                    setLoggingIn(false)
                }
            })
    }

    async function checkLoginByUrlParams(
        companyid,
        key,
        secret,
        setLoggedIn,
        setCookie,
        force = false,
    ) {
        let session = parseInt(sessionStorage.getItem('lastLoginCheck')) ?? 0

        // if current time is less than 10 seconds after last check, and cookie adminid is set return
        if (
            Math.floor(Date.now()) < session + 10000 &&
            document.cookie.includes('companyID') &&
            !force
        ) {
            return true
        } else {
            sessionStorage.setItem('lastLoginCheck', Math.floor(Date.now()))
        }

        setLoggingIn(true)

        // current time in seconds
        let requesttime = Math.floor(Date.now() / 1000)
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        // check login by url params
        await axios
            .get(
                'https://api.escaped.online/CHECKCOMPANYLOGIN/?companyID=' +
                    companyid +
                    '&key=' +
                    key +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.data.error === true) {
                    console.log(response.data)
                    logout()

                    return false
                } else {
                    // set cookie for whole domain
                    setCookie('companyID', companyid, {
                        domain: window.location.host.split(':')[0],
                        path: '/',
                    })
                    setCookie('key', key, {
                        domain: window.location.host.split(':')[0],
                        path: '/',
                    })
                    setCookie('secret', secret, {
                        domain: window.location.host.split(':')[0],
                        path: '/',
                    })

                    setLoggingIn(false)
                    setLoggedIn(true)
                    return true
                }
            })
            .catch((error) => {
                setLoggingIn(false)
                return false
            })
    }

    async function checkAdminLogin(adminid, key, secret) {
        // get session variable
        let session =
            parseInt(sessionStorage.getItem('lastAdminLoginCheck')) ?? 0

        // if current time is less than 10 seconds after last check, and cookie adminid is set return
        if (
            Math.floor(Date.now()) < session + 10000 &&
            document.cookie.includes('adminID')
        ) {
            return true
        } else {
            sessionStorage.setItem(
                'lastAdminLoginCheck',
                Math.floor(Date.now()),
            )
        }

        // current time in seconds
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        // check login by url params
        const result = await axios
            .get(
                'https://api.escaped.online/CHECKADMINLOGIN/?adminid=' +
                    adminid +
                    '&key=' +
                    key +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.data.error === 'true') {
                    console.log(response.data)
                    logout()
                    return false
                } else {
                    return true
                }
            })
            .catch((error) => {
                console.log(error)
                return false
            })

        return result
    }

    async function checkLogOut() {
        if (window.location.href.includes('/logout')) {
            await logout()
            window.location.href = '/'
        }
    }

    async function logout() {
        // delete  all cookies
        document.cookie.split(';').forEach((c) => {
            document.cookie = c
                .replace(/^ +/, '')
                .replace(
                    /=.*/,
                    '=;expires=' +
                        new Date().toUTCString() +
                        ';domain=' +
                        window.location.host.split(':')[0] +
                        ';path=/',
                )
        })
        // remove session storage
        await sessionStorage.setItem('lastLoginCheck', 0)
        await sessionStorage.setItem('lastAdminLoginCheck', 0)

        setLoggingIn(false)
        setLoggedIn(false)

        return true
    }

    async function checkLoggedIn() {
        // check if cookie companyID , key and secret exists
        if (
            document.cookie.includes('companyID') &&
            document.cookie.includes('key') &&
            document.cookie.includes('secret')
        ) {
            let companyid = document.cookie
                .split('; ')
                .find((row) => row.startsWith('companyID'))
                .split('=')[1]
            let key = document.cookie
                .split('; ')
                .find((row) => row.startsWith('key'))
                .split('=')[1]
            let secret = document.cookie
                .split('; ')
                .find((row) => row.startsWith('secret'))
                .split('=')[1]

            if (
                (await checkLoginByUrlParams(
                    companyid,
                    key,
                    secret,
                    setLoggedIn,
                    setCookie,
                )) === true
            ) {
                setLoggedIn(true)
                setLoggingIn(false)
                return true
            }
        } else if (
            document.cookie.includes('adminID') &&
            document.cookie.includes('adminKey') &&
            document.cookie.includes('adminSecret')
        ) {
            let adminID = document.cookie
                .split('; ')
                .find((row) => row.startsWith('adminID'))
                .split('=')[1]
            let adminKey = document.cookie
                .split('; ')
                .find((row) => row.startsWith('adminKey'))
                .split('=')[1]
            let adminSecret = document.cookie
                .split('; ')
                .find((row) => row.startsWith('adminSecret'))
                .split('=')[1]

            if (
                (await checkAdminLogin(adminID, adminKey, adminSecret)) === true
            ) {
                // setLoggedIn(true);
                // setLoggingIn(false);
                // #login hide
                try {
                    document.getElementById('login').style.display = 'none'
                } catch (e) {}
                return true
            } else {
                setLoggedIn(false)
                setLoggingIn(false)
                return false
            }
        } else {
            setLoggedIn(false)
            setLoggingIn(false)
            return false
        }

        setLoggedIn(false)
        setLoggingIn(false)
        return false
    }

    async function requestNewLoginLin() {
        const email = username
        if (email === '') {
            setMessage('Vul een e-mailadres in')
            return false
        }
        // check if real mailaddress
        if (!email.includes('@') || !email.includes('.')) {
            setMessage('Vul een geldig e-mailadres in')
            return false
        }

        axios
            .post('https://api.escaped.online/LOGINLINK', {
                email: email,
            })
            .then((response) => {
                if (response.data.error === 'true') {
                    setMessage(
                        'We hebben geen mail kunnen versturen. Klopt dit e-mailadres?',
                    )
                } else {
                    setEmailSent(true)
                    setMessage(
                        'Er is een e-mail verstuurd met een nieuwe login link',
                    )
                }
            })
            .catch((error) => {
                setMessage(
                    'Er is een fout opgetreden bij het versturen van de e-mail',
                )
            })
    }

    const [loggedIn, setLoggedIn] = React.useState(false)
    let [username, setUsername] = React.useState('')
    let [password, setPassword] = React.useState('')
    const [loggingIn, setLoggingIn] = React.useState(true)
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])

    if (loggedIn === true) {
        // redirect to dashboard if domain starts with /login
        if (window.location.href.includes('/login')) {
            setTimeout(function () {
                window.location.href = '/dashboard'
            }, 1000)
        }
        return
    }

    if (loggingIn === true) {
        return (
            <div id={'login'}>
                <div
                    className="d-flex flex-column ms-5 animate-pulse"
                    id={'loginForm'}
                >
                    <div className="text-center content-center">
                        <img
                            src="/images/escaped.png"
                            className={'m-auto mb-5'}
                            style={{ width: '185px' }}
                            alt="logo"
                        />
                    </div>

                    <p>Een moment geduld...</p>
                </div>
            </div>
        )
    } else {
        return !showPasswordForgotten ? (
            <div id={'login'}>
                <div className="absolute w-3/6 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-10 px-10 rounded-xl shadow-xl bg-white max-md:w-full max-md:h-full max-md:rounded-none">
                    <div className="text-center content-center">
                        <img
                            className={'m-auto'}
                            src="/images/escaped.png"
                            style={{ width: '185px' }}
                            alt="logo"
                        />
                    </div>

                    {message != '' ? (
                        <p
                            className={
                                'text-center text-red-500 font-bold mb-10 mt-10'
                            }
                        >
                            {message}
                        </p>
                    ) : (
                        <p className={'mb-10 mt-10 text-center'}>
                            Log in om verder te gaan
                        </p>
                    )}
                    <label className={'m-1 block'}>E-mailadres</label>
                    <input
                        className={
                            'mb-4 border rounded block p-2 w-full shadow'
                        }
                        type={'text'}
                        id="form1"
                        onChange={(event) => {
                            setUsername(event.target.value)
                        }}
                        type="email"
                    />
                    <label className={'m-1 block'}>Wachtwoord</label>
                    <input
                        className={
                            'mb-4 border rounded block p-2 w-full shadow'
                        }
                        type={'password'}
                        onChange={(event) => {
                            setPassword(event.target.value)
                        }}
                        type="password"
                    />

                    <div className="text-center pt-1 mb-5 pb-1">
                        <button
                            className="w-8/12 bg-orange-600 px-5 py-3 rounded text-white gradient-custom-2"
                            onClick={() => {
                                checkLoginByForm(
                                    username,
                                    password,
                                    setLoggedIn,
                                    setCookie,
                                )
                            }}
                        >
                            Log in
                        </button>
                        <div className={'mt-4 text-black'}>
                            <a
                                onClick={() => {
                                    setShowPasswordForgotten(true)
                                }}
                                className={'text-black'}
                            >
                                Kamer eigenaar en login link kwijt?
                                <br />
                                <span className={'underline cursor-pointer'}>
                                    {' '}
                                    Vraag hier jouw login link opnieuw aan!
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div id={'login'}>
                <div className="absolute w-3/6 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-10 px-10 rounded-xl shadow-xl bg-white max-md:w-full max-md:h-full max-md:rounded-none">
                    <div className="text-center content-center">
                        <img
                            className={'m-auto'}
                            src="/images/escaped.png"
                            style={{ width: '185px' }}
                            alt="logo"
                        />
                    </div>

                    {message != '' ? (
                        <p
                            className={
                                'text-center text-red-500 font-bold mb-10 mt-10'
                            }
                        >
                            {message}
                        </p>
                    ) : (
                        <p className={'mb-10 mt-10 text-center'}>
                            Vul jouw e-mailadres in om een nieuwe login link aan
                            te vragen
                        </p>
                    )}
                    {!emailSent ? (
                        <>
                            <label className={'m-1 block'}>E-mailadres</label>
                            <input
                                className={
                                    'mb-4 border rounded block p-2 w-full shadow'
                                }
                                type={'text'}
                                id="form1"
                                onChange={(event) => {
                                    setUsername(event.target.value)
                                }}
                                type="email"
                            />

                            <div className="text-center pt-1 mb-5 pb-1">
                                <button
                                    className="w-8/12 bg-orange-600 px-5 py-3 rounded text-white gradient-custom-2"
                                    onClick={requestNewLoginLin}
                                >
                                    Vraag login link aan
                                </button>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        )
    }
}

export default LoginForm
