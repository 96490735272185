import React, { useCallback, useEffect, useState } from 'react'

import checkifadmin from '../functions/login/checkifadmin'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import Loading from './loading'
import { useParams } from 'react-router-dom'

const Roomupdates = () => {
    const { roomid } = useParams()
    const [updates, setUpdates] = useState(null)
    const [rooms, setRooms] = useState(null)
    const [updatedRooms, setUpdatedRooms] = useState(null)
    const [ready, setReady] = useState(false)
    const [filteredrooms, setFilteredrooms] = useState(null)

    useEffect(() => {
        checkifadmin().then(async (response) => {
            if (roomid !== undefined) {
                loadUpdates()
            } else {
                if (rooms === null) await loadRooms()

                if (updatedRooms === null) await loadUpdatedRooms()
            }
        })
    }, [])

    useEffect(() => {
        if (rooms !== null && updatedRooms !== null) {
            // add room from rooms to filtered rooms if updated_rooms room_id is ID in rooms
            let filtered = []
            for (let i = 0; i < updatedRooms.length; i++) {
                for (let j = 0; j < rooms.length; j++) {
                    if (updatedRooms[i].room_id === rooms[j].ID) {
                        filtered.push(rooms[j])
                    }
                }
            }
            // remove duplicates and add to filteredrooms
            setFilteredrooms(
                filtered.filter(
                    (item, index) => filtered.indexOf(item) === index,
                ),
            )
        }
    }, [rooms, updatedRooms])

    useEffect(() => {
        if (filteredrooms !== null) {
            setReady(true)
        }
    }, [filteredrooms])

    async function loadUpdates() {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/UPDATES_BY_OWNER/' +
                    roomid +
                    '?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                let changes = []
                // loop through all updates and decode changes and add to array
                for (let i = 0; i < response.data.length; i++) {
                    console.log(response.data[i].changes)
                    // replace \
                    let change = JSON.parse(
                        response.data[i].changes
                            .replaceAll('\\n', '')
                            .replaceAll('\\', ''),
                    )
                    console.log(change)

                    // if value is a string
                    if (
                        change.value !== null &&
                        typeof change.value !== 'string'
                    ) {
                        change.value = (
                            <img
                                src={change.value['original']}
                                alt={change.value['original']}
                                className="w-1/2 mx-auto"
                            />
                        )
                    }

                    changes.push(change)
                }

                // order changes by datetime ASC
                changes.sort((a, b) => {
                    return new Date(b.datetime) - new Date(a.datetime)
                })

                setUpdates(changes)
                setReady(true)
            })
    }

    async function loadRooms() {
        await axios
            .get('https://api.escaped.online/GET/ROOMS')
            .then((response) => {
                setRooms(response.data)
            })
    }

    async function loadUpdatedRooms() {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/ROOMS_UPDATED_BY_OWNER/?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                setUpdatedRooms(response.data)
                console.log(response.data)
            })
    }

    async function acceptChanges() {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .post(
                'https://api.escaped.online/CONSOLE/ACCEPT_UPDATES_BY_OWNER/',
                {
                    adminid: adminID,
                    key: adminKey,
                    hash: hash,
                    requesttime: requesttime,
                    roomid: roomid,
                },
            )
            .then((response) => {
                if (response.status === 200)
                    window.location.href = '/ownerupdates'
            })
    }

    if (ready === false) {
        return <Loading />
    } else {
        if (roomid !== undefined) {
            const variables = []
            return [
                <div
                    className={
                        'bg-white p-3 inline-block rounded mb-5 cursor-pointer'
                    }
                    onClick={acceptChanges}
                >
                    Accepteer Wijzigingen
                </div>,
                updates.map((update) => {
                    if (
                        variables.includes(update.variable) &&
                        update.variable !== 'categories'
                    )
                        return

                    variables.push(update.variable)
                    let date = new Date(update.datetime).toLocaleString()

                    return (
                        <div className={'bg-white mb-3 py-2 px-4 rounded-xl'}>
                            <div className={'flex justify-between'}>
                                <div className={'font-bold'}>
                                    {update.variable}
                                </div>
                                <div className={'text-sm opacity-50'}>
                                    {date}
                                </div>
                            </div>
                            {update.value}
                        </div>
                    )
                }),
            ]
        }

        if (filteredrooms.length === 0) {
            return (
                <div className={'block w-full'}>
                    <div className={'text-center text-md text-gray-500 mt-5'}>
                        Er zijn geen kamers gewijzigd
                    </div>
                </div>
            )
        }

        return filteredrooms.map((room) => {
            let image = JSON.parse(room.image.replaceAll('\\', ''))['small']
            return (
                <div
                    className="room"
                    onClick={() => {
                        window.location.href = '/ownerupdates/' + room.ID
                    }}
                >
                    <div
                        className={'image'}
                        style={{ backgroundImage: "url('" + image + "')" }}
                    ></div>
                    <div className={'title'}>{room.name ?? ''}</div>
                    <p className={'description'}>{room.description ?? ''}</p>
                </div>
            )
        })
    }
}

export default Roomupdates
