import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import Loading from './loading'
import Room from './room'
import { useParams } from 'react-router-dom'
import Input from './input'
import NoAccessYet from './noaccessyet'
import ConsoleImageUpload from './consoleImageUpload.js'
import {
    BuildingOfficeIcon,
    CheckIcon,
    ChevronUpDownIcon,
    CreditCardIcon,
    PlusCircleIcon,
    TrashIcon,
    UserIcon,
    UsersIcon,
} from '@heroicons/react/20/solid'
import eyeIcon from '@heroicons/react/20/solid/esm/EyeIcon'
import cogIcon from '@heroicons/react/20/solid/esm/CogIcon'
import currencyEuroIcon from '@heroicons/react/20/solid/esm/CurrencyEuroIcon'
import calendarIcon from '@heroicons/react/20/solid/esm/CalendarIcon'
import phoneIcon from '@heroicons/react/20/solid/esm/PhoneIcon'
import devicePhoneMobileIcon from '@heroicons/react/20/solid/esm/DevicePhoneMobileIcon'
import showMessage from '../functions/showmessage'
import checkIcon from '@heroicons/react/20/solid/esm/CheckIcon'
import starIcon from '@heroicons/react/20/solid/esm/StarIcon'
import loadRooms from '../functions/loadrooms'
import InputElement from './inputElement'
import { Listbox, Transition } from '@headlessui/react'

function MyRooms(props) {
    const [rooms, setRooms] = useState(false)
    const [roomLoading, setRoomLoading] = useState(false)
    const { roomID } = useParams()
    const [room, setRoom] = useState(false)
    const [calendarIcs, setCalendarIcs] = useState(null)
    const [calendarIcsLoading, setCalendarIcsLoading] = useState(false)
    const [predictedBlocks, setPredictedBlocks] = useState(null)
    const [predictedBlocksLoading, setPredictedBlocksLoading] = useState(false)
    const [freeSpots, setFreeSpots] = useState(false)
    const [freeSpotsLoading, setFreeSpotsLoading] = useState(false)
    const [bookingDelay, setBookingDelay] = useState(0)
    const [bookingDelayPhone, setBookingDelayPhone] = useState(0)
    const [prices, setPrices] = useState(false)
    const [priceType, setPriceType] = useState(false)
    const { openTab } = useParams()
    const [currentTab, setCurrentTab] = useState(0)
    const [currentValue, setCurrentValue] = useState(props.value ?? [])
    const [numRoomsWithNoRating, setNumRoomsWithNoRating] = useState(null)

    let companyID = (
        document.cookie
            .split('; ')
            .find((row) => row.startsWith('companyID')) ?? ''
    ).split('=')[1]
    let key = (
        document.cookie.split('; ').find((row) => row.startsWith('key')) ?? ''
    ).split('=')[1]
    let secret = (
        document.cookie.split('; ').find((row) => row.startsWith('secret')) ??
        ''
    ).split('=')[1]
    let ref = useRef()

    let updateTimeout = 0
    async function update(event) {
        if (
            event.target.attributes.name !== undefined &&
            event.target.value !== undefined
        ) {
            // current time in seconds
            let requesttime = Math.floor(Date.now())
            // convert to utf8 bytes
            var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
            hmac.update(requesttime.toString() + key.toString())
            var hash = hmac.finalize()
            // convert to base64
            hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

            clearTimeout(updateTimeout)
            let value = ''
            updateTimeout = setTimeout(function () {
                value = event.target.value
                value = value.replace(/"/g, '`')
                value = value.replace(/'/g, '`')

                axios
                    .post(
                        'https://api.escaped.online/CONSOLE/UPDATE_ROOM_META',
                        {
                            key: key,
                            hash: hash,
                            requesttime: requesttime,
                            companyID: companyID,
                            roomID: roomID,
                            variable: event.target.attributes.name.value,
                            value: value,
                            toggle:
                                (event.target.attributes.type != null &&
                                    event.target.attributes.type.value ===
                                        'checkbox') ||
                                event.target.attributes.multiple != null,
                        },
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            showMessage('Opgeslagen')
                        } else {
                            showMessage('Opslaan mislukt')
                        }
                        console.log(response)
                    })
            }, 200)
        }
        return true
    }

    function handleFileUpload(files) {
        // current time in seconds
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        //get base64 of image
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = function () {
            //set base64 string to variable imageString
            let imageString = reader.result
            // set imageString to background of .dropzone
            document.querySelector('.dropzone').style.backgroundImage =
                `url(${imageString})`

            axios
                .post('https://api.escaped.online/CONSOLE/UPDATE_ROOM_META', {
                    key: key,
                    hash: hash,
                    requesttime: requesttime,
                    companyID: companyID,
                    variable: 'image',
                    value: imageString,
                    roomID: roomID,
                })
                .then((response) => {
                    console.log(response)
                })
        }
    }

    useEffect(() => {
        if (roomID && !room) {
            loadRoomDetails()
            loadRoomIcs()
        } else if (!roomID) {
            loadRoomsNow()
        }
    }, [])

    useEffect(() => {
        if (openTab) {
            setCurrentTab(parseInt(openTab))
        }
    }, [])

    useEffect(() => {
        loadRoomsWithNoRating()
    }, [])

    async function loadRoomsNow() {
        loadRooms().then((response) => {
            setRooms(response)
        })
    }

    async function loadRoomsWithNoRating() {
        // current time in seconds
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        axios
            .get(
                'https://api.escaped.online/CONSOLE/COMPANY_ROOMS_WITH_NO_OWNER_RATING/?companyID=' +
                    companyID +
                    '&key=' +
                    key +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.data.error === 'true') {
                    console.log(response.data)
                    return 'Onjuiste logingegevens'
                } else {
                    setNumRoomsWithNoRating(response.data)
                }
            })
            .catch((error) => {})
    }

    if (
        !document.cookie.split('; ').find((row) => row.startsWith('companyID'))
    ) {
        return <NoAccessYet />
    }

    if (!roomLoading && room) {
        return [
            <Menu></Menu>,
            <ChangesNotice></ChangesNotice>,
            <RoomWithNoRating></RoomWithNoRating>,
            <Content></Content>,
            <div
                className={'grid grid-cols-2 gap-10 mt-5 max-md:grid-cols-1'}
            ></div>,
        ]
    } else if (rooms) {
        // loop through rooms
        let roomList = []
        for (let i = 0; i < rooms.length; i++) {
            roomList.push(<RoomTile key={i} room={rooms[i]} />)
        }
        return [<RoomWithNoRating></RoomWithNoRating>, roomList]
    } else {
        return <Loading />
    }

    function Content(props) {
        switch (currentTab) {
            case 0:
                return <RoomImage room={room} />
            case 1:
                return [<RoomProperties />, <RoomCategories />]
            case 2:
                return <RoomPricing />
            case 3:
                return [
                    <BookingScheme />,
                    calendarIcs != null && calendarIcs != '' ? (
                        <FreeSpotsPreview />
                    ) : (
                        <></>
                    ),
                ]
            case 4:
                return <RoomRanking />
            default:
                return <></>
        }
    }

    function ChangesNotice() {
        if (room.check_before_cache_update != null) {
            return (
                <div
                    className={
                        'bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4'
                    }
                    role="alert"
                >
                    <p className={'font-bold'}>Wachten op controle</p>
                    <p className={'text-sm'}>
                        Je hebt wijzigingen aangebracht die we nog moeten
                        controleren. Tot die tijd zullen de wijzigingen nog niet
                        in de app te zien zijn. Je kunt nog extra wijzigingen
                        aanbrengen.
                    </p>
                </div>
            )
        }
    }

    function RoomWithNoRating() {
        if (numRoomsWithNoRating != null && numRoomsWithNoRating.length > 0) {
            return (
                <div
                    onClick={() => {
                        window.location.href =
                            '/myrooms/' + numRoomsWithNoRating[0] + '/4'
                    }}
                    className={
                        'bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-5 cursor-pointer'
                    }
                    role="alert"
                >
                    <p className={'font-bold'}>
                        Je kunt een beoordeling invoeren
                    </p>
                    <p className={'text-sm'}>
                        Je hebt voor {numRoomsWithNoRating.length} kamer
                        {numRoomsWithNoRating.length > 1 ? 's' : ''} nog geen
                        beoordeling ingevoerd. Klik hier om een beoordeling in
                        te voeren.{' '}
                    </p>
                </div>
            )
        }
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function Menu(props) {
        const tabs = [
            {
                name: 'Visueel & tekst',
                href: '#',
                icon: eyeIcon,
                current: currentTab === 0,
                id: 0,
            },
            {
                name: 'Eigenschappen',
                href: '#',
                icon: cogIcon,
                current: currentTab === 1,
                id: 1,
            },
            {
                name: 'Prijzen',
                href: '#',
                icon: currencyEuroIcon,
                current: currentTab === 2,
                id: 2,
            },
            {
                name: 'Beschikbaarheid',
                href: '#',
                icon: calendarIcon,
                current: currentTab === 3,
                id: 3,
            },
            {
                name: 'Beoordeling',
                href: '#',
                icon: starIcon,
                current: currentTab === 4,
                id: 4,
            },
            // { name: 'Voorbeeld', href: '#', icon: devicePhoneMobileIcon, current: currentTab === 4, id: 4 },
        ]

        return (
            <div>
                <div className="sm:hidden clear-both w-full">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        onChange={(event) => {
                            setCurrentTab(parseInt(event.target.value))
                        }}
                        className="mb-5 block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500"
                    >
                        {tabs.map((tab) => (
                            <option
                                value={tab.id}
                                key={tab.name}
                                selected={
                                    currentTab === tab.id ? 'selected' : ''
                                }
                            >
                                {tab.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-400">
                        <nav
                            className="-mb-px flex space-x-8"
                            aria-label="Tabs"
                        >
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    onClick={() => {
                                        setCurrentTab(tab.id)
                                    }}
                                    className={classNames(
                                        tab.current
                                            ? 'cursor-pointer border-black text-black'
                                            : 'cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium',
                                    )}
                                    aria-current={
                                        tab.current ? 'page' : undefined
                                    }
                                >
                                    <tab.icon
                                        className={classNames(
                                            tab.current
                                                ? 'text-black'
                                                : 'text-gray-400 group-hover:text-gray-500',
                                            '-ml-0.5 mr-2 h-5 w-5',
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span>{tab.name}</span>
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        )
    }

    function loadRoomIcs() {
        if (!calendarIcs && !calendarIcsLoading) {
            setCalendarIcsLoading(true)
            // current time in seconds
            let requesttime = Math.floor(Date.now())
            // convert to utf8 bytes
            var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
            hmac.update(requesttime.toString() + key.toString())
            var hash = hmac.finalize()
            // convert to base64
            hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

            axios
                .get(
                    'https://api.escaped.online/CONSOLE/CALENDARICS/?roomID=' +
                        roomID +
                        '&key=' +
                        key +
                        '&hash=' +
                        hash +
                        '&requesttime=' +
                        requesttime +
                        '&companyID=' +
                        companyID,
                )
                .then((response) => {
                    if (response.data.error == 'true') {
                        console.log(response.data)
                        setCalendarIcsLoading(false)
                        setCalendarIcs('')
                        return 'Onjuiste logingegevens'
                    } else {
                        setCalendarIcs(response.data.value)
                        setCalendarIcsLoading(false)
                    }
                })
                .catch((error) => {})
        }
    }

    function loadRoomDetails(force) {
        if ((!room && !roomLoading) || force) {
            if (!force) setRoomLoading(true)
            // current time in seconds
            let requesttime = Math.floor(Date.now())
            // convert to utf8 bytes
            var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
            hmac.update(requesttime.toString() + key.toString())
            var hash = hmac.finalize()
            // convert to base64
            hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

            axios
                .get(
                    'https://api.escaped.online/GET/ROOM/' +
                        roomID +
                        '?live=true',
                )
                .then((response) => {
                    if (response.data.error == 'true') {
                        setRoomLoading(false)
                        return 'Onjuiste logingegevens'
                    } else {
                        setRoomLoading(false)
                        console.log(response.data)
                        setRoom(response.data)
                        setBookingDelay(response.data.bookingDelay ?? 0)
                        setBookingDelayPhone(
                            response.data.bookingDelayPhone ?? 0,
                        )
                        setPrices(response.data.prices ?? [])
                        setPriceType(
                            response.data.prices['priceType'] ?? 'group',
                        )
                    }
                })
                .then(() => {
                    loadPredictedBlocks()
                })
                .then(() => {
                    loadFreeSpots()
                })
                .catch((error) => {})
        }
    }

    function loadPredictedBlocks(force = false) {
        if ((!predictedBlocks && !predictedBlocksLoading) || force) {
            setPredictedBlocksLoading(true)
            // current time in seconds
            let requesttime = Math.floor(Date.now())
            // convert to utf8 bytes
            var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
            hmac.update(requesttime.toString() + key.toString())
            var hash = hmac.finalize()
            // convert to base64
            hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

            axios
                .get(
                    'https://api.escaped.online/' +
                        roomID +
                        '/CALENDAR/SUGGESTED',
                )
                .then((response) => {
                    if (response.data.error === 'true') {
                        console.log(response.data)
                        setPredictedBlocksLoading(false)
                        return 'Onjuiste logingegevens'
                    } else {
                        setPredictedBlocksLoading(false)
                        setPredictedBlocks(response.data)
                    }
                })
                .catch((error) => {
                    setPredictedBlocks([])
                    setPredictedBlocksLoading(false)
                })
        }
    }

    async function sortBlocks(blocks) {
        // blocks = {0:["11:00","09:00"],1:["11:00","09:00"]}
        // sort for each record in blocks on time
        for (const day in blocks) {
            // sort on start time
            blocks[day] = blocks[day].sort(function (a, b) {
                return new Date('1970/01/01 ' + a) - new Date('1970/01/01 ' + b)
            })
        }
        return blocks
    }

    async function updatePredictedBlocks(blocks) {
        blocks = await sortBlocks(blocks)

        // current time in seconds
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        axios
            .post('https://api.escaped.online/' + roomID + '/CALENDAR/BLOCKS', {
                key: key,
                hash: hash,
                requesttime: requesttime,
                companyID: companyID,
                blocks: JSON.stringify(blocks),
            })
            .then((response) => {
                if (response.data.error === 'true') {
                    setPredictedBlocksLoading(false)
                    return 'Onjuiste logingegevens'
                } else {
                    setPredictedBlocksLoading(false)
                    setPredictedBlocks(blocks)
                }
            })
            .catch((error) => {})
    }

    function loadFreeSpots(force = false) {
        if ((!freeSpots && !freeSpotsLoading) || force) {
            setFreeSpotsLoading(true)
            // current time in seconds
            let requesttime = Math.floor(Date.now())
            // convert to utf8 bytes
            var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
            hmac.update(requesttime.toString() + key.toString())
            var hash = hmac.finalize()
            // convert to base64
            hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

            axios
                .get(
                    'https://api.escaped.online/' +
                        roomID +
                        '/CALENDAR/OPEN?force=true',
                )
                .then((response) => {
                    if (response.data.error === 'true') {
                        console.log(response.data)
                        setFreeSpotsLoading(false)
                        return 'Onjuiste logingegevens'
                    } else {
                        setFreeSpotsLoading(false)
                        setFreeSpots(response.data)
                    }
                })
                .catch((error) => {
                    // setFreeSpotsLoading(false);
                    setFreeSpots([])
                    setFreeSpotsLoading(false)
                })
        }
    }

    function RoomTile(props) {
        let image =
            props.room.image != null
                ? JSON.parse(props.room.image.replaceAll(/\\/g, ''))
                : ''
        return (
            <div
                className="room"
                onClick={() => {
                    window.location.href = 'myrooms/' + props.room.ID
                }}
            >
                <div
                    className={'image'}
                    style={{ backgroundImage: "url('" + image['small'] + "')" }}
                ></div>
                <div className={'title'}>{props.room.name ?? ''}</div>
                <p className={'description'}>{props.room.description ?? ''}</p>
            </div>
        )
    }

    function RoomImage(props) {
        return [
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Naam van de escaperoom</h2>
                <div className={'mb-5 pl-5 pr-5 pb-5'}>
                    <Input
                        onChange={(event) => {
                            update(event)
                        }}
                        name={'name'}
                        value={room.name}
                        type={'text'}
                        placeholder={'Naam van de escaperoom'}
                    />
                </div>
            </div>,
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Afbeelding</h2>
                <div className={'mb-5 pl-5 pr-5 pb-5'}>
                    <em
                        className={
                            'text-sm m-auto opacity-50 max-w-[800px] block'
                        }
                    >
                        Deze afbeelding gebruiken we om jouw escaperoom in de
                        lijsten te tonen. De afbeelding wordt ook op een
                        detailpagina groter in beeld gebracht. Klik op de
                        afbeelding om deze te wijzigen
                    </em>
                </div>
                <div
                    className={
                        'w-2/3 aspect-[3/2] overflow-hidden mb-10 block pb-10 m-auto cover'
                    }
                >
                    <ConsoleImageUpload
                        fileTypes={['.jpeg', '.jpg', '.png']}
                        handleFileUpload={(files) => {
                            handleFileUpload(files)
                        }}
                        updateValue={async (event) => {
                            await update(event)
                        }}
                        dropzoneClassName={
                            'rounded w-full aspect-[3/2] bg-cover bg-top'
                        }
                        type={'image'}
                        width={30}
                        height={20}
                        dataType={'room_meta'}
                        room={props.room}
                        value={room.image}
                        variable={'image'}
                        className={'object-cover'}
                    />
                </div>
            </div>,
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Korte teaser</h2>
                <div className={'mb-5 pl-5 pr-5 pb-5'}>
                    <em className={'text-sm opacity-50 mb-5 block'}>
                        De teaser tekst wordt gebruikt om in lijsten met
                        escaperooms jouw kamer kort toe te lichten. Hiermee
                        verleid je dus de app gebruikers om jouw kamer verder te
                        bekijken
                    </em>
                    <Input
                        rows={3}
                        advicedChars={140}
                        onChange={(event) => {
                            update(event)
                        }}
                        name={'short_description'}
                        value={room.short_description}
                        type={'textarea'}
                        placeholder={'Vul hier een korte teaser in'}
                    />
                </div>
            </div>,
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Het verhaal</h2>
                <div className={'mb-5 pl-5 pr-5 pb-5'}>
                    <em className={'text-sm opacity-50 mb-5 block'}>
                        Deze tekst wordt gebruikt op de detail pagina en bevat
                        het verhaal/plot van jouw escaperoom
                    </em>
                    <Input
                        rows={6}
                        onChange={(event) => {
                            update(event)
                        }}
                        name={'full_description'}
                        value={room.full_description}
                        type={'textarea'}
                        placeholder={'Vul hier een korte teaser in'}
                    />
                </div>
            </div>,
        ]
    }

    function RoomProperties(props) {
        const roomProperties = [
            {
                name: 'Kamer geopend sinds',
                min: 0,
                inline: true,
                variable: 'open_since',
                type: 'date',
                placeholder: '',
                description: 'Sinds wanneer is de kamer open?',
            },
            {
                name: 'Min spelers',
                decimals: 0,
                min: 0,
                inline: true,
                variable: 'minPlayers',
                type: 'number',
                placeholder: 'Minimaal antal spelers',
                description:
                    'Vul hier het aantal spelers in dat minimaal nodig is om te spelen',
            },
            {
                name: 'Max spelers',
                decimals: 0,
                inline: true,
                variable: 'maxPlayers',
                type: 'number',
                placeholder: 'Maximaal aantal spelers',
                description:
                    'Vul hier het maximum aantal spelers in dat tegelijkertijd kan spelen',
            },
            {
                name: 'Speelduur (minuten)',
                decimals: 0,
                min: 0,
                inline: true,
                variable: 'playTime',
                type: 'number',
                placeholder: 'Maximale ontsnappingstijd',
                description:
                    'Vul hier in hoe lang de escaperoom maximaal mag duren',
            },
            {
                name: 'Optimaal aantal spelers',
                decimals: 0,
                inline: true,
                variable: 'recommendedplayers',
                type: 'number',
                placeholder: 'Aanbevolen aantal spelers',
                description:
                    'Vul hier het aantal aanbevolen spelers in. Dit is het aantal spelers waarvoor de escaperoom het leukst is',
            },
            {
                name: 'Moeilijkheid (1-10)',
                decimals: 0,
                inline: true,
                variable: 'difficulty',
                type: 'number',
                placeholder: 'Moeilijkheidsgraad (1-10)',
                description:
                    'Vul hier de moeilijkheidsgraad van de escaperoom in. 1 is makkelijk, 10 is moeilijk',
            },
            {
                name: 'Minimale leeftijd',
                decimals: 0,
                inline: true,
                variable: 'minAge',
                type: 'number',
                placeholder: 'Minimale leeftijd',
                description:
                    'Vul hier de minimale leeftijd in die spelers moeten hebben om de escaperoom te spelen',
            },
            {
                name: 'Talen',
                className: '!w-full',
                inline: false,
                multiple: true,
                variable: 'languages',
                type: 'select',
                options: [
                    { label: 'Engels', value: 'english' },
                    { label: 'Duits', value: 'german' },
                    { label: 'Nederlands', value: 'dutch' },
                    { label: 'Frans', value: 'French' },
                ],
                placeholder: 'Talen',
            },
            {
                name: 'Ontsnapping (0-100%)',
                decimals: 0,
                inline: true,
                variable: 'escapeRate',
                type: 'number',
                placeholder: 'Escaperate',
                description:
                    'Vul hier de escaperate in van de escaperoom. Dit is het percentage spelers dat de escaperoom succesvol uitspeeld',
            },
            {
                name: 'Rolstoelvriendelijk',
                inline: true,
                variable: 'wheelchairs',
                type: 'checkbox',
                placeholder: 'Geschikt voor rolstoel',
                description:
                    'Is de escaperoom geschikt voor rolstoelgebruikers?',
            },
            {
                name: 'Toilet',
                inline: true,
                variable: 'toilet',
                type: 'checkbox',
                placeholder: 'Toilet',
                description: 'Is er een toilet aanwezig?',
            },
            {
                name: 'Airco',
                inline: true,
                variable: 'airco',
                type: 'checkbox',
                placeholder: 'Airco',
                description: 'Is er airco aanwezig?',
            },
            {
                name: 'Pinbetaling',
                inline: true,
                variable: 'cardPayments',
                type: 'checkbox',
                placeholder: 'Pinbetaling',
                description: 'Kunnen spelers pinnen?',
            },
            {
                name: 'Contante betaling',
                inline: true,
                variable: 'cashPayments',
                type: 'checkbox',
                placeholder: 'Contante betaling',
                description: 'Kunnen spelers contant betalen?',
            },
            {
                name: 'Drinkgelegenheid',
                inline: true,
                variable: 'drinks',
                type: 'checkbox',
                placeholder: 'Drankjes',
                description: 'Kunnen spelers in de escaperoom wat drinken?',
            },
            {
                name: 'Zwangeren',
                inline: true,
                variable: 'pregnants',
                type: 'checkbox',
                placeholder: 'Zwangeren',
                description: 'Is de escaperoom geschikt voor zwangeren?',
            },
            {
                name: 'Epilepsie',
                inline: true,
                variable: 'epileptics',
                type: 'checkbox',
                placeholder: 'Epilepsie',
                description:
                    'Is de escaperoom geschikt voor mensen met epilepsie?',
            },
            {
                name: 'Parkeren',
                inline: true,
                variable: 'parking',
                type: 'checkbox',
                placeholder: 'Parkeren',
                description: 'Is er parkeergelegenheid?',
            },
        ]

        return (
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Eigenschappen</h2>
                <div className={'pl-5 pr-5 pb-5'}>
                    {roomProperties.map((property, index) => (
                        <Input
                            className={property.className}
                            inline={props.inline ?? false}
                            onChange={(event) => {
                                update(event)
                            }}
                            name={property.variable}
                            checked={
                                room[property.variable] == 'true' ||
                                room[property.variable] == true
                                    ? true
                                    : false
                            }
                            value={
                                property.type === 'checkbox'
                                    ? room[property.variable]
                                        ? true
                                        : false
                                    : room[property.variable]
                            }
                            type={property.type}
                            placeholder={property.placeholder}
                            inlineHelp={property.description}
                            label={property.name}
                            options={property.options}
                            multiple={property.multiple}
                            decimals={property.decimals}
                        />
                    ))}
                </div>
            </div>
        )
    }

    function RoomPricing(props) {
        return (
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Prijzen</h2>
                <div className={'pl-5 pr-5 pb-5'}>
                    <Pricing />
                </div>
            </div>
        )
    }

    function Pricing() {
        const options = [
            {
                label: 'Kies hoe de prijs wordt berekend',
                value: '',
                disabled: true,
                selected: true,
            },
            {
                label: 'De prijs is afhankelijk van het aantal personen',
                value: 'user',
            },
            {
                label: 'Elke groepsgrootte betaalt dezelde prijs',
                value: 'group',
            },
        ]

        return [
            <Input
                label={'Berekening van de prijs'}
                inlineHelp={
                    'Is de prijs die men betaalt voor elke groepsgrootte hetzelfde? Of betaalt men meer als de groep groter is?'
                }
                ref={ref}
                value={priceType ?? false}
                type={'select'}
                name={'priceType'}
                options={options}
                onChange={async (event) => {
                    await update(event)
                    setTimeout(function () {
                        loadRoomDetails(true)
                    }, 1000)
                }}
            />,
            <Input
                value={room['bookUrl'] ?? ''}
                inlineHelp={
                    "Naar deze URL verwijzen we onze gebruikers door als ze op de 'toon beschikbaarheid' knop drukken"
                }
                label="Boekingspagina op de website"
                type={'text'}
                name={'bookUrl'}
                onChange={async (event) => {
                    await update(event)
                }}
                placeholder={'Link naar boekingspagina'}
            />,
            priceType === 'user' ? <UserPricing /> : <GroupPricing />,
        ]
    }

    function UserPricing() {
        const weekDays = [
            'Maandag',
            'Dinsdag',
            'Woensdag',
            'Donderdag',
            'Vrijdag',
            'Zaterdag',
            'Zondag',
        ]
        const weekDaysSlug = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
        const minUsers = room.minPlayers
        const maxUsers = room.maxPlayers

        // show a list from minPlayers to Maxplayers
        // show for every playerscount 7 columns to show the price for every day of the week for that amount of players
        return [
            <div className={''}>
                {Array.from(Array(maxUsers - minUsers + 1).keys()).map(
                    (userCount, index) => (
                        <div
                            className={'bg-gray-200 p-2 mb-3 mt-10'}
                            key={index}
                        >
                            <div>
                                <h3 className={'text-center my-2'}>
                                    {userCount + minUsers} spelers
                                </h3>
                            </div>
                            <div
                                className={
                                    'text-[14px] grid grid-cols-7 gap-2 max-md:text-[10px] max-md:gap-1'
                                }
                            >
                                {Array.from(Array(7).keys()).map(
                                    (day, index) => [
                                        <div className={'col p-0'}>
                                            <Input
                                                label={weekDays[index].slice(
                                                    0,
                                                    2,
                                                )}
                                                forceClassname={
                                                    'p-1 m-0 border border-b w-[100%]  appearance-none'
                                                }
                                                inline={true}
                                                value={
                                                    prices[
                                                        'price_' +
                                                            (userCount +
                                                                minUsers) +
                                                            '_' +
                                                            weekDaysSlug[index]
                                                    ] ??
                                                    prices[
                                                        'price_' +
                                                            (userCount +
                                                                minUsers)
                                                    ] ??
                                                    ''
                                                }
                                                onChange={(event) => {
                                                    update(event)
                                                }}
                                                name={
                                                    'price_' +
                                                    (userCount + minUsers) +
                                                    '_' +
                                                    weekDaysSlug[index]
                                                }
                                                type={'number'}
                                                placeholder={'Prijs'}
                                            />
                                        </div>,
                                    ],
                                )}
                            </div>
                        </div>
                    ),
                )}
            </div>,
        ]
    }

    function GroupPricing() {
        const weekDays = [
            'Maandag',
            'Dinsdag',
            'Woensdag',
            'Donderdag',
            'Vrijdag',
            'Zaterdag',
            'Zondag',
        ]
        const weekDaysSlug = [
            'price_mon',
            'price_tue',
            'price_wed',
            'price_thu',
            'price_fri',
            'price_sat',
            'price_sun',
        ]

        return [
            <div className={'mt-10'}>
                {weekDays.map((day, index) => (
                    <div className={''} key={index}>
                        <div className={'text-[10px] '}>
                            <Input
                                label={day}
                                inline={true}
                                value={
                                    prices[weekDaysSlug[index]] ??
                                    parseFloat(prices['price']) ??
                                    ''
                                }
                                onChange={(event) => {
                                    update(event)
                                }}
                                name={weekDaysSlug[index]}
                                type={'number'}
                                placeholder={'Prijs'}
                            />
                        </div>
                    </div>
                ))}
            </div>,
        ]
    }

    function RoomCategories() {
        const categories = [
            'horror',
            'humor',
            'crime',
            'history',
            'physical',
            'thriller',
            'sciencefiction',
            'love',
            'experience',
            'fantasy',
            'traditional',
            'actor',
            'storytelling',
            'kids',
            'outdoor',
            'mysterie',
            'avontuur',
            'misdaad',
            'VR',
            'Op lokatie',
            'detective',
            'battle',
        ]

        return (
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Categorieën</h2>
                <div
                    className={
                        'pl-5 pr-5 pb-5 grid grid-cols-3 gap-2 max-sm:grid-cols-2'
                    }
                >
                    {categories.map((category, index) => (
                        <div className={'col-2'} key={index}>
                            <Input
                                label={category}
                                inline={true}
                                value={category}
                                checked={
                                    room.categories.includes(category)
                                        ? true
                                        : false
                                }
                                onChange={(event) => {
                                    update(event)
                                }}
                                name={'categories'}
                                type={'checkbox'}
                                placeholder={'Prijs'}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    function BookingScheme(props) {
        return [
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-visible mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Schema en beschikbaarheid</h2>
                <div className={'pl-5 pr-5 pb-5 '}>
                    <Input
                        inline={'false'}
                        onChange={(event) => {
                            setCalendarIcs(event.target.value)
                            update(event)
                            setTimeout(function () {
                                loadPredictedBlocks(true)
                            }, 1000)
                            setTimeout(function () {
                                loadFreeSpots(true)
                            }, 2000)
                        }}
                        name={'calendarics'}
                        className={'!w-4/5'}
                        value={calendarIcs == null ? '' : calendarIcs}
                        type={'text'}
                        label={'Link naar .ics agenda'}
                        description={
                            'We gebruiken deze link alleen om de vrije plekken in de agenda te vinden zodat we de juiste beschikbaarheid in de app kunnen tonen. De agenda wordt nooit ingezien of opgeslagen.'
                        }
                        placeholder={'Plak een link naar jouw boekingsagenda'}
                        // help={"/help/beschikbaarheid"}
                    />

                    <ul className={'list-disc ml-10'}>
                        <li>
                            <strong>
                                Gebruik je onlineafspraken.nl voor jouw
                                boekingen?
                            </strong>{' '}
                            Ga dan naar naar de website van Online Afspraken:
                            Instellingen > Synchronisatie > Enkelvoudig om de
                            .ics link te vinden. Kopieer deze en plak hem
                            hierboven.
                        </li>
                        <li>
                            <strong>Gebruik je een google agenda?</strong> Ga
                            dan naar calendar.google.com en volg de volgende
                            stappen:
                            <ol className={'list-decimal ml-10'}>
                                <li>
                                    Ga linksonderin naar de "My calendars"
                                    sectie en klik bij de betreffende agenda op
                                    de 3 puntjes (verschijnt als je er met de
                                    muis overheen gaat)
                                </li>
                                <li>Klik op "Instellingen en delen"</li>
                                <li>
                                    Scroll naar beneden naar de "Integreer
                                    calender" sectie
                                </li>
                                <li>
                                    Zoek naar "Geheim adres in iCal-formaat" en
                                    kopieer de link die verschijnt
                                    <br />
                                    <em className={'text-gray-400'}>
                                        Google zal een melding geven dat je deze
                                        link niet met anderen moet delen. Dit is
                                        omdat deze link alle afspraakgegevens
                                        bevat. Ons systeem slaat jouw afspraken
                                        nooit op en we (mensen) kijken deze
                                        gegevens ook nooit in. Ons systeem
                                        bekijkt alleen welke tijdsblokken erin
                                        staan en berekend aan de hand daarvan
                                        welke tijdsblokken nog open staan. We
                                        gaan zeer vertrouwelijk om met deze
                                        gegvens.
                                    </em>
                                </li>
                            </ol>
                        </li>
                    </ul>

                    {calendarIcs != null && calendarIcs != '' ? (
                        <BookingBlocks></BookingBlocks>
                    ) : (
                        <></>
                    )}
                </div>
            </div>,
        ]
    }

    function BookingBlocks() {
        const [timeBlocks, setTimeBlocks] = useState(predictedBlocks)
        const [timeBlocksChanged, setTimeBlocksChanged] = useState(false)

        function removeTimeBlock(day, block) {
            setTimeBlocks((prev) => ({
                ...prev,
                [day]: prev[day].filter((v) => v !== block),
            }))
            setTimeBlocksChanged(true)
        }

        function updateMinute(dayindex, index, minute) {
            const oldHour = timeBlocks[dayindex][index].split(':')[0]
            const oldMinute = timeBlocks[dayindex][index].split(':')[1]

            if (minute === oldMinute) return

            const newTime = oldHour + ':' + minute

            // replace old time and update timeblocks
            timeBlocks[dayindex][index] = newTime
            setTimeBlocks((prev) => ({
                ...prev,
                [dayindex]: timeBlocks[dayindex],
            }))
            setTimeBlocksChanged(true)
        }

        function updateHour(dayindex, index, hour) {
            const oldHour = timeBlocks[dayindex][index].split(':')[0]
            const oldMinute = timeBlocks[dayindex][index].split(':')[1]

            if (hour === oldHour) return

            const newTime = hour + ':' + oldMinute

            // replace old time and update timeblocks
            timeBlocks[dayindex][index] = newTime
            setTimeBlocks((prev) => ({
                ...prev,
                [dayindex]: timeBlocks[dayindex],
            }))
            setTimeBlocksChanged(true)
        }

        function newStartBlock(dayIndex) {
            const newTime = '00:00'
            setTimeBlocks((prev) => ({
                ...prev,
                [dayIndex]: [...prev[dayIndex], newTime],
            }))
            setTimeBlocksChanged(true)
        }

        function DayBlocks({ day, timeBlock, dayindex }) {
            return (
                <div
                    className={
                        'mr-3 relative rounded bg-white shadow shadow-gray-800 p-2 text-center pb-10  min-w-[250px]'
                    }
                    key={dayindex}
                >
                    <h3 className={'text-[15px] mb-2 font-bold'}>{day}</h3>
                    {timeBlock.map((block, index) => (
                        <DayBlock
                            key={'test' + dayindex + '_' + index}
                            block={block}
                            dayindex={dayindex}
                            index={index}
                        ></DayBlock>
                    ))}
                    <NewBlockButton dayIndex={dayindex} />
                </div>
            )
        }

        function DayBlock({ block, dayindex, index }) {
            const hour = block.split(':')[0]
            const [minute, setMinute] = useState(block.split(':')[1] ?? 0)
            const [selectedMinute, setSelectedMinute] = useState(
                minute == 0 ? '00' : minute,
            )
            const [selectedHour, setSelectedHour] = useState(hour)
            const [open, setOpen] = useState(true)
            const [openHour, setOpenHour] = useState(true)

            const minuteOptions = [...Array(60).keys()]
            const hourOptions = [...Array(24).keys()]

            useEffect(() => {
                updateMinute(dayindex, index, selectedMinute)
            }, [selectedMinute])

            useEffect(() => {
                updateHour(dayindex, index, selectedHour)
            }, [selectedHour])

            return (
                <div
                    className={
                        'flex text-[13px] border-t-gray-600 border-solid border-t-[1px] py-2 px-0 max-md:text-[10px]'
                    }
                    key={index}
                >
                    <div className={'w-2/5'}>
                        <Listbox
                            value={selectedHour}
                            onChange={setSelectedHour}
                        >
                            {({ open }) => (
                                <div>
                                    <div className="relative mt-2">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-1000 sm:text-sm sm:leading-6">
                                            <span className="block truncate">
                                                {selectedHour.toString()
                                                    .length == 1
                                                    ? '0' +
                                                      selectedHour.toString()
                                                    : selectedHour}
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 -translate-y-2"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {hourOptions.map((hour) => (
                                                    <Listbox.Option
                                                        key={
                                                            dayindex +
                                                            '_' +
                                                            index +
                                                            '_hour_' +
                                                            hour
                                                        }
                                                        className={({
                                                            active,
                                                        }) =>
                                                            classNames(
                                                                active
                                                                    ? 'bg-primary-1000 text-white'
                                                                    : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            )
                                                        }
                                                        value={
                                                            hour.toString()
                                                                .length == 1
                                                                ? '0' +
                                                                  hour.toString()
                                                                : hour
                                                        }
                                                    >
                                                        {({
                                                            selected,
                                                            active,
                                                        }) => (
                                                            <>
                                                                <span
                                                                    className={classNames(
                                                                        selected
                                                                            ? 'font-semibold'
                                                                            : 'font-normal',
                                                                        'block truncate',
                                                                    )}
                                                                >
                                                                    {hour.toString()
                                                                        .length ==
                                                                    1
                                                                        ? '0' +
                                                                          hour.toString()
                                                                        : hour}
                                                                </span>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active
                                                                                ? 'text-white'
                                                                                : 'text-primary-1000',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </div>
                            )}
                        </Listbox>
                    </div>
                    <div className={'font-bold text-xl mt-3 mx-1'}>:</div>
                    <div className={'w-2/5'}>
                        <Listbox
                            value={selectedMinute}
                            onChange={setSelectedMinute}
                        >
                            {({ open }) => (
                                <div>
                                    <div className="relative mt-2">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-1000 sm:text-sm sm:leading-6">
                                            <span className="block truncate">
                                                {selectedMinute}
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 -translate-y-2"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {minuteOptions.map((minute) => (
                                                    <Listbox.Option
                                                        key={
                                                            dayindex +
                                                            '_' +
                                                            index +
                                                            '_minute_' +
                                                            minute
                                                        }
                                                        className={({
                                                            active,
                                                        }) =>
                                                            classNames(
                                                                active
                                                                    ? 'bg-primary-1000 text-white'
                                                                    : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                                            )
                                                        }
                                                        value={
                                                            minute.toString()
                                                                .length == 1
                                                                ? '0' +
                                                                  minute.toString()
                                                                : minute
                                                        }
                                                    >
                                                        {({
                                                            selected,
                                                            active,
                                                        }) => (
                                                            <>
                                                                <span
                                                                    className={classNames(
                                                                        selected
                                                                            ? 'font-semibold'
                                                                            : 'font-normal',
                                                                        'block truncate',
                                                                    )}
                                                                >
                                                                    {minute.toString()
                                                                        .length ==
                                                                    1
                                                                        ? '0' +
                                                                          minute.toString()
                                                                        : minute}
                                                                </span>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active
                                                                                ? 'text-white'
                                                                                : 'text-primary-1000',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </div>
                            )}
                        </Listbox>
                    </div>
                    <div>
                        <TrashIcon
                            onClick={() => {
                                removeTimeBlock(dayindex, block)
                            }}
                            className={
                                'w-4 mt-4 ml-1 opacity-30 inline-block hover:opacity-100 cursor-pointer float-right'
                            }
                        />
                    </div>
                </div>
            )
        }

        if (!calendarIcs && !calendarIcsLoading) {
            return (
                <div className={'mt-5'}>
                    <h2 className={'mb-5'}>Start tijden</h2>
                    <em className={'text-sm opacity-50'}>
                        Voer hierboven eerst een .ics link in om automatisch de
                        startijden van jouw groepen te detecteren.
                    </em>
                </div>
            )
        } else if (predictedBlocksLoading && calendarIcs) {
            return [
                <div className={'mt-5'}>
                    <h2 className={''}>Start tijden</h2>
                </div>,
                <Loading />,
            ]
        } else if (
            !predictedBlocks ||
            !calendarIcs ||
            predictedBlocks.length === 0
        ) {
            return (
                <div className={'mt-5'}>
                    <h2 className={''}>Start tijden</h2>
                    <em className={'text-sm !leading-normal opacity-40'}>
                        We konden helaas geen boekingstijden voorspellen. Heb je
                        de link naar jouw .ics bestand al ingevoerd?
                    </em>
                </div>
            )
        } else {
            const weekDays = [
                'Maandag',
                'Dinsdag',
                'Woensdag',
                'Donderdag',
                'Vrijdag',
                'Zaterdag',
                'Zondag',
            ]

            return (
                <div
                    className={
                        'mt-5 relative min-h-[600px] h-auto overflow-visible'
                    }
                >
                    <h2 className={''}>Start tijden</h2>
                    {timeBlocksChanged ? (
                        <input
                            className={'absolute top-10 right-4 z-50 shadow'}
                            type={'button'}
                            onClick={() => {
                                updatePredictedBlocks(timeBlocks)
                            }}
                            value={'Opslaan'}
                        />
                    ) : (
                        <></>
                    )}
                    <div
                        className={
                            'pb-20 !overflow-x-scroll overflow-y-visible w-[100%]'
                        }
                    >
                        <div
                            className={
                                'w-[auto] flex gap-1 p-2 mt-5 relative overflow-y-visible'
                            }
                        >
                            {weekDays.map((day, index) => (
                                <DayBlocks
                                    key={ref + day + index}
                                    day={day}
                                    dayindex={index}
                                    timeBlock={timeBlocks[index]}
                                ></DayBlocks>
                            ))}
                        </div>
                    </div>
                </div>
            )
        }

        function NewBlockButton({ dayIndex }) {
            return (
                <div
                    onClick={() => {
                        newStartBlock(dayIndex)
                    }}
                    className={
                        'absolute bottom-0 w-full text-left p-2 text-primary-1000 cursor-pointer opacity-60 hover:opacity-100'
                    }
                >
                    <PlusCircleIcon
                        className={'w-5 inline'}
                        onClick={() => {
                            console.log('new block')
                        }}
                    />
                    <span className={'top-0.5 relative ml-1'}>
                        Nieuwe starttijd
                    </span>
                </div>
            )
        }
    }

    function FreeSpotsPreview(props) {
        const bookingDelaySelection = (
            <select
                defaultValue={bookingDelay ?? 0}
                name={'bookingDelay'}
                onChange={async (event) => {
                    await update(event)
                    setBookingDelay(event.target.value)
                    setTimeout(function () {
                        loadFreeSpots(true)
                    }, 1000)
                }}
            >
                {
                    //     map 0-72 hours and gereation options for that
                    [...Array(73)].map((e, i) => (
                        <option key={i} value={i}>
                            {i} uur
                        </option>
                    ))
                }
            </select>
        )

        const phoneDelaySelection = (
            <select
                defaultValue={bookingDelayPhone ?? 0}
                name={'bookingDelayPhone'}
                onChange={async (event) => {
                    await update(event)
                    setBookingDelayPhone(event.target.value)
                    setTimeout(function () {
                        loadFreeSpots(true)
                    }, 1000)
                }}
            >
                {
                    //     map 0-72 hours and gereation options for that
                    [...Array(73)].map((e, i) => (
                        <option key={i} value={i}>
                            {i} uur
                        </option>
                    ))
                }
            </select>
        )

        if (freeSpots && !freeSpotsLoading && freeSpots.length === 0) {
            return (
                <div
                    className={
                        'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                    }
                >
                    <h2 className={'mb-5 p-3'}>Eerstvolgende vrije plekken</h2>
                    <div className={'pl-5 pr-5 pb-5 grid gap-2'}>
                        <em className={'text-sm opacity-50'}>
                            We hebben geen vrije plekken kunnen vinden in de
                            komende 3 maanden. Heb je een .ics url ingevoerd
                            hierboven?
                        </em>

                        <em
                            className={
                                'text-sm px-5 py-2 bg-black opacity-50 text-center mt-3 rounded text-white block'
                            }
                        >
                            Klopt dit niet? Laat het ons dan weten!
                        </em>
                    </div>
                </div>
            )
        } else if (freeSpots && !freeSpotsLoading && freeSpots.length > 0) {
            return (
                <div
                    className={
                        'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                    }
                >
                    <h2 className={'mb-5 p-3'}>Eerstvolgende vrije plekken</h2>
                    <div className={'pl-5 pr-5 pb-5 grid gap-2'}>
                        <p className={'mb-2'}>
                            Online reserveren kan tot {bookingDelaySelection}{' '}
                            tevoren
                        </p>
                        <p className={'mb-2'}>
                            Telefonisch reserveren kan tot {phoneDelaySelection}{' '}
                            tevoren{' '}
                        </p>
                        <em className={'text-sm opacity-50'}>
                            Moeten spelers bijvoorbeeld 48 uur tevoren online
                            boeken, maar kan het mogelijk dezelfde dag als ze
                            bellen? Zet deze instelling dan op bijvoorbeeld 48
                            uur en 4 uur
                        </em>
                        <div className={'grid grid-cols-3 gap-1 mt-5'}>
                            {/*SHOW FIRST 10 FREE SPOTS*/}
                            {freeSpots.slice(0, 10).map((block, index) => (
                                <div
                                    className={
                                        'rounded bg-white shadow shadow-gray-800 p-2 text-center m-2'
                                    }
                                    key={index}
                                >
                                    {/*epoch block time to hours*/}
                                    <h3
                                        className={
                                            'text-[15px] mb-2 truncate max-md:text-[11px]'
                                        }
                                    >
                                        {new Date(
                                            block * 1000,
                                        ).toLocaleDateString('nl-NL', {
                                            weekday: 'long',
                                            day: 'numeric',
                                            month: 'short',
                                        })}
                                    </h3>
                                    <div
                                        className={
                                            'text-[16px] block border-t-gray-200 border-solid border-t-[1px] py-1 font-bold'
                                        }
                                    >
                                        {new Date(
                                            block * 1000,
                                        ).toLocaleTimeString('nl-NL', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <em
                            className={
                                'text-sm px-5 py-2 bg-black opacity-50 text-center mt-3 rounded text-white block'
                            }
                        >
                            Klopt dit niet? Laat het ons dan weten!
                        </em>
                    </div>
                </div>
            )
        } else {
            return <Loading />
        }
    }

    function RoomRanking(props) {
        return (
            <div
                className={
                    'rounded bg-white shadow pt-0 m-2 overflow-hidden mt-5'
                }
            >
                <h2 className={'mb-5 p-3'}>Beoordeling van de eigenaar</h2>

                <div
                    className={
                        'pl-5 pr-5 pb-5 grid grid-cols-3 gap-3 text-sm max-md:grid-cols-1'
                    }
                >
                    <p className={'mb-3'}>
                        In de opstartfase van Escaped krijg je als eigenaar van
                        de kamer de kans om zelf een beoordeling in te vullen.
                        Dit cijfer moet een representatie zijn van wat je
                        gemiddeld als beoordeling ontvangt. Zo willen we onze
                        gebruikers inzicht geven in hoe de kamer wordt ervaren.
                    </p>
                    <ol className={'list-decimal list-outside ml-6 text-sm'}>
                        <li>
                            We controleren of de beoordeling die je invult
                            realistisch is door deze te vergelijken met
                            beoordelingen op andere platformen
                        </li>
                        <li>
                            Dit cijfer telt mee voor 15 reviews. Vanaf 15
                            reviews wegen de reviews dus zwaarder dan dit cijfer
                        </li>
                        <li>
                            Vanaf 20 reviews zal dit cijfer niet meer worden
                            getoond en worden alleen de reviews nog getoond
                        </li>
                    </ol>

                    <div className={'mt-5 w-1/2 m-auto'}>
                        <Input
                            decimals={1}
                            type={'number'}
                            name={'owner_rating'}
                            label={'Beoordeling'}
                            value={parseFloat(
                                room.reviews.owner_rating ?? '0.0',
                            )}
                            onChange={(event) => {
                                event.target.value =
                                    event.target.value > 10
                                        ? 10
                                        : event.target.value
                                update(event)
                            }}
                            className={'!text-3xl'}
                            onBlur={(event) => {
                                checkValue(event)
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    function checkValue(event) {
        // replace , with .
        event.target.value = event.target.value.replace(',', '.')

        //    check if value is between 1 and 10
        if (event.target.value < 1) {
            event.target.value = 1
        } else if (event.target.value > 10) {
            event.target.value = 10
        }

        event.target.value = parseFloat(event.target.value).toFixed(1)
    }
}

export default MyRooms
