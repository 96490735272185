import { useEffect, useState } from 'react'
import Loading from './loading'
import loadLastminuteSuggestions from '../functions/lastminutes/loadLastminutesSuggestions'
import { format } from 'date-fns'

export default function LastminuteSuggestions(props) {
    const [loading, setLoading] = useState(true)
    const [suggestions, setSuggestions] = useState(null)

    useEffect(() => {
        loadLastminuteSuggestions().then((suggestions) => {
            setSuggestions(suggestions)
            setLoading(false)
        })
    }, [])

    return (
        // if loading, then animate-pulse
        <div
            className={
                'min-h-[100px] w-full rounded-md bg-gray-200 shadow p-5 relative overflow-hidden pt-10' +
                (loading ? ' animate-pulse' : '')
            }
        >
            <h3 className="text-sm text-white bg-gray-500 p-2 absolute top-0 left-0 rounded-br-md">
                Suggesties
            </h3>
            <p className={'opacity-60 py-2 text-center'}>
                Klik op een suggestie om deze toe te voegen aan jouw beschikbare
                last minutes
            </p>
            <Suggestions />
        </div>
    )

    async function applySuggestion(room, date) {
        // convert date to correct format
        date = format(date, 'yyyy-MM-dd HH:mm:ss')

        await props.newLastMinute()
        props.setFocussedLastMinute((prevState) => ({
            ...prevState,
            status: 'concept',
            price: 100,
            room_id: room['room_id'],
            datetime: date,
        }))

        // wait 1 second
        await new Promise((r) => setTimeout(r, 500))

        // trigger blur
        document.getElementById('newdatepickerDateInput').blur()
        document.getElementById('newdatepickerTimeInput').blur()

        // props.saveLastminute();
        // props.showPopup(true);
    }

    function Suggestions() {
        if (loading) {
            return <Loading />
        }

        if (suggestions.length === 0) {
            return (
                <em className={'text-center block'}>
                    Geen suggesties gevonden
                </em>
            )
        }

        if (suggestions.length > 0) {
            return suggestions.map((room, index) => {
                // rooms, check open blocks
                return room.openBlocks.map((suggestion, index) => {
                    const date = new Date(suggestion * 1000)
                    const now = new Date()
                    let today = false

                    let dateString = format(date, 'dd-MM-yyyy HH:mm')
                    // if today
                    if (
                        date.getDate() === now.getDate() &&
                        date.getMonth() === now.getMonth() &&
                        date.getFullYear() === now.getFullYear()
                    ) {
                        dateString = 'Vandaag ' + format(date, 'HH:mm')
                        today = true
                    }
                    // if tomorrow
                    // make Date object for tomorrow
                    let tomorrow = new Date()
                    tomorrow.setDate(tomorrow.getDate() + 1)

                    if (
                        date.getDate() === tomorrow.getDate() &&
                        date.getMonth() === tomorrow.getMonth() &&
                        date.getFullYear() === tomorrow.getFullYear()
                    ) {
                        dateString = 'Morgen ' + format(date, 'HH:mm')
                        today = false
                    }

                    if (
                        date.getTime() - now.getTime() < 48 * 60 * 60 * 1000 &&
                        date.getTime() - now.getTime() > 0
                    ) {
                        return (
                            <div
                                onClick={() => {
                                    applySuggestion(room, date)
                                }}
                                key={index}
                                className={
                                    'inline-block border-black border m-1 rounded shadow shadow-[#333] overflow-hidden text-ellipsis cursor-pointer'
                                }
                            >
                                <div>
                                    <div
                                        className={
                                            'bg-black text-white p-1 text-xs w-32 text-center font-bold'
                                        }
                                    >
                                        {room['room_name']}
                                    </div>
                                    <p
                                        className={
                                            'text-sm text-center bg-white p-1 ' +
                                            (today && 'font-bold')
                                        }
                                    >
                                        {dateString}
                                    </p>
                                </div>
                            </div>
                        )
                    } else {
                        return ''
                    }
                })
            })
        }
    }
}
