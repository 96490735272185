import React, { useEffect, useRef, useState, Component } from 'react'
import { faIcons } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import Loading from './loading'
import { useParams } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import ContentState from 'draft-js/lib/ContentState'
import ConsoleImageUpload from './consoleImageUpload'
import checkifadmin from '../functions/login/checkifadmin'

const News = () => {
    const { newsid } = useParams()

    if (newsid > 0) {
        return [<NewsItemDetail newsid={newsid} />]
    } else {
        return [<NewsToolbar />, <NewsList />]
    }
}

const NewsToolbar = () => {
    let adminID = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminID'))
        .split('=')[1]
    let adminKey = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminKey'))
        .split('=')[1]
    let adminSecret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminSecret'))
        .split('=')[1]
    const newItem = async () => {
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        await axios
            .put('https://api.escaped.online/CONSOLE/NEWSITEM', {
                adminid: adminID,
                key: adminKey,
                hash: hash,
                requesttime: requesttime,
            })
            .then((response) => {
                if (response.status === 200) {
                    const newid = response.data.id
                    window.location.href = '/news/' + newid
                } else {
                    alert(
                        'Er is een fout opgetreden, probeer het later nog eens',
                    )
                }
            })
            .catch((error) => {
                if (error.response.status !== 200) {
                    alert(
                        'Er is een fout opgetreden, probeer het later nog eens',
                    )
                }
            })
    }

    return (
        <div className={'block space-x-10 mb-10'}>
            {/*<SearchBox/>*/}
            <button
                onClick={newItem}
                className={' rounded p-2 bg-white shadow shadow-md'}
            >
                <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
                Nieuw Item
            </button>
        </div>
    )
}

const NewsList = () => {
    let adminID = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminID'))
        .split('=')[1]
    let adminKey = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminKey'))
        .split('=')[1]
    let adminSecret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminSecret'))
        .split('=')[1]

    const [news, setNews] = useState([])

    useEffect(() => {
        checkifadmin().then(() => loadNewsItems())
    }, [])

    const loadNewsItems = async () => {
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        // check login by url params
        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/NEWSITEMS/?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    setNews(response.data)
                    return true
                } else {
                    return false
                }
            })
    }

    let newsItems = []

    if (news.length === 0) {
        return <Loading />
    }
    for (let i = 0; i < news.length; i++) {
        newsItems.push(<NewsItem key={i} newsItem={news[i]} />)
    }

    return newsItems
}

const NewsItem = (props) => {
    const date = new Date(props.newsItem.datetime)
    const dateString =
        date.getDate() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getFullYear() +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes()
    return (
        <a
            href={'/news/' + props.newsItem.ID}
            className={
                'rounded p-4 bg-white shadow shadow-md block my-2' +
                (props.newsItem.status == 'published'
                    ? ' border-l-4 border-l-green-700'
                    : ' border-l-4 border-l-orange-500')
            }
        >
            <div className={'flex justify-between'}>
                <div className={'font-bold'}>{props.newsItem.subject}</div>
                <div
                    className={'text-gray-500 text-ellipsis whitespace-nowrap'}
                >
                    {dateString}
                </div>
            </div>
            <div>
                {props.newsItem.content
                    .replace(/(<([^>]+)>)/gi, ' ')
                    .replaceAll('\\n', '')
                    .replaceAll('&nbsp;', '')
                    .substring(0, 400)}
                ...
            </div>
        </a>
    )
}

const SearchBox = () => {
    return (
        <div
            className={
                'rounded p-2 bg-white shadow shadow-md w-1/3 inline-block'
            }
        >
            <input
                type={'text'}
                placeholder={'Zoek...'}
                className={'border-0 focus:outline-none'}
            />
        </div>
    )
}

const ToolBar = (props) => {
    let adminID = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminID'))
        .split('=')[1]
    let adminKey = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminKey'))
        .split('=')[1]
    let adminSecret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminSecret'))
        .split('=')[1]

    const deleteNewsItem = async () => {
        // confirm delete
        if (
            !window.confirm(
                'Weet je zeker dat je dit nieuwsitem wilt verwijderen?',
            )
        ) {
            return false
        }

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        // check login by url params
        const result = await axios
            .delete(
                'https://api.escaped.online/CONSOLE/NEWSITEM/' +
                    props.newsItem.ID +
                    '?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    window.location.href = '/news'
                    return true
                } else {
                    return false
                }
            })
    }

    const toggleStatus = async () => {
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        let status =
            props.newsItem.status === 'concept' ? 'published' : 'concept'

        // check login by url params
        const result = await axios
            .post(
                'https://api.escaped.online/CONSOLE/NEWSITEM/' +
                    props.newsItem.ID,
                {
                    adminid: adminID,
                    key: adminKey,
                    hash: hash,
                    requesttime: requesttime,
                    variable: 'status',
                    value: status,
                },
            )
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload()
                    return true
                } else {
                    return false
                }
            })
    }

    return (
        <div className={'w-1/2'}>
            <a
                href={'/news'}
                className={'rounded p-2 bg-white shadow shadow-md my-1 mr-2'}
            >
                <FontAwesomeIcon icon={faChevronLeft} className={'mr-2'} />
                Terug
            </a>
            <a
                onClick={deleteNewsItem}
                className={
                    'rounded p-2 pr-0 bg-white shadow shadow-md my-1 mr-2 cursor-pointer'
                }
            >
                <FontAwesomeIcon icon={faTrash} className={'mr-2'} />
            </a>
            <a
                onClick={toggleStatus}
                className={
                    'rounded p-2 bg-white shadow shadow-md my-1 cursor-pointer'
                }
            >
                {props.newsItem.status === 'concept'
                    ? [
                          <FontAwesomeIcon icon={faEye} className={'mr-2'} />,
                          <span>Publiceren</span>,
                      ]
                    : [
                          <FontAwesomeIcon
                              icon={faEyeSlash}
                              className={'mr-2'}
                          />,
                          <span>Terug naar concept</span>,
                      ]}
            </a>
        </div>
    )
}

const NewsItemDetail = (props) => {
    let adminID = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminID'))
        .split('=')[1]
    let adminKey = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminKey'))
        .split('=')[1]
    let adminSecret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminSecret'))
        .split('=')[1]

    const [newsItem, setNewsItem] = useState([])
    const loadNewsItem = async () => {
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        // check login by url params
        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/NEWSITEM/' +
                    props.newsid +
                    '?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setNewsItem(response.data)
                    return true
                } else {
                    return false
                }
            })
    }

    useEffect(() => {
        loadNewsItem()
    }, [])

    if (newsItem.length === 0) {
        return <Loading />
    } else {
        return [
            <ToolBar newsItem={newsItem} />,
            <Title newsItem={newsItem} />,
            <Image newsItem={newsItem} />,
            <Content newsItem={newsItem} />,
        ]
    }
}

const Image = (props) => {
    const adminID = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminID'))
        .split('=')[1]
    const adminKey = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminKey'))
        .split('=')[1]
    const adminSecret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminSecret'))
        .split('=')[1]

    function handleFileUpload(files) {
        // current time in seconds
        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        //get base64 of image
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = function () {
            //set base64 string to variable imageString
            let imageString = reader.result
            // set imageString to background of .dropzone
            document.querySelector('.dropzone').style.backgroundImage =
                `url(${imageString})`

            axios
                .post(
                    'https://api.escaped.online/CONSOLE/NEWSITEM/' +
                        props.newsItem.ID,
                    {
                        key: adminKey,
                        hash: hash,
                        requesttime: requesttime,
                        adminid: adminID,
                        variable: 'image',
                        value: imageString,
                        table: 'news_meta',
                    },
                )
                .then((response) => {
                    console.log(response)
                })
        }
    }

    return (
        <ConsoleImageUpload
            fileTypes={['.jpeg', '.jpg', '.png']}
            handleFileUpload={(files) => {
                handleFileUpload(files)
            }}
            updateValue={async (event) => {
                await updateNewsItem(props, 'thumbnail', event.target.value)
            }}
            dropzoneClassName={
                'rounded w-full h-20 bg-contain bg-center bg-no-repeat'
            }
            type={'image'}
            dataType={'news_meta'}
            value={props.newsItem.image ?? '{}'}
            variable={'thumbnail'}
            className={
                'aspect-[6/3] rounded p-5 mb-5 bg-white shadow-md my-1 w-1/3 text-xl inline-block align-bottom'
            }
        />
    )

    // return (
    //     <div className={"rounded p-5 mb-5 bg-white shadow-md my-1 w-1/4 text-xl inline-block align-bottom"}>
    //         <img src={"https://via.placeholder.com/500x300"} className={"w-full"}/>
    //     </div>
    // )
}

const Title = (props) => {
    return (
        <input
            onBlur={(event) =>
                updateNewsItem(props, 'subject', event.target.value)
            }
            className={
                'inline-block rounded p-5 mb-5 mt-5 bg-white shadow-md my-1 w-3/5 mr-10 text-xl'
            }
            defaultValue={props.newsItem.subject}
        />
    )
}

class Content extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editorState: EditorState.createEmpty(),
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });

        if (!editorState.getSelection().getHasFocus()) {
            // get HTML content of editor
            let content = draftToHtml(
                convertToRaw(editorState.getCurrentContent()),
            )
            updateNewsItem(this.props, 'content', content)
        }
    };

    componentDidMount() {
        if (this.props.newsItem.content === undefined) {
            return
        }
        const html = this.props.newsItem.content
        const contentBlock = htmlToDraft(html.replaceAll('\\n', '\n'))
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks,
            )
            const editorState = EditorState.createWithContent(contentState)
            this.setState({
                editorState,
            })
        }
    }

    render() {
        const { editorState } = this.state

        return (
            <Editor
                editorState={editorState}

                wrapperClassName="demo-wrapper"
                editorClassName={
                    'whitespace-pre-line rounded p-5 mb-5 bg-white shadow-md my-1 w-full text-md min-h-[50vh]'
                }
                onEditorStateChange={this.onEditorStateChange}
            />
        )
    }
}

const updateNewsItem = async (props, field, value) => {
    let adminID = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminID'))
        .split('=')[1]
    let adminKey = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminKey'))
        .split('=')[1]
    let adminSecret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminSecret'))
        .split('=')[1]

    let requesttime = Math.floor(Date.now())
    // convert to utf8 bytes
    var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
    hmac.update(requesttime.toString() + adminKey.toString())
    var hash = hmac.finalize()
    // convert to base64
    hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

    let data = {}
    data[field] = value

    const result = await axios
        .post(
            'https://api.escaped.online/CONSOLE/NEWSITEM/' + props.newsItem.ID,
            {
                adminid: adminID,
                key: adminKey,
                hash: hash,
                requesttime: requesttime,
                value: value,
                variable: field,
            },
        )
        .then((response) => {
            if (response.status === 200) {
                return true
            } else {
                return false
            }
        })
}

export default News
