import { useEffect, useState } from 'react'
import getCurrentOnboardStatus, {
    hideOnboardingPanel,
} from '../api/currentOnboardStatus'
import showMessage from '../functions/showmessage'

export default function OnBoarding() {
    const [currentStatus, setCurrentStatus] = useState(null)
    const [checkPoints, setCheckPoints] = useState([])

    useEffect(() => {
        if (currentStatus !== null) return
        setCurrentStatus(
            getCurrentOnboardStatus()
                .then((response) => setCurrentStatus(response))
                .catch((error) =>
                    showMessage(
                        'Er is iets misgegaan bij het ophalen van de onboarding status',
                    ),
                ),
        )
        console.log(currentStatus)
    }, [])

    useEffect(() => {
        loadCheckPoints()
    }, [currentStatus])

    return currentStatus === null ? (
        <div
            className={
                'w-full h-[50vh] rounded-md shadow bg-primary-1000 animate-pulse'
            }
        ></div>
    ) : currentStatus.hidden === true ? (
        ''
    ) : (
        <div
            className={
                'rounded-md bg-primary-1000 pt-5 shadow  min-h-30 w-full mb-5 '
            }
        >
            {/*Close button*/}
            <CloseButton />
            <h2 className={'text-white font-black mb-10  text-xl text-center'}>
                Zo haal je het maximale uit Escaped:
            </h2>
            <div className={'columns-2 max-md:columns-1'}>
                {checkPoints.map((checkPoint) => (
                    <CheckPoint {...checkPoint} />
                ))}
            </div>
        </div>
    )

    function CheckPoint({ title, description, done, url, variable }) {
        return (
            <a
                href={url}
                className={
                    'block mb-5 break-inside-avoid hover:bg-white hover:bg-opacity-30 cursor-pointer px-4 py-6'
                }
            >
                <div className={'flex items-start'}>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={
                                'w-8 text-primary-1000 mr-3 rounded-2xl bg-white p-1' +
                                (done ||
                                (currentStatus[variable] ?? [1, 2]).length === 0
                                    ? ''
                                    : ' opacity-30')
                            }
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={3}
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </div>
                    <p className={'text-white font-bold leading-6 mb-2'}>
                        {title}
                    </p>
                </div>
                <em className={'ml-11 text-white text-sm opacity-60 block'}>
                    {description}
                </em>
            </a>
        )
    }

    function CloseButton() {
        return (
            <div className={'flex justify-end'}>
                <button
                    className={'text-white text-xl mr-5'}
                    onClick={hideOnboardingPanel}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={'w-6'}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
        )
    }

    function loadCheckPoints() {
        const checkPointsList = [
            {
                title: 'Vul jouw bedrijfsgegevens in',
                description:
                    'Met deze gegevens kunnen potentiële spelers je vinden en contact opnemen (bijvoorbeeld boeken via jouw website). We hebben dit al voor je gedaan, maar het kan geen kwaad om ze nog eens te controleren.',
                done: true,
                url: '/mycompany',
            },
            {
                title: 'Kies een passende afbeelding voor jouw kamer(s) en controleer de gegevens',
                description:
                    'We willen de spelers zo accuraat mogelijke gegevens tonen. Denk bijvoorbeeld aan de openingstijden, prijzen, etc. We hebben dit al voor je ingevuld, maar wie weet is er inmiddels iets veranderd.',
                done: true,
                url: '/myrooms',
            },
            {
                title: 'Geef jouw beschikbaarheid (automatisch) door',
                description:
                    'Door het schema met start tijden te checken en een .ics link (bijvoorbeeld van onlineafspraken.nl of Google Calendar) in te vullen, kunnen spelers in onze app direct zien wanneer ze kunnen spelen.',
                done: false,
                variable: 'numRoomsNoIcs',
                url:
                    currentStatus != null &&
                    currentStatus?.numRoomsNoIcs?.length > 0
                        ? '/myrooms/' + currentStatus.numRoomsNoIcs[0] + '/3'
                        : '/myrooms',
            },
            {
                title: 'Check Reviews',
                description:
                    'Natuurlijk houden wij de reviews in de gaten, maar je kunt ze ook zelf bekijken en indien nodig 24 uur pauzeren (bijvoorbeeld omdat er spoilers in staan). We nemen dan contact op om een oplossing te vinden.',
                done: false,
                url: '/reviews',
            },
            {
                title: 'Nodig spelers uit',
                description:
                    'Hoe meer reviews (social proof) je hebt hoe beter de spelers een keuze kunnen maken. Nodig bijvoorbeeld een aantal oud-spelers uit om een review te schrijven. Je kunt ze vanuit ons platform een uitnodiging sturen.',
                done: false,
            },
        ]

        setCheckPoints(checkPointsList)
    }
}
