import checkIfLoggedin from '../login/checkifloggedin'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import showMessage from '../showmessage'

async function pauseReview(review) {
    if (await checkIfLoggedin()) {
        let companyid = document.cookie
            .split('; ')
            .find((row) => row.startsWith('companyID'))
            .split('=')[1]
        let key = document.cookie
            .split('; ')
            .find((row) => row.startsWith('key'))
            .split('=')[1]
        let secret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('secret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .post('https://api.escaped.online/CONSOLE/PAUSE_REVIEW', {
                key: key,
                hash: hash,
                requesttime: requesttime,
                companyID: companyid,
                review: review.id,
            })
            .then((response) => {
                showMessage('Review is gepauzeerd, we nemen contact met je op')
                return true
            })
            .catch((error) => {
                alert('Er is iets mis gegaan bij het pauzeren van de review')
                return false
            })
    }
    return false
}

export default pauseReview
