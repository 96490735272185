import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import * as React from 'react'
import loginForm from './loginForm'
import SettingsPopup from './settingspopup.js'
import checkIfAdmin from '../functions/login/checkifadmin.js'

import {
    BookmarkIcon,
    ChartBarIcon,
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    PhoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import shoppingBagIcon from '@heroicons/react/24/outline/esm/ShoppingBagIcon'
import bellIcon from '@heroicons/react/24/outline/esm/BellIcon'

// if href == current page, then current = true
let navigation = [
    { name: 'Dashboard', href: '/', icon: ChartBarIcon, current: false },
    { name: 'Bedrijf', href: '/mycompany', icon: HomeIcon, current: false },
    { name: 'Kamer(s)', href: '/myrooms', icon: FolderIcon, current: false },
    {
        name: 'Boekingen',
        href: '/mybookings',
        icon: CalendarIcon,
        current: false,
    },
    { name: 'Reviews', href: '/myreviews', icon: BookmarkIcon, current: false },
    {
        name: 'Advertenties',
        href: '/myads',
        icon: shoppingBagIcon,
        current: false,
    },
    {
        name: 'Last Minutes',
        href: '/mylastminutes',
        icon: bellIcon,
        current: false,
    },
]
const teams = [
    {
        id: 1,
        name: 'Statistieken',
        href: '/statistics',
        initial: 'S',
        current: false,
    },
    { id: 1, name: 'Reviews', href: '/reviews', initial: 'R', current: false },
    { id: 2, name: 'Lokaties', href: '/list', initial: 'L', current: false },
    { id: 3, name: 'Kamers', href: '/rooms', initial: 'K', current: false },
    { id: 4, name: 'Nieuws', href: '/news', initial: 'N', current: false },
    {
        id: 5,
        name: 'Uitnodigingen',
        href: '/invitations',
        initial: 'I',
        current: false,
    },
    {
        id: 6,
        name: 'Kamerwijzigingen',
        href: '/ownerupdates',
        initial: 'K',
        current: false,
    },
]
const userNavigation = [
    // { name: 'Your profile', href: '#' },
    { name: 'Uitloggen', href: '/logout' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Sidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isCompany, setIsCompany] = useState(false)
    const [showSettings, setShowSettings] = useState(false)

    async function checkIfCompany() {
        if (
            document.cookie.includes('companyID') &&
            document.cookie.includes('key') &&
            document.cookie.includes('secret')
        ) {
            setIsCompany(true)
            return true
        }
    }

    useEffect(() => {
        checkIfAdmin().then((result) => {
            setIsAdmin(result)
        })
        checkIfCompany()
    })

    return [
        showSettings ? <SettingsPopup /> : <></>,
        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() =>
                                                setSidebarOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-10 mt-5 w-auto"
                                            src="/images/escaped.png"
                                            alt="Escaped"
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul
                                            role="list"
                                            className="flex flex-1 flex-col gap-y-7"
                                        >
                                            <li>
                                                <ul
                                                    role="list"
                                                    className="-mx-2 space-y-1"
                                                >
                                                    {isCompany ? (
                                                        navigation.map(
                                                            (item) => (
                                                                <li
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    <a
                                                                        href={
                                                                            item.href
                                                                        }
                                                                        className={classNames(
                                                                            window
                                                                                .location
                                                                                .pathname ===
                                                                                item.href
                                                                                ? 'bg-gray-50 text-orange-600'
                                                                                : 'text-gray-700 hover:text-orange-600 hover:bg-gray-50',
                                                                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                        )}
                                                                    >
                                                                        <item.icon
                                                                            className={classNames(
                                                                                window
                                                                                    .location
                                                                                    .pathname ===
                                                                                    item.href
                                                                                    ? 'text-orange-600'
                                                                                    : 'text-gray-400 group-hover:text-orange-600',
                                                                                'h-6 w-6 shrink-0',
                                                                            )}
                                                                            aria-hidden="true"
                                                                        />
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </a>
                                                                </li>
                                                            ),
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                </ul>
                                            </li>
                                            {isAdmin ? (
                                                <li>
                                                    <ul
                                                        role="list"
                                                        className="-mx-2 mt-2 space-y-1"
                                                    >
                                                        {teams.map((team) => (
                                                            <li key={team.name}>
                                                                <a
                                                                    href={
                                                                        team.href
                                                                    }
                                                                    className={classNames(
                                                                        window
                                                                            .location
                                                                            .pathname ===
                                                                            team.href
                                                                            ? 'bg-gray-50 text-orange-600'
                                                                            : 'text-gray-700 hover:text-orange-600 hover:bg-gray-50',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                                    )}
                                                                >
                                                                    <span
                                                                        className={classNames(
                                                                            window
                                                                                .location
                                                                                .pathname ===
                                                                                team.href
                                                                                ? 'text-orange-600 border-orange-600'
                                                                                : 'text-gray-400 border-gray-200 group-hover:border-orange-600 group-hover:text-orange-600',
                                                                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
                                                                        )}
                                                                    >
                                                                        {
                                                                            team.initial
                                                                        }
                                                                    </span>
                                                                    <span className="truncate">
                                                                        {
                                                                            team.name
                                                                        }
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {/*<li className="mt-auto">*/}
                                            {/*    <div onClick={()=>{setShowSettings(!showSettings)}}*/}
                                            {/*        className="cursor-pointer group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-orange-600"*/}
                                            {/*    >*/}
                                            {/*        <Cog6ToothIcon*/}
                                            {/*            className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-orange-600"*/}
                                            {/*            aria-hidden="true"*/}
                                            {/*        />*/}
                                            {/*        Instellingen*/}
                                            {/*    </div>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                        <img
                            className="h-10 mt-5 w-auto"
                            src="/images/escaped.png"
                            alt="Escaped"
                        />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                        >
                            <li>
                                <ul role="list" className={'-mx-2 space-y-1'}>
                                    {isCompany ? (
                                        navigation.map((item) => (
                                            <li
                                                key={item.name}
                                                className={
                                                    isCompany ||
                                                    item.href === '/'
                                                        ? ''
                                                        : ' opacity-60'
                                                }
                                            >
                                                <a
                                                    href={
                                                        isCompany ||
                                                        item.href === '/'
                                                            ? item.href
                                                            : ''
                                                    }
                                                    className={classNames(
                                                        window.location
                                                            .pathname ===
                                                            item.href
                                                            ? 'bg-gray-50 text-orange-600'
                                                            : 'text-gray-700 hover:text-orange-600 hover:bg-gray-50',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            window.location
                                                                .pathname ===
                                                                item.href
                                                                ? 'text-orange-600'
                                                                : 'text-gray-400 group-hover:text-orange-600',
                                                            'h-6 w-6 shrink-0',
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </ul>
                            </li>
                            {isAdmin ? (
                                <li>
                                    <ul
                                        role="list"
                                        className="-mx-2 mt-2 space-y-1"
                                    >
                                        {teams.map((team) => (
                                            <li key={team.name}>
                                                <a
                                                    href={team.href}
                                                    className={classNames(
                                                        window.location
                                                            .pathname ===
                                                            team.href
                                                            ? 'bg-gray-50 text-orange-600'
                                                            : 'text-gray-700 hover:text-orange-600 hover:bg-gray-50',
                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                                    )}
                                                >
                                                    <span
                                                        className={classNames(
                                                            window.location
                                                                .pathname ===
                                                                team.href
                                                                ? 'text-orange-600 border-orange-600'
                                                                : 'text-gray-400 border-gray-200 group-hover:border-orange-600 group-hover:text-orange-600',
                                                            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
                                                        )}
                                                    >
                                                        {team.initial}
                                                    </span>
                                                    <span className="truncate">
                                                        {team.name}
                                                    </span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ) : (
                                ''
                            )}

                            {/*<li className="mt-auto">*/}
                            {/*    <div onClick={()=>{setShowSettings(!showSettings)}}*/}
                            {/*         className=" cursor-pointer group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-orange-600"*/}
                            {/*    >*/}
                            {/*        <Cog6ToothIcon*/}
                            {/*            className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-orange-600"*/}
                            {/*            aria-hidden="true"*/}
                            {/*        />*/}
                            {/*        Instellingen*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="lg:pl-60">
                <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                    <button
                        type="button"
                        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Separator */}
                    <div
                        className="h-6 w-px bg-gray-200 lg:hidden"
                        aria-hidden="true"
                    />

                    <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                        <form
                            className="relative flex flex-1"
                            action="#"
                            method="GET"
                        >
                            {/*<label htmlFor="search-field" className="sr-only">*/}
                            {/*    Search*/}
                            {/*</label>*/}
                            {/*<MagnifyingGlassIcon*/}
                            {/*    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"*/}
                            {/*    aria-hidden="true"*/}
                            {/*/>*/}
                            {/*<input*/}
                            {/*    id="search-field"*/}
                            {/*    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"*/}
                            {/*    placeholder="Zoeken..."*/}
                            {/*    type="search"*/}
                            {/*    name="search"*/}
                            {/*/>*/}
                            <div
                                className={
                                    'mt-6 opacity-50 text-sm max-md:text-xs max-md:mt-3'
                                }
                            >
                                Hulp nodig
                                <span className={'max-md:hidden'}>
                                    {' '}
                                    of vragen
                                </span>
                                ? Bel
                                <span className={'max-md:hidden'}>
                                    {' '}
                                    ons gerust even op
                                </span>{' '}
                                +31(0)30-833 01 02
                            </div>
                        </form>
                        <div className="flex items-center gap-x-4 lg:gap-x-6">
                            <button
                                type="button"
                                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">
                                    View notifications
                                </span>
                                <BellIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>

                            {/* Separator */}
                            <div
                                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                                aria-hidden="true"
                            />

                            {/* Profile dropdown */}
                            <Menu as="div" className="relative">
                                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                    <span className="sr-only">
                                        Open user menu
                                    </span>
                                    {/*<img*/}
                                    {/*    className="h-8 w-8 rounded-full bg-gray-50"*/}
                                    {/*    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                                    {/*    alt=""*/}
                                    {/*/>*/}
                                    <span className=" flex items-center">
                                        <span
                                            className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                            aria-hidden="true"
                                        >
                                            Ingelogd
                                        </span>
                                        <ChevronDownIcon
                                            className="ml-2 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {({ active }) => (
                                                    <a
                                                        href={item.href}
                                                        className={classNames(
                                                            active
                                                                ? 'bg-gray-50'
                                                                : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                        )}
                                                    >
                                                        {item.name}
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
    ]
}

export default Sidebar
