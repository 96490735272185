import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import ReactDOM from 'react-dom'

import ReactStarsRating from 'react-awesome-stars-rating'
import Input from './input'

function ReviewPanel(props) {
    const { hash } = useParams()
    const [review, setReview] = useState([])
    const [room, setRoom] = useState(null)
    const [rating, setRating] = useState(review.value ?? 1)
    const [ratingDisabled, setRatingDisabled] = useState(false)
    const [service, setService] = useState(review.service ?? 0)
    const [puzzles, setPuzzles] = useState(review.puzzles ?? 0)
    const [atmosphere, setAtmosphere] = useState(review.atmosphere ?? 0)
    const [showdetails, setShowDetails] = useState(false)
    const [usedChars, setUsedChars] = useState(
        review.content ? review.content.length ?? 0 : 0,
    )

    const saveRating = (e) => {
        axios
            .post('https://api.escaped.online/UPDATE/REVIEW_VALUE', {
                hash: hash,
                value: rating,
            })
            .then((response) => {
                console.log('review saved')
            })
            .then((response) => {
                setShowDetails(true)
                setRatingDisabled(true)
            })
    }

    const saveContent = (e) => {
        if (service === 0 || puzzles === 0 || atmosphere === 0) {
            alert(
                'Geef eerst een beoordeling voor de sfeer, de puzzels en de service',
            )
            return
        }

        const value = document.getElementById('reviewContent').value

        if (value.length < 5) {
            alert('Je review is te kort')
            return
        }

        axios
            .post('https://api.escaped.online/UPDATE/REVIEW_CONTENT', {
                hash: hash,
                value: value,
            })
            .then((response) => {
                alert('Bedankt, jouw review is opgeslagen')
                // forward to escaped-app.com
                window.location.href = 'https://escaped-app.com'
            })
    }

    const saveService = (rating) => {
        setService(rating)
        axios
            .post('https://api.escaped.online/UPDATE/REVIEW_META', {
                hash: hash,
                key: 'service',
                value: rating,
            })
            .then((response) => {
                console.log('review meta saved')
            })
    }

    const savePuzzles = (rating) => {
        setPuzzles(rating)
        axios
            .post('https://api.escaped.online/UPDATE/REVIEW_META', {
                hash: hash,
                key: 'puzzles',
                value: rating,
            })
            .then((response) => {
                console.log('review meta saved')
            })
    }

    const saveAtmosphere = (rating) => {
        setAtmosphere(rating)
        axios
            .post('https://api.escaped.online/UPDATE/REVIEW_META', {
                hash: hash,
                key: 'atmosphere',
                value: rating,
            })
            .then((response) => {
                console.log('review meta saved')
            })
    }

    const updateMaxChars = (e) => {
        if (e.target.value.length > 2500) {
            e.target.value = e.target.value.substring(0, 2500)
        }
        document.getElementById('usedChars').innerHTML = e.target.value.length
    }

    useEffect(() => {
        if (review.length === 0) {
            axios
                .get('https://api.escaped.online/GET/REVIEW/' + hash)
                .then((response) => {
                    setReview(response.data)
                    console.log(response.data)
                    setRating(response.data.value ?? 1)
                    setUsedChars(response.data.content.length ?? 0)
                    setPuzzles(response.data.puzzles ?? 0)
                    setService(response.data.service ?? 0)
                    setAtmosphere(response.data.atmosphere ?? 0)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else if (room === null) {
            axios
                .get('https://api.escaped.online/GET/ROOM/' + review.room_id)
                .then((response) => {
                    setRoom(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    })

    if (review.length === 0 || room === null) {
        return (
            <div
                className={
                    'm-auto mt-10 p-10 min-h-[50vh] min-w-[500px] w-[50vw] max-sm:min-w-[100vw] box-border shadow-md shadow-gray-400 rounded-xl bg-white '
                }
            >
                <div>
                    <h1 className={'text-2xl'}>Review wordt geladen...</h1>
                </div>
            </div>
        )
    } else {
        return (
            <div
                className={
                    'm-auto mt-10 p-10 min-h-[50vh] min-w-[500px] w-[50vw] max-sm:min-w-[100vw] box-border shadow-md shadow-gray-400 rounded-xl bg-white '
                }
            >
                <div className={'pb-9 border-b-2 border-gray-100'}>
                    <h1 className={'text-2xl mb-6 font-bold'}>
                        Geef jouw mening!
                    </h1>
                    <RoomDetail></RoomDetail>
                    <p className={'mt-5 text-center'}>
                        Onlangs heb je <strong>{room.name ?? ''}</strong>{' '}
                        gespeeld. <br />
                        We zijn benieuwd wat je er van vond!
                    </p>
                </div>
                <Score></Score>
                <ScoreDetails></ScoreDetails>
            </div>
        )
    }

    function RoomDetail() {
        let image =
            room.image != null
                ? JSON.parse(room.image.replaceAll(/\\/g, ''))
                : ''
        return (
            <div
                className={
                    'mt-10 shadow-gray-400 border-gray rounded-xl shadow-sm bg-gray-200 overflow-hidden h-auto w-full'
                }
            >
                <div
                    className={
                        'align-top mt-0 inline-block w-[230px] h-[153px] max-sm:w-[130px] max-sm:h-[100px] overflow-hidden'
                    }
                    style={{
                        backgroundImage: 'url(' + image.small + ')',
                        backgroundPosition: 'center top',
                        backgroundSize: 'cover',
                    }}
                ></div>

                <div
                    className={
                        ' align-top w-[calc(100%-230px)] p-4 inline-block max-sm:w-[calc(100%-135px)]'
                    }
                >
                    <h2
                        className={
                            'line-clamp-1 mb-2 text-xl font-bold w-full h-auto max-sm:text-xs'
                        }
                    >
                        {room.name ?? ''}
                    </h2>
                    <p
                        className={
                            'text-xs line-clamp-5 overflow-hidden max-sm:line-clamp-4 max-sm:text-[9px] max-sm:leading-[1.3em]'
                        }
                    >
                        {room.short_description}
                    </p>
                </div>
            </div>
        )
    }

    function Score() {
        return (
            <div className={'mt-10'}>
                <h3
                    className={
                        'text-xl text-center font-bold mb-2 max-sm:text-xs'
                    }
                >
                    Welk cijfer geef je deze kamer?
                </h3>
                <div className={'flex place-content-center'}>
                    {/*INPUT FIELD WITH NUMBER 1-10 POSSIBLE*/}
                    <Input
                        disabled={ratingDisabled}
                        id={'rating'}
                        decimals={1}
                        type={'number'}
                        min={1}
                        max={10}
                        step={0.1}
                        value={rating}
                        onChange={(e) => {
                            e.preventDefault()
                            setRating(e.target.value)
                        }}
                        forceClassname={
                            'flex w-30 m-auto text-5xl color-red-600 outline-none  text-center border-b-2'
                        }
                    />
                </div>
                <button
                    onClick={(e) => {
                        saveRating(e)
                    }}
                    className={
                        'btn primary clear-both block m-auto float-none w-full mt-5 mb-10 cursor-pointer hover:shadow-md shadow-2-strong shadow-gray-700' +
                        (ratingDisabled ? ' !hidden' : '')
                    }
                >
                    Opslaan
                </button>
            </div>
        )
    }

    function ScoreDetails() {
        if (showdetails) {
            return [
                // <p className={"text-center mt-4 text-green-600"}>Bedankt voor jouw beoordeling! Wil je nog wat details geven?</p>,
                <div className={'mt-10'}>
                    <hr></hr>
                    <div></div>
                    <div className={'mt-5'}>
                        <h2 className={'text-large text-center  mb-2'}>
                            Wat vond je van de <strong>sfeer</strong>?
                        </h2>
                        <div className={'flex place-content-center'}>
                            <ReactStarsRating
                                starGap={4}
                                size={35}
                                count={5}
                                className={'flex w-auto m-auto'}
                                value={atmosphere}
                                onChange={saveAtmosphere}
                            ></ReactStarsRating>
                            <div className={'text-2xl mt-2 font-bold'}>
                                {atmosphere}
                            </div>
                        </div>
                    </div>
                    <div className={'mt-5'}>
                        <h2 className={'text-large text-center  mb-2'}>
                            Wat vond je van de <strong>puzzels?</strong>
                        </h2>
                        <div className={'flex place-content-center'}>
                            <ReactStarsRating
                                starGap={4}
                                size={35}
                                count={5}
                                className={'flex w-auto m-auto'}
                                value={puzzles}
                                onChange={savePuzzles}
                            ></ReactStarsRating>
                            <div className={'text-2xl mt-2 font-bold'}>
                                {puzzles}
                            </div>
                        </div>
                    </div>
                    <div className={'mt-5'}>
                        <h2 className={'text-large text-center  mb-2'}>
                            Wat vond je van de <strong>service</strong>?
                        </h2>
                        <div className={'flex place-content-center'}>
                            <ReactStarsRating
                                starGap={4}
                                size={35}
                                count={5}
                                className={'flex w-auto m-auto'}
                                value={service}
                                onChange={saveService}
                            ></ReactStarsRating>
                            <div className={'text-2xl mt-2 font-bold'}>
                                {service}
                            </div>
                        </div>
                    </div>
                    <hr className={'mt-5'}></hr>
                    <p className={'text-center mt-10 '}>
                        Voeg een korte toelichting toe. Dat helpt toekomstige
                        spelers een keuze te maken. Zorg er wel voor dat je geen
                        spoilers weg geeft!
                    </p>
                    <textarea
                        onKeyDown={updateMaxChars}
                        id={'reviewContent'}
                        className={
                            'block h-40 p-2.5 mt-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 outline-none'
                        }
                    >
                        {review.content}
                    </textarea>
                    <div className={'text-sm mt-2'}>
                        <span className={'mr-1'} id={'usedChars'}>
                            {usedChars}
                        </span>
                        / 1500
                    </div>
                    <input
                        onClick={saveContent}
                        className={
                            'clear-both block m-auto float-none w-full mt-5 mb-10 cursor-pointer hover:shadow-md shadow-2-strong shadow-gray-700'
                        }
                        type={'button'}
                        value={'Opslaan'}
                    ></input>
                </div>,
            ]
        } else {
            return <div></div>
        }
    }
}

export default ReviewPanel
