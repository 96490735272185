import React from 'react'
import Dropzone from 'react-dropzone'
import axios from 'axios'

export default function ConsoleImageUpload(props) {
    let image = ''
    if (props.value !== undefined) {
        // props.value is array, take the first
        if (Array.isArray(props.value)) {
            image = props.value[0]
        } else {
            image = props.value
        }

        // if value string contains :data
        if (image.includes('data:')) {
            image = props.value
        } else {
            // console.log(props.value);
            image = JSON.parse(image.replace(/\\/g, ''))
            image = image.original ?? image.small

            console.log(image)
        }
    } else {
        image = ''
    }

    return [
        <div className={props.className}>
            <label>{props.label}</label>
            <input
                onChange={(event) => {
                    props.updateValue(event)
                }}
                type="hidden"
                name={props.variable}
                className={props.class}
                value={image}
            />
            <Dropzone
                onDrop={(acceptedFiles) =>
                    props.handleFileUpload(acceptedFiles)
                }
            >
                {({ getRootProps, getInputProps }) => (
                    <div
                        className={
                            props.className +
                            ' cursor-pointer h-full relative border-black border border-solid dropzone ' +
                            props.dropzoneClassName
                        }
                        {...getRootProps()}
                        style={{ backgroundImage: 'url(' + image + ')' }}
                    >
                        <input {...getInputProps()} accept={props.fileTypes} />
                        {image == null || image === '' ? (
                            <p
                                className={
                                    'bg-white bg-opacity-80 opacity-50 m-3 p-3 absolute bottom-3 text-center text-[10px] w-auto block text-center hover:opacity-100 cursor-pointer leading-3'
                                }
                            >
                                Klik hier om een bestand te uploaden of sleep
                                een bestand hier naartoe
                            </p>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </Dropzone>
        </div>,
    ]
}
