import React from 'react'

function NoAccessYet(props) {
    return (
        <div className="flex justify-center items-center mt-[20vh]">
            <div className="text-center">
                {/*ICON LOCK*/}
                <svg
                    className="w-20 h-20 mx-auto mb-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#999"
                >
                    <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm0-5a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zm0-4a1 1 0 011 1v1a1 1 0 11-2 0V8a1 1 0 011-1z"
                        clipRule="evenodd"
                    />
                </svg>
                <h1 className="text-4xl font-bold text-[#999]">
                    Nog geen toegang
                </h1>
                <p className="text-l mt-5 text-[#999]">
                    Deze functie wordt binnenkort open gesteld
                </p>
                <p className={'text-l mt-5 text-[#444] max-w-[700px]'}>
                    {props.message}
                </p>
                {props.expected ? (
                    <p
                        className={
                            'bg-black text-white px-5 py-2 rounded-xl inline-block mt-5'
                        }
                    >
                        Verwachte activering: {props.expected}
                    </p>
                ) : (
                    <></>
                )}
            </div>
        </div>
    )
}

export default NoAccessYet
