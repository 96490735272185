import { useEffect, useState } from 'react'
import axios from 'axios'
import Loading from './loading'
import checkIfAdmin from '../functions/login/checkifadmin'
import CryptoJS from 'crypto-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaPause } from 'react-icons/fa'

const Reviews = () => {
    const [reviewList, setReviewList] = useState([])

    useEffect(() => {
        loadReviews()
    }, [])

    async function loadReviews() {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/REVIEWS/?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    // order ivitations by date
                    response.data.sort((a, b) => {
                        return new Date(b.datetime) - new Date(a.datetime)
                    })
                    setReviewList(response.data)
                    return true
                } else {
                    return false
                }
            })
    }

    var returnValue = []

    let tableData = reviewList.map((review) => {
        return (
            <tr
                className={
                    'hover:bg-gray-100 ' +
                    (review.status === 'paused' ? ' bg-orange-200' : 'bg-white')
                }
            >
                <ReviewListItem review={review} />
            </tr>
        )
    })

    returnValue.push(
        <div className="">
            <div className="overflow-x-auto ">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className=" w-[100%] table-auto divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        ID
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                    >
                                        Kamer
                                    </th>
                                    <th
                                        scope="col"
                                        className="px- py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Score
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        Review tekst
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        User
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    >
                                        datum
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {tableData}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>,
    )

    return returnValue
}

const EditButton = (props) => {
    return (
        // button with on click function to open new page with edit url review
        <a
            href={
                'https://api.escaped.online/openapp/review/' + props.review.hash
            }
            className={
                'p-2 text-xs cursor-pointer bg-orange-400 hover:bg-orange-600 text-white '
            }
        >
            Bewerken
        </a>
    )
}
const ReviewListItem = (props) => {
    return (
        <>
            <td className={'p-2 whitespace-nowrap opacity-20'}>
                <a href={''}>{props.review.ID}</a>
            </td>
            <td className={'p-2 whitespace-nowrap'}>
                {props.review.room_name}
            </td>
            <td className={'p-2'}>{props.review.value}</td>
            <td
                className={
                    'p-2 ' + (props.review.content == null ? 'opacity-20' : '')
                }
            >
                {(props.review.content ?? 'Geen tekst ingevuld').replaceAll(
                    '\\n',
                    '\n',
                )}
            </td>
            <td className={'p-2 '}>{props.review.user_id}</td>
            <td className={'p-2 whitespace-nowrap'}>
                {new Date(props.review.datetime).toLocaleDateString()}
            </td>
            <td>
                {props.review.status === 'paused' ? (
                    <EditButton review={props.review} />
                ) : (
                    ''
                )}
            </td>
        </>
    )
}

export default Reviews
