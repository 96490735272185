import * as React from 'react'
import ReactDOM from 'react-dom/client'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useParams,
} from 'react-router-dom'
import './index.css?v=0.1'
import Configurator from './widgets/configurator'
import CompanyList from './widgets/companylist'
import RoomList from './widgets/roomlist'
import Navigation from './widgets/navigation'
import PartnerPanel from './widgets/partnerpanel'
import ReviewPanel from './widgets/reviewpanel'
import reportWebVitals from './reportWebVitals'
import Sidebar from './widgets/sidebar'
import Dashboard from './widgets/dashboard'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ConfigFields from './widgets/configfields'
import Iframe from 'react-iframe'
import LoginForm from './widgets/loginForm'
import CompanyDetails from './widgets/companydetails'
import MyCompanyDetails from './widgets/mycompanydetails'
import MyRooms from './widgets/myrooms'
import Back from './widgets/back'
import NoAccessYet from './widgets/noaccessyet'
import Contact from './widgets/contact'
import ChatSupport from './widgets/chatsupport'
import News from './widgets/news'
import AdminStatistics from './widgets/adminstatistics'
import Invitations from './widgets/invitations'
import Invitation from './widgets/invitation'
import Roomupdates from './widgets/roomupdates'
import Reviews from './widgets/reviews'
import Myreviews from './widgets/myreviews'
import Lastminutes from './widgets/lastminutes'
import Logos from './widgets/Logos'

const root = ReactDOM.createRoot(document.getElementById('root'))
// get value from cookie

root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px] ">
                            <Dashboard />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/logout"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px] ">
                            <Dashboard />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/login/:companyid/:key/:secret/"
                    element={[<LoginForm />, <ChatSupport />]}
                />
                <Route
                    path="/mycompany"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <MyCompanyDetails />,
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/myrooms"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <MyRooms />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/myrooms/:roomID"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <Back url={'/myrooms'} />,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <MyRooms />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/myrooms/:roomID/:openTab"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <Back url={'/myrooms'} />,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <MyRooms />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/mybookings"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <NoAccessYet
                                message={
                                    'Hier kan je binnenkort zien welke boekingen via Escaped zijn binnen gekomen'
                                }
                            />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/myreviews"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-10 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <Myreviews></Myreviews>
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/myads"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <NoAccessYet
                                message={
                                    'Zodra we ons advertentiemodel lanceren kan jij jouw advertenties hier beheren'
                                }
                            />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/mylastminutes"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <Lastminutes />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
                <Route
                    path="/contact"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <Contact />
                        </main>,
                        <ChatSupport />,
                    ]}
                />

                <Route
                    path="/statistics"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <AdminStatistics />
                        </main>,
                    ]}
                />

                <Route
                    path="/rooms"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0px] max-lg:mr-[0px] max-md:px-[5px]">
                            <RoomList />
                        </main>,
                    ]}
                />
                <Route
                    path="/list"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 ml-[240px] max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <CompanyList />
                        </main>,
                    ]}
                />
                <Route
                    path="/location/:id"
                    element={[<LoginForm />, <Configurator />]}
                />
                <Route
                    path="/logos"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <Logos />
                        </main>,
                    ]}
                />
                <Route
                    path="/partner/:hash"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[4px] max-lg:mr-[4px] max-md:px-[5px]">
                            <PartnerPanel />
                        </main>,
                    ]}
                />
                <Route
                    path="/news"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0px] max-lg:mr-[0px] max-md:px-[5px]">
                            <News />
                        </main>,
                    ]}
                />
                <Route
                    path="/news/:newsid"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0px] max-lg:mr-[0px] max-md:px-[5px]">
                            <News />
                        </main>,
                    ]}
                />
                <Route
                    path="/invitations"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0px] max-lg:mr-[0px] max-md:px-[5px]">
                            <Invitations />
                        </main>,
                    ]}
                />
                <Route
                    path="/reviews"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0px] max-lg:mr-[0px] max-md:px-[5px]">
                            <Reviews />
                        </main>,
                    ]}
                />
                <Route
                    path="/invitations/:companyid"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0px] max-lg:mr-[0px] max-md:px-[5px]">
                            <Invitation />
                        </main>,
                    ]}
                />
                <Route
                    path="/ownerupdates/"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0px] max-lg:mr-[0px] max-md:px-[5px]">
                            <Roomupdates />
                        </main>,
                    ]}
                />
                <Route
                    path="/ownerupdates/:roomid"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0px] max-lg:mr-[0px] max-md:px-[5px]">
                            <Roomupdates />
                        </main>,
                    ]}
                />

                <Route path="/review/:hash" element={[<ReviewPanel />]} />

                {/*BACKUP*/}
                <Route
                    path="/:id"
                    element={[
                        <LoginForm />,
                        <Sidebar></Sidebar>,
                        <main className="py-8 px-8 ml-[260px] mr-10 max-lg:ml-[0] max-md:mr-0 max-md:px-4">
                            <Dashboard />
                        </main>,
                        <ChatSupport />,
                    ]}
                />
            </Routes>
        </Router>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
