import React, { useCallback, useEffect, useState } from 'react'
import checkifadmin from '../functions/login/checkifadmin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import plusIcon from '@heroicons/react/20/solid/esm/PlusIcon'
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import Loading from './loading'
import CryptoJS from 'crypto-js'
import invitation from './invitation'
import { useCookies } from 'react-cookie'

const Invitations = () => {
    const [admin, setAdmin] = useState(false)
    const [invitations, setInvitations] = useState(null)
    const [reminders, setReminders] = useState(null)
    const [newInvitationPopup, setNewInvitationPopup] = useState(false)
    const [companies, setCompanies] = useState(null)
    const [filteredCompanies, setFilteredCompanies] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [emailType, setEmailType] = useState(
        useCookies(['emailType'])[0].emailType ?? 'invitation',
    )

    useEffect(() => {
        checkifadmin().then((response) => {
            if (invitations == null) setAdmin(response)
            loadInvitations()
            loadReminders()
        })
    }, [admin])

    useEffect(() => {
        if (admin && companies === null) {
            loadCompanies()
        }
    }, [admin])

    useEffect(() => {
        // set emailtype as cookie
        document.cookie = 'emailType=' + emailType
    })

    async function loadCompanies() {
        await axios
            .get('https://api.escaped.online/GET/COMPANIES/LIST')
            .then((response) => {
                setCompanies(response.data)

                // filter companies where status = checked
                response.data = response.data.filter((company) => {
                    return company.status === 'checked'
                })

                setFilteredCompanies(response.data)
            })
    }

    async function loadInvitations() {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/INVITATIONS/?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    // order ivitations by date
                    response.data.sort((a, b) => {
                        return new Date(b.datetime) - new Date(a.datetime)
                    })
                    setInvitations(response.data)
                    return true
                } else {
                    return false
                }
            })
    }

    async function loadReminders() {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/REMINDERS/?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    // order ivitations by date
                    response.data.sort((a, b) => {
                        return new Date(b.datetime) - new Date(a.datetime)
                    })
                    setReminders(response.data)
                    return true
                } else {
                    return false
                }
            })
    }

    function InvitationsList() {
        if (invitations === null) {
            return <Loading></Loading>
        }

        if (invitations.length === 0) {
            return (
                <div className={'block w-full'}>
                    <div className={'text-center text-2xl text-gray-500 mt-5'}>
                        Er zijn nog geen uitnodigingen aangemaakt.
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'block w-full mt-5 rounded overflow-hidden'}>
                    {companies === null ? (
                        <Loading></Loading>
                    ) : (
                        invitations.map((invitation) => {
                            const company = companies.find((company) => {
                                return company.ID === invitation.company_id
                            })
                            console.log(invitation)

                            const date = new Date(
                                invitation.datetime,
                            ).toLocaleDateString('nl-NL', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                            const date_short = new Date(
                                invitation.datetime,
                            ).toLocaleString('nl-NL', {
                                year: '2-digit',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                            const activeAfterSent =
                                invitation.datetime < company.last_active
                            const lastActiveDate = new Date(
                                company.last_active,
                            ).toLocaleDateString('nl-NL', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })

                            return (
                                <a
                                    href={'/invitations/' + company.ID}
                                    className={
                                        'flex justify-between items-center px-5 py-1 bg-white border-b border-gray-300 hover:bg-gray-300 cursor-pointer'
                                    }
                                    onClick={(event) => {
                                        window.location.href =
                                            '/invitations/' +
                                            invitation.ID.toString()
                                    }}
                                >
                                    <div className={'flex items-center'}>
                                        <div className={'text-md'}>
                                            {company.name}
                                        </div>
                                    </div>
                                    <div
                                        className={'flex items-center text-sm'}
                                    >
                                        <div
                                            className={
                                                'text-md max-md:hidden opacity-50'
                                            }
                                        >
                                            {date}
                                        </div>
                                        <div
                                            className={
                                                'text-md md:hidden opacity-50'
                                            }
                                        >
                                            {date_short}
                                        </div>
                                        <div
                                            title={lastActiveDate}
                                            className={
                                                'w-2 h-2 float-right ml-2 ' +
                                                (activeAfterSent
                                                    ? ' bg-green-800'
                                                    : ' bg-red-600')
                                            }
                                        ></div>
                                    </div>
                                </a>
                            )
                        })
                    )}
                </div>
            )
        }
    }

    function RemindersList() {
        if (reminders === null) {
            return <Loading></Loading>
        }

        if (reminders.length === 0) {
            return (
                <div className={'block w-full'}>
                    <div className={'text-center text-2xl text-gray-500 mt-5'}>
                        Er zijn nog geen herinneringen verstuurd.
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'block w-full mt-5 rounded overflow-hidden'}>
                    {companies === null ? (
                        <Loading></Loading>
                    ) : (
                        reminders.map((reminder) => {
                            const company = companies.find((company) => {
                                return company.ID === reminder.company_id
                            })
                            console.log(reminder)

                            const date = new Date(
                                reminder.datetime,
                            ).toLocaleDateString('nl-NL', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                            const date_short = new Date(
                                reminder.datetime,
                            ).toLocaleString('nl-NL', {
                                year: '2-digit',
                                month: 'short',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                            const activeAfterSent =
                                reminder.datetime < company.last_active
                            const lastActiveDate = new Date(
                                company.last_active,
                            ).toLocaleDateString('nl-NL', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })

                            return (
                                <a
                                    href={'/invitations/' + company.ID}
                                    className={
                                        'flex justify-between items-center px-5 py-1 bg-white border-b border-gray-300 hover:bg-gray-300 cursor-pointer'
                                    }
                                    onClick={(event) => {
                                        window.location.href =
                                            '/invitations/' +
                                            reminder.ID.toString()
                                    }}
                                >
                                    <div className={'flex items-center'}>
                                        <div className={'text-md'}>
                                            {company.name}
                                        </div>
                                    </div>
                                    <div
                                        className={'flex items-center text-sm'}
                                    >
                                        <div
                                            className={
                                                'text-md max-md:hidden opacity-50'
                                            }
                                        >
                                            {date}
                                        </div>
                                        <div
                                            className={
                                                'text-md md:hidden opacity-50'
                                            }
                                        >
                                            {date_short}
                                        </div>
                                        <div
                                            title={lastActiveDate}
                                            className={
                                                'w-2 h-2 float-right ml-2 ' +
                                                (activeAfterSent
                                                    ? ' bg-green-800'
                                                    : ' bg-red-600')
                                            }
                                        ></div>
                                    </div>
                                </a>
                            )
                        })
                    )}
                </div>
            )
        }
    }

    function Menu() {
        // menu bar with + invitation button
        return (
            <div className={'block w-full'}>
                <button
                    onClick={(event) => {
                        setNewInvitationPopup(true)
                    }}
                    className={
                        ' rounded p-2 bg-white shadow shadow-md inline-block'
                    }
                >
                    <FontAwesomeIcon icon={faPlus} className={'mr-2'} />
                    Nieuwe{' '}
                    {emailType === 'invitation' ? 'uitnodiging' : 'herinnering'}
                </button>
                {/*    SELECT DROPDOWN BETWEEN uitnodiging AND herinnering and set emailType to invitation or reminder*/}
                <div className={'flex justify-end mt-5 inline-block'}>
                    <select
                        onChange={(event) => {
                            setEmailType(event.target.value)
                        }}
                        className={'rounded p-2 bg-white shadow shadow-md'}
                        defaultValue={emailType}
                    >
                        <option value={'invitation'}>Uitnodigingen</option>
                        <option value={'reminder'}>Herinneringen</option>
                    </select>
                </div>
            </div>
        )
    }

    function NewInvitationPopup() {
        if (newInvitationPopup) {
            return (
                <div
                    className={
                        'fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 z-50'
                    }
                >
                    <div
                        className={
                            'h-[50vh] w-[50vw] max-h-[50vh] overflow-hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl shadow shadow-md max-sm:w-full max-sm:max-h-[90vh] max-sm:h-[90vh]'
                        }
                    >
                        <SearchForm />
                        <SearchResults />
                    </div>
                </div>
            )
        }
        return null
    }

    async function findCompany(event, search) {
        if (event.keyCode != 13) {
            return
        }

        setSearchValue(search)

        // setSearchValue(search);
        // sort companies by name
        companies.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })

        // filter companies
        setFilteredCompanies(
            companies.filter((company) => {
                return (
                    company.name.toLowerCase().includes(search.toLowerCase()) &&
                    company.status === 'checked'
                )
            }),
        )
    }

    const SearchForm = () => {
        return (
            <div
                key={'1234'}
                className={'px-5 py-8 min-w-[600px] max-sm:!min-w-[100vw]'}
            >
                <div
                    onClick={(event) => {
                        setNewInvitationPopup(false)
                    }}
                    className={
                        'absolute top-0 right-0 p-2 cursor-pointer text-xl m-2'
                    }
                >
                    <FontAwesomeIcon icon={faClose} />
                </div>
                <input
                    id={'companySearch'}
                    defaultValue={searchValue}
                    type={'text'}
                    className={
                        'drop-shadow-md shadow-gray-400 shadow w-full rounded p-3 text-xl outline-none mt-5'
                    }
                    placeholder={'Zoek een bedrijf...'}
                    onKeyUp={(event) => {
                        findCompany(event, event.target.value)
                    }}
                />
            </div>
        )
    }

    function SearchResults() {
        if (filteredCompanies === null) {
            return <Loading></Loading>
        }

        return (
            <div
                className={
                    'px-5 pb-3 overflow-scroll max-h-[calc(50vh-140px)] max-sm:max-h-[calc(90vh-140px)]'
                }
            >
                {filteredCompanies.map((company) => {
                    // if company.iD is already in invitations, then set alreadySent to true
                    var alreadySent = false
                    if (emailType === 'invitation') {
                        alreadySent = invitations.find((invitation) => {
                            return invitation.company_id === company.ID
                        })
                    } else {
                        alreadySent = reminders.find((reminder) => {
                            return reminder.company_id === company.ID
                        })
                    }

                    return (
                        <div
                            className={
                                'flex justify-between items-center px-5 py-1 border-b border-gray-300 hover:bg-gray-300 cursor-pointer' +
                                (alreadySent ? ' opacity-20' : '')
                            }
                            onClick={(event) => {
                                window.location.href =
                                    '/invitations/' + company.ID.toString()
                            }}
                        >
                            <div className={'flex items-center'}>
                                <div className={'text-md'}>{company.name}</div>
                            </div>
                            <div className={'flex items-center'}>
                                <button
                                    className={
                                        'rounded-full bg-orange-600 text-white px-1'
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    if (admin) {
        return [
            <Menu />,

            emailType === 'invitation' ? (
                <InvitationsList />
            ) : (
                <RemindersList />
            ),
            <NewInvitationPopup />,
        ]
    }

    return 'Je hebt geen toegang tot deze gegevens'
}

export default Invitations
