import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from './loading'
import axios from 'axios'
import { decode as base64_decode, encode as base64_encode } from 'base-64'
import { Buffer } from 'buffer'
import { renderToString } from 'react-dom/server'
import replaceInlineImagesForBlob from '../functions/replaceInlineImagesForBlob'
import CryptoJS from 'crypto-js'
import { useCookies } from 'react-cookie'

const Invitation = () => {
    const { companyid } = useParams()
    const [roomImages, setRoomImages] = useState(<Loading />)
    const [newFeatures, setNewFeatures] = useState(<Loading />)
    const [rooms, setRooms] = useState([])
    const [companyRooms, setCompanyRooms] = useState([])
    const [roomCount, setRoomCount] = useState(0)
    const [allCid, setAllCid] = useState([])
    const [companyKey, setCompanyKey] = useState('')
    const [companySecret, setCompanySecret] = useState('')
    const [companyEmails, setCompanyEmails] = useState([])
    const [companyName, setCompanyName] = useState('')
    const [choosenEmail, setChoosenEmail] = useState('')
    const [loginUrl, setLoginUrl] = useState('')
    const [lastSend, setLastSend] = useState(null)
    const [emailType, setEmailType] = useState(
        useCookies(['emailType'])[0].emailType ?? 'invitation',
    )

    useEffect(() => {
        if (emailType === 'invitation') {
            loadInvitationLink()
        } else {
            loadReminderLink()
        }
    }, [])

    useEffect(() => {
        if (loginUrl !== '') {
            loadRoomImages()
            loadNewFeaturesImage()
        }
    }, [loginUrl])

    async function loadInvitationLink() {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/INVITATION/' +
                    companyid +
                    '?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    setCompanyKey(response.data.key)
                    setCompanySecret(response.data.secret)
                    setCompanyName(response.data.name)
                    setCompanyEmails(response.data.email)
                    setLastSend(
                        response.data.invitation_sent != null
                            ? new Date(
                                  response.data.invitation_sent,
                              ).toLocaleDateString('nl-NL', {
                                  weekday: 'long',
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                              })
                            : 'Nog niet verzonden',
                    )
                    setLoginUrl(
                        'https://console.escaped.online/login/' +
                            companyid +
                            '/' +
                            response.data.key +
                            '/' +
                            response.data.secret,
                    )
                    return true
                } else {
                    return false
                }
            })
    }

    async function loadReminderLink() {
        let adminID = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminID'))
            .split('=')[1]
        let adminKey = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminKey'))
            .split('=')[1]
        let adminSecret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('adminSecret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
        hmac.update(requesttime.toString() + adminKey.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios
            .get(
                'https://api.escaped.online/CONSOLE/REMINDER/' +
                    companyid +
                    '?adminid=' +
                    adminID +
                    '&key=' +
                    adminKey +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )
            .then((response) => {
                if (response.status === 200) {
                    setCompanyKey(response.data.key)
                    setCompanySecret(response.data.secret)
                    setCompanyName(response.data.name)
                    setCompanyEmails(response.data.email)
                    setLastSend(
                        response.data.reminder_sent != null
                            ? new Date(
                                  response.data.reminder_sent,
                              ).toLocaleDateString('nl-NL', {
                                  weekday: 'long',
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                              })
                            : 'Nog niet verzonden',
                    )
                    setLoginUrl(
                        'https://console.escaped.online/login/' +
                            companyid +
                            '/' +
                            response.data.key +
                            '/' +
                            response.data.secret,
                    )
                    return true
                } else {
                    return false
                }
            })
    }

    async function loadNewFeaturesImage() {
        const Features = (
            <div
                style={{
                    'line-height': '1.2em',
                    'text-align': 'center',
                    zoom: '1.3',
                }}
            >
                <div
                    style={{
                        width: '200px',
                        margin: '5px',
                        display: 'inline-block',
                        'background-color': '#EC6807',
                        color: 'white',
                        padding: '7px',
                        'border-radius': '5px',
                        'vertical-align': 'top',
                        'text-align': 'left',
                    }}
                >
                    <div style={{ height: '5px' }}></div>
                    <div
                        style={{
                            'text-align': 'center',
                            'font-weight': 'bold',
                            'font-size': '15px',
                        }}
                    >
                        Samen Boeken
                    </div>
                    <div style={{ height: '5px', 'font-size': '11px' }}></div>
                    <div style={{ 'font-size': '13px' }}>
                        Samen spelen is ook samen boeken! Met deze datumprikker
                        functie kunnen spelers direct zien welke tijdsblokken er
                        in jouw kamer nog beschikbaar zijn en zo hun volgende
                        escape sneller plannen.
                    </div>
                </div>

                <div
                    style={{
                        width: '200px',
                        margin: '5px',
                        display: 'inline-block',
                        'background-color': '#EC6807',
                        color: 'white',
                        padding: '7px',
                        'border-radius': '5px',
                        'vertical-align': 'top',
                        'text-align': 'left',
                    }}
                >
                    <div style={{ height: '5px' }}></div>
                    <div
                        style={{
                            'text-align': 'center',
                            'font-weight': 'bold',
                            'font-size': '15px',
                        }}
                    >
                        Last Minutes
                    </div>
                    <div style={{ height: '5px', 'font-size': '11px' }}></div>
                    <div style={{ 'font-size': '13px' }}>
                        Vul gemakkelijk een gat in de agenda van jouw kamer(s)
                        door een Last Minute aan te bieden aan onze gebruikers.
                        Gebruik hiervoor de handige Pushberichten functie. Of
                        reageer op Last Minute aanvragen van gebruikers.
                    </div>
                </div>

                <div
                    style={{
                        width: '200px',
                        margin: '5px',
                        display: 'inline-block',
                        'background-color': '#EC6807',
                        color: 'white',
                        padding: '7px',
                        'border-radius': '5px',
                        'vertical-align': 'top',
                        'text-align': 'left',
                    }}
                >
                    <div style={{ height: '5px' }}></div>
                    <div
                        style={{
                            'text-align': 'center',
                            'font-weight': 'bold',
                            'font-size': '15px',
                        }}
                    >
                        Pushberichten
                    </div>
                    <div style={{ height: '5px', 'font-size': '11px' }}></div>
                    <div style={{ 'font-size': '13px' }}>
                        Heeft jouw agenda even een boost nodig? Stuur een
                        pushbericht naar alle spelers die jouw kamer op hun
                        wishlist hebben staan. Deel nieuws, speciale acties etc.
                        direct met gebruikers van de app!
                    </div>
                </div>

                <div
                    style={{
                        width: '200px',
                        margin: '5px',
                        display: 'inline-block',
                        'background-color': '#EC6807',
                        color: 'white',
                        padding: '7px',
                        'border-radius': '5px',
                        'vertical-align': 'top',
                        'text-align': 'left',
                    }}
                >
                    <div style={{ height: '5px' }}></div>
                    <div
                        style={{
                            'text-align': 'center',
                            'font-weight': 'bold',
                            'font-size': '15px',
                        }}
                    >
                        Foto module
                    </div>
                    <div style={{ height: '5px', 'font-size': '11px' }}></div>
                    <div style={{ 'font-size': '13px' }}>
                        Maak foto's van jouw spelers en laat onze module het
                        opmaakwerk doen!
                    </div>
                </div>

                <div
                    style={{
                        width: '200px',
                        margin: '5px',
                        display: 'inline-block',
                        'background-color': '#EC6807',
                        color: 'white',
                        padding: '7px',
                        'border-radius': '5px',
                        'vertical-align': 'top',
                        'text-align': 'left',
                    }}
                >
                    <div style={{ height: '5px' }}></div>
                    <div
                        style={{
                            'text-align': 'center',
                            'font-weight': 'bold',
                            'font-size': '15px',
                        }}
                    >
                        Boekingsmodule
                    </div>
                    <div style={{ height: '5px', 'font-size': '11px' }}></div>
                    <div style={{ 'font-size': '13px' }}>
                        Rechtstreeks via Escaped jouw kamer laten boeken? Dat
                        kan binnenkort!
                    </div>
                </div>

                <div
                    style={{
                        width: '200px',
                        margin: '5px',
                        display: 'inline-block',
                        'background-color': '#EC6807',
                        color: 'white',
                        padding: '7px',
                        'border-radius': '5px',
                        'vertical-align': 'top',
                        'text-align': 'left',
                    }}
                >
                    <div style={{ height: '5px' }}></div>
                    <div
                        style={{
                            'text-align': 'center',
                            'font-weight': 'bold',
                            'font-size': '15px',
                        }}
                    >
                        Community
                    </div>
                    <div style={{ height: '5px', 'font-size': '11px' }}></div>
                    <div style={{ 'font-size': '13px' }}>
                        Hier kunnen gebruikers o.a. tips, ervaringen,
                        persoonlijke ranglijsten delen met andere escapers en
                        elkaar uitdagen!
                    </div>
                </div>
            </div>
        )

        const html = renderToString(Features)
        let image = await axios
            .post(
                'https://europe-west6-courseflow-338919.cloudfunctions.net/snapshot/image/',
                {
                    content:
                        '<html><head><style>@import url(\'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap\');</style><meta charset="utf-8"></head><body style="margin:0;padding:0;box-sizing:border-box;font-family:\'Open Sans\'">' +
                        html +
                        '</body></html>',
                    width: 900,
                    height: 450,
                    quality: 100,
                    fullpage: false,
                },
                { responseType: 'arraybuffer' },
            )
            .then((response) =>
                Buffer.from(response.data, 'binary').toString('base64'),
            )

        setNewFeatures(
            <img
                style={{ 'max-width': '100%' }}
                src={'data:image/png;base64,' + image}
            />,
        )
    }

    async function loadRoomImages() {
        let allImagesDef = []

        axios
            .get('https://api.escaped.online/GET/ROOMS')
            .then(async (response) => {
                await setRooms(response.data)

                let allImages = []
                let cid = {}
                // promise
                allImages = await new Promise(async (resolve, reject) => {
                    let allImagesTemp = []
                    await response.data
                        .filter(
                            (room) =>
                                room.company_id.toString() ===
                                companyid.toString(),
                        )
                        .map((room) => {
                            allImagesTemp.push(
                                '<div style="width:210px; margin:10px 10px; border-radius:10px; overflow:hidden; box-shadow:1px 1px 7px #999; display:inline-block; text-align:left;"> <div style="width:210px; height:140px; overflow:hidden; display:inline-block;"> <img  style="width:210px;" src="' +
                                    JSON.parse(room.image.replaceAll('\\', ''))
                                        .small +
                                    '" alt="room image"/></div> <div style="height:105px; text-overflow:ellipsis; overflow:hidden; padding:8px; line-height:1.1em;"> <div style="font-weight:600; font-size:15px; margin-bottom:0px">' +
                                    room.name +
                                    '</div> <p style="height:70px; overflow:hidden; font-size:13px; line-height:1.3em;" >' +
                                    room.description.substring(0, 110) +
                                    '...</p> </div> </div>',
                            )
                        })

                    resolve(allImagesTemp)
                })

                setRoomCount(allImages.length)

                for (let i = 0; i < allImages.length; i++) {
                    let html = allImages[i]

                    let image = await axios
                        .post(
                            'https://europe-west6-courseflow-338919.cloudfunctions.net/snapshot/image/',
                            {
                                content:
                                    '<html><head><style>@import url(\'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap\');</style><meta charset="utf-8"></head><body style="margin:0;padding:0;box-sizing:border-box;font-family:\'Open Sans\'">' +
                                    html +
                                    '</body></html>',
                                width: 230,
                                height: 300,
                                quality: 100,
                                fullpage: false,
                            },
                            { responseType: 'arraybuffer' },
                        )
                        .then((response) =>
                            Buffer.from(response.data, 'binary').toString(
                                'base64',
                            ),
                        )

                    let imageBase64 = 'data:image/png;base64,' + image
                    const cidName = 'room' + i + '@cid'

                    allImagesDef.push(
                        <a href={loginUrl}>
                            <img
                                style={{ display: 'inline-block' }}
                                src={imageBase64}
                                alt={'room image'}
                            />
                        </a>,
                    )
                }

                setRoomImages(allImagesDef)
            })
    }

    const Content = () => {
        if (emailType === 'invitation') {
            return (
                <div id={'emailcontent'}>
                    <div
                        style={{
                            'max-width': '850px',
                            width: '90%',
                            'background-color': '#FFFFFF',
                            margin: 'auto',
                            'font-family': 'verdana',
                            'font-size': '15px',
                            'line-height': '1.4em',
                        }}
                    >
                        <div
                            style={{
                                width: '750px',
                                height: '20px',
                                background: '#ffffff',
                            }}
                        ></div>
                        <img
                            src={
                                'https://escaped-app.com/_next/static/media/escaped-logo.6c8b54c4.png'
                            }
                            width={'200'}
                            alt={'logo'}
                            style={{ margin: 'auto', display: 'block' }}
                        />
                        <div
                            style={{
                                width: '100%',
                                height: '40px',
                                background: '#ffffff',
                            }}
                        ></div>
                        <div
                            style={{
                                width: '84%',
                                background: '#ffffff',
                                margin: '0 8% 0 8%',
                            }}
                        >
                            <p>
                                <strong>Beste {companyName},</strong>
                            </p>
                            {/*<p>*/}
                            {/*    Onlangs ontving je van ons mail(s) met daarin een link voor toegang naar jouw beheer omgeving van onze Escaped App. Met deze link kan jij de weergave van jouw kamers aanpassen en andere (eigenaren)functies van onze app gebruiken.*/}
                            {/*</p>*/}
                            {/*<div style={{"width":"100%", "height":"20px", "background":"#ffffff"}}></div>*/}
                            {/*<p>*/}
                            {/*    We hebben geconstateerd dat jouw link (door een foutje in onze code) niet werkte en hebben daarom een nieuwe link gegenereerd. In deze mail vind je de juiste link waarmee je wel kunt inloggen. Excuses voor het ongemak.*/}
                            {/*</p>*/}
                            {/*<div style={{"width":"100%", "height":"20px", "background":"#ffffff"}}></div>*/}
                            {/*<hr/>*/}
                            {/*<div style={{"width":"100%", "height":"20px", "background":"#ffffff"}}></div>*/}
                            <p>
                                Goed nieuws! Jouw escape room
                                {roomCount > 1 ? 's zijn' : ' is'} nu te vinden
                                in onze nieuwe escape room app:{' '}
                                <strong>Escaped!</strong>
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <p>
                                Escaped is een initiatief van escape liefhebbers
                                Rob Pronk (De Morfinekluis, Apeldoorn), Jeanine
                                Hogeveen (Corpse Inc., Baarn) en Jerry Tieben
                                (esQuis, Utrecht – ICT-er en appbouwer).
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <p>
                                Van groepen kregen we vaak de vraag waarom er
                                nog geen echte NL escape app is. Daar moeten we
                                iets mee hadden we bedacht en zo is Escaped
                                geleidelijk aan ontstaan. In Escaped kunnen
                                spelers eenvoudig jouw kamer
                                {roomCount > 1 ? 's' : ''} vinden, toevoegen aan
                                hun favorieten en direct de beschikbaarheid
                                zien! We hebben ook enkele handige functies
                                toegevoegd om de ervaring nog beter te maken!
                                Maar we zitten natuurlijk niet stil. Dit zijn
                                enkele andere features die we de komende periode
                                zullen introduceren:
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>

                            {newFeatures}

                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <p>
                                We hebben je kamer{roomCount > 1 ? 's' : ''}{' '}
                                alvast in de app gezet, maar we geven je
                                uiteraard zelf toegang tot je kamerprofiel,
                                zodat je de gegevens zelf ook kunt aanpassen.
                                Omdat we nog niet voldoende reviews hebben om
                                een realistische beoordeling van jouw kamer
                                {roomCount > 1 ? 's' : ''} te geven, kan je
                                eenmalig zelf een beoordeling invoeren. Klik
                                hieronder op jouw kamer
                                {roomCount > 1 ? 's' : ''} en controleer of deze
                                correct vermeld staat in de app. Als je meerdere
                                locaties hebt zul je voor elke locatie een
                                aparte mail ontvangen.
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <div
                                style={{
                                    'min-height': '100px',
                                    'text-align': 'center',
                                }}
                            >
                                {roomImages}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <p>
                                Heb je vragen en/of opmerkingen? Of specifieke
                                wensen voor jouw vermelding in de app? Laat het
                                ons weten! Dat kan door deze mail te
                                beantwoorden, of bel even met 030 833 01 02
                                <br />
                                <br />
                                En vergeet natuurlijk niet om de app even{' '}
                                <a
                                    style={{
                                        color: '#EC6807',
                                        'text-decoration': 'underline',
                                    }}
                                    href={'https://escaped-app.com'}
                                >
                                    te downloaden
                                </a>
                                . We zijn heel benieuwd naar jullie feedback!
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <p>
                                Wij streven ernaar om samen met jullie een
                                geweldige (objectieve) app te creëren, waar
                                spelers enthousiast van worden en waarbij jij
                                als eigenaar gerichter je doelgroep kan bereiken
                                (last minute pushberichten/acties e.d.) en hopen
                                dan ook op jullie medewerking zodat we zo
                                spoedig mogelijk alle leuke features kunnen
                                implementeren!
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <p>
                                De eerste stap die je kan zetten is het
                                controleren van de kamergegevens en het invoeren
                                van de gemiddelde beoordeling die je van spelers
                                krijgt. Zet je escaperoom op de kaart en trek
                                nieuwe escapers aan!
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    height: '20px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <p>
                                Groeten, Team Escaped
                                <br />
                                Rob Pronk (Morfinekluis), Jeanine Hogeveen
                                (Escaperoom Junkie / Corpse inc.) en Jerry
                                Tieben (esQuis development)
                            </p>

                            <div
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    background: '#ffffff',
                                }}
                            ></div>
                            <p>
                                P.S. Een paar kleine tips van ons:
                                <br />
                                <ul
                                    style={{
                                        'list-style-type': 'disc',
                                        'margin-left': '30px',
                                        'margin-top': '5px',
                                    }}
                                >
                                    <li>
                                        Zorg dat je een{' '}
                                        <strong>opvallende afbeelding</strong>{' '}
                                        instelt. Dit is het eerste wat mensen
                                        zien
                                    </li>
                                    <li>
                                        <strong>Koppel jouw agenda</strong>{' '}
                                        zodat we in <strong>REAL TIME</strong>{' '}
                                        de beschikbaarheid kunnen tonen van
                                        jullie kamer{roomCount > 1 ? 's' : ''}.
                                        Dit is straks ook van belang bij nieuwe
                                        functies zoals de Last Minute & Samen
                                        Boeken functies
                                    </li>
                                </ul>
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    background: '#ffffff',
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div id={'emailcontent'} className={'bg-white p-5'}>
                    Beste {companyName},<br />
                    <br />
                    Vorige week heb je van ons een uitnodiging gehad om
                    (eventueel ) de gegevens van jouw locatie en kamer(s) aan te
                    passen in onze nieuwe escaperoom App: Escaped. Aankomende
                    periode gaan wij ons platform lanceren onder escaperoom
                    spelers en daarmee is jouw locatie gelijk goed zichtbaar.
                    <br />
                    <br />
                    Het viel ons op dat nog niet alle gegevens door jou zijn
                    beoordeeld, waaronder het cijfer* wat jij je kamer(s) geeft.
                    Deze mogelijkheid willen wij jou graag nog bieden, zodat ook
                    nieuwe spelers weten hoe jouw kamer(s) over het algemeen
                    worden beoordeeld.
                    <br />
                    <br />
                    Mocht er binnen 10 dagen nog geen cijfer zijn ingevuld dan
                    regelen wij dat voor je. Wij kijken dan naar diverse
                    platforms en berekenen op basis daarvan het gemiddelde
                    cijfer van jouw kamer(s).
                    <br />
                    <br />
                    *Ter info: Jouw beoordeling telt mee als 15 reviews. Na 20
                    reviews verdwijnt jouw beoordeling uit de app en neemt de
                    gemiddelde beoordeling van spelers het over.
                    <br />
                    <br />
                    Hierbij de link naar jouw kamer(s):
                    <br />
                    {loginUrl}
                    <br />
                    <br />
                    Heb je vragen en/of opmerkingen? Of specifieke wensen voor
                    jouw vermelding in de app? Laat het ons weten! Dat kan door
                    deze mail te beantwoorden, of bel even met 030 833 01 02
                    <br />
                    <br />
                    En vergeet natuurlijk niet om de app te downloaden. We zijn
                    heel benieuwd naar jullie feedback!
                    <br />
                    <br />
                    Groeten,
                    <br />
                    Team Escaped
                    <br />
                    <br />
                    Rob Pronk (Morfinekluis)
                    <br />
                    Jeanine Hogeveen (Escaperoom Junkie / Corpse inc.)
                    <br />
                    Jerry Tieben (esQuis development)
                </div>
            )
        }
    }

    const sendInvitation = async () => {
        let emailContent = document.getElementById('emailcontent').innerHTML
        const formData = new FormData()
        formData.append('from', 'Escaped <info@escaped-app.com>')
        formData.append('to', [choosenEmail])
        // formData.append('to', ["jeanine@planet.nl", "jeanine@escaperoomjunkie.nl"]);
        formData.append('subject', 'Escaped App')

        const response = await replaceInlineImagesForBlob(emailContent)
        console.log(response)

        // loop through allCid
        for (let i = 0; i < response.inlineImages.length; i++) {
            // Attach the base64-encoded image
            formData.append(
                'inline[]',
                response.inlineImages[i].data,
                response.inlineImages[i].cid,
            )
        }

        formData.append('html', response.htmlBody)

        // Attach the base64-encoded image
        // formData.append('attachment', allCid.data, { filename: 'image.png' });

        console.log(formData)
        // use axios to send mail through the mailgun API EU host
        axios
            .post(
                'https://api.eu.mailgun.net/v3/' +
                    process.env.REACT_APP_MAILGUN_DOMAIN +
                    '/messages',
                formData,
                {
                    auth: {
                        username: 'api',
                        password: process.env.REACT_APP_MAILGUN_API_KEY,
                    },
                    headers: { 'Content-Type': 'multipart/form-data' },
                },
            )
            .then((response) => {
                axios
                    .post(
                        'https://api.escaped.online/POST/UPDATE_COMPANY_META',
                        {
                            variable: emailType + '_sent',
                            value: new Date(),
                            ID: companyid,
                        },
                    )
                    .then((response) => {
                        alert('Mail verzonden')
                        window.location.href = '/invitations'
                    })

                console.log(response)
            })
            .catch((error) => {
                alert(error)
            })
    }

    const sendReminder = async () => {
        let emailContent = document.getElementById('emailcontent').innerHTML
        const formData = new FormData()
        formData.append('from', 'Escaped <info@escaped.online>')
        formData.append('to', [choosenEmail])
        // formData.append('to', ["jeanine@planet.nl", "jeanine@escaperoomjunkie.nl"]);
        formData.append('subject', 'RE: Escaped App')
        // make new lines in the email content
        emailContent = emailContent.replace(/<br>/g, '\n')
        formData.append('text', emailContent)

        // use axios to send mail through the mailgun API EU host
        axios
            .post(
                'https://api.eu.mailgun.net/v3/' +
                    process.env.REACT_APP_MAILGUN_DOMAIN +
                    '/messages',
                formData,
                {
                    auth: {
                        username: 'api',
                        password: process.env.REACT_APP_MAILGUN_API_KEY,
                    },
                    headers: { 'Content-Type': 'text/plain' },
                },
            )
            .then((response) => {
                axios
                    .post(
                        'https://api.escaped.online/POST/UPDATE_COMPANY_META',
                        {
                            variable: emailType + '_sent',
                            value: new Date(),
                            ID: companyid,
                        },
                    )
                    .then((response) => {
                        alert('Mail verzonden')
                        window.location.href = '/invitations'
                    })

                console.log(response)
            })
            .catch((error) => {
                alert(error)
            })
    }

    return [
        <div>
            <div className={'w-[750px]  m-3 min-h-[100px] m-auto'}>
                <div className={'flex justify-end'}>
                    {/*// show a <select> input with all companyEmails as option en when selected, set chooseEmail to the selected email*/}
                    {companyEmails != null && companyEmails.length > 0 ? (
                        <select
                            className={'w-[300px] h-[50px] rounded-lg m-3'}
                            onChange={(e) => setChoosenEmail(e.target.value)}
                        >
                            <option value={''}>Kies een emailadres</option>
                            {companyEmails.map((email) => {
                                return <option value={email}>{email}</option>
                            })}
                        </select>
                    ) : (
                        <></>
                    )}
                    <input
                        className={'w-[20vw] px-10 py-2 h-10 mt-4 rounded'}
                        type={'text'}
                        onBlur={(event) => {
                            setChoosenEmail(event.target.value)
                        }}
                        defaultValue={choosenEmail}
                    />

                    <div
                        className={
                            'flex justify-center items-center bg-[#EC6807] text-white w-[150px] h-[50px] rounded-lg m-3 cursor-pointer'
                        }
                        onClick={() =>
                            emailType === 'invitation'
                                ? sendInvitation()
                                : sendReminder()
                        }
                    >
                        <div>Verstuur</div>
                    </div>
                </div>
            </div>
        </div>,
        <div className={'text-center mb-5'}>
            {lastSend != null ? (
                <div>Laatst verstuurd op: {lastSend}</div>
            ) : (
                <> </>
            )}
        </div>,
        <Content />,
    ]
}

export default Invitation
