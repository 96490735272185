import React, { useEffect, useState } from 'react'
import Rooms from './rooms'
import CompanyDetails from './companydetails'
import axios, { Axios } from 'axios'

function ConfigFields(props) {
    const company = props.company

    return (
        <configFieldsWrapper>
            <img src="/images/escaped.png" id="logo" />
            <CompanyDetails company={company} />
            <Rooms company={company} roomsArray={company.rooms ?? false} />
            <input type={'button'} value={'next'} onClick={reload} />
        </configFieldsWrapper>
    )
}

function reload() {
    window.location.reload(false)
}

export default ConfigFields
