import CryptoJS from 'crypto-js'
import axios from 'axios'
import showMessage from '../showmessage'

export default async function loadLastminuteSuggestions(props) {
    let companyID = (
        document.cookie
            .split('; ')
            .find((row) => row.startsWith('companyID')) ?? ''
    ).split('=')[1]
    let key = (
        document.cookie.split('; ').find((row) => row.startsWith('key')) ?? ''
    ).split('=')[1]
    let secret = (
        document.cookie.split('; ').find((row) => row.startsWith('secret')) ??
        ''
    ).split('=')[1]

    // current time in seconds
    let requesttime = Math.floor(Date.now())
    // convert to utf8 bytes
    var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
    hmac.update(requesttime.toString() + key.toString())
    var hash = hmac.finalize()
    // convert to base64
    hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

    return axios
        .get(
            'https://api.escaped.online/CONSOLE/LASTMINUTESUGGESTIONS/?companyID=' +
                companyID +
                '&key=' +
                key +
                '&hash=' +
                hash +
                '&requesttime=' +
                requesttime,
        )
        .then((response) => {
            if (response.data.error === 'true') {
                console.log(response.data)
                showMessage(
                    'Er is iets mis gegaan bij het ophalen van de last minute suggesties',
                )
                return []
            } else {
                return response.data
            }
        })
        .catch((error) => {
            console.log(error)
            showMessage(
                'Er is iets mis gegaan bij het ophalen van de last minute suggesties',
            )
            return []
        })
}
