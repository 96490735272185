import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import RoomList from './roomlist'
import Room from './room'

function PartnerPanel() {
    const { hash } = useParams()
    let [companies, setCompanies] = useState([])
    let [companyID, setCompanyID] = useState(0)
    let [company, setCompany] = useState({})

    useEffect(() => {
        if (companyID === 0) {
            setCompanyID(hash)
            companyID = hash
            console.log(process.env.API_URL ?? 'http://localhost:8080')
            axios
                .get('https://api.escaped.online/GET/COMPANIES/LIST')
                .then((response) => {
                    setCompanies(response.data)
                    response.data.find((company) => {
                        if (company.ID.toString() === companyID.toString()) {
                            setCompany(company)
                        }
                    })
                })
        }
    }, [])

    return (
        <div className={'flex'}>
            <PartnerDetails />
            <PartnerRooms hash={hash} />
        </div>
    )
}

function PartnerDetails() {
    return (
        <div
            className={
                'm-4 mt-5 p-4 h-[50vh] w-1/3 box-border shadow-md shadow-gray-400 rounded-xl bg-white '
            }
        >
            <h2>Partner Details</h2>
        </div>
    )
}

function PartnerRooms(props) {
    return (
        <div
            className={
                'partner m-4 mt-5 partner-rooms w-2/3 h-[320px] border-b border-1 border-gray-500 block overflow-scroll justify-stretch box-border '
            }
        >
            <RoomList companyHash={props.hash} />
        </div>
    )
}

export default PartnerPanel
