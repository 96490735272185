import React from 'react'
import Dropzone from 'react-dropzone'
import axios from 'axios'

export default function ImageUpload(props) {
    function handleFileUpload(files) {
        //get base64 of image
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = function () {
            //set base64 string to variable imageString
            let imageString = reader.result
            // set imageString to background of .dropzone
            document.querySelector('.dropzone').style.backgroundImage =
                `url(${imageString})`

            axios
                .post(
                    props.company != null
                        ? 'https://api.escaped.online/POST/UPDATE_COMPANY_META'
                        : 'https://api.escaped.online/POST/UPDATE_ROOM_META',
                    {
                        variable: 'image',
                        value: imageString,
                        ID:
                            props.room != null
                                ? props.room.ID
                                : props.company.ID,
                    },
                )
                .then((response) => {
                    console.log(response)
                })
        }
    }

    function updateValue(event, dataType) {
        if (dataType) {
            switch (dataType) {
                case 'room_meta':
                    axios
                        .post(
                            'https://api.escaped.online/POST/UPDATE_ROOM_META',
                            {
                                variable: event.target.attributes.name.value,
                                value: event.target.value,
                                ID: props.room.ID,
                            },
                        )
                        .then((response) => {
                            console.log(response)
                        })
                    break

                case 'company_meta':
                    axios
                        .post(
                            'https://api.escaped.online/POST/UPDATE_COMPANY_META',
                            {
                                variable: event.target.attributes.name.value,
                                value: event.target.value,
                                ID: props.company.ID,
                            },
                        )
                        .then((response) => {
                            console.log(response)
                        })
                    break
            }
        }
    }

    let image = ''
    if (props.value !== undefined) {
        // props.value is array, take the first
        if (Array.isArray(props.value)) {
            image = props.value[0]
        } else {
            image = props.value
        }

        // if value string contains :data
        if (image.includes('data:')) {
            image = props.value
        } else {
            // console.log(props.value);
            image = JSON.parse(image.replace(/\\/g, ''))
            image = image.small
        }
    } else {
        image = ''
    }

    return [
        <label>{props.label}</label>,
        <input
            onChange={(event) => {
                updateValue(event, props.dataType)
            }}
            type="hidden"
            name={props.variable}
            className={props.class}
            value={image}
        />,
        <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
                <div
                    className={'dropzone'}
                    {...getRootProps()}
                    style={{ backgroundImage: 'url(' + image + ')' }}
                >
                    <input {...getInputProps()} />
                    <p>
                        Drag 'n' drop some files here, or click to select files
                    </p>
                </div>
            )}
        </Dropzone>,
    ]
}
