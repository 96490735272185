import * as React from 'react'

import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import {
    CursorArrowRaysIcon,
    EnvelopeOpenIcon,
    UsersIcon,
} from '@heroicons/react/24/outline'
import eyeIcon from '@heroicons/react/20/solid/esm/EyeIcon'
import shareIcon from '@heroicons/react/24/solid/esm/ShareIcon'
import starIcon from '@heroicons/react/20/solid/esm/StarIcon'
import checkIcon from '@heroicons/react/20/solid/esm/CheckIcon'

import { Fragment, useEffect, useState } from 'react'
import {
    ChatBubbleLeftEllipsisIcon,
    TagIcon,
    UserCircleIcon,
} from '@heroicons/react/20/solid'
import Loading from './loading'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import checkifadmin from '../functions/login/checkifadmin'
import checkIfLoggedin from '../functions/login/checkifloggedin'
import NoAccessYet from './noaccessyet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFaceGrin,
    faMessage,
    faPerson,
} from '@fortawesome/free-solid-svg-icons'
import loadReviews from '../functions/reviews/loadreviews'
import OnBoarding from './Onboarding'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Dashboard(props) {
    const [stats, setStats] = useState(null)
    const [loggedin, setLoggedin] = useState(null)
    const [reviews, setReviews] = useState(null)

    useEffect(() => {
        loadStats()
        loadReviews().then((response) => {
            setReviews(response)
        })
    }, [])

    async function loadStats() {
        if (stats !== null) {
            return
        }

        if (await checkIfLoggedin()) {
            let companyid = document.cookie
                .split('; ')
                .find((row) => row.startsWith('companyID'))
                .split('=')[1]
            let key = document.cookie
                .split('; ')
                .find((row) => row.startsWith('key'))
                .split('=')[1]
            let secret = document.cookie
                .split('; ')
                .find((row) => row.startsWith('secret'))
                .split('=')[1]

            let requesttime = Math.floor(Date.now())
            // convert to utf8 bytes
            var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
            hmac.update(requesttime.toString() + key.toString())
            var hash = hmac.finalize()
            // convert to base64
            hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

            const result = await axios.get(
                'https://api.escaped.online/CONSOLE/COMPANY_STATISTICS/?companyID=' +
                    companyid +
                    '&key=' +
                    key +
                    '&hash=' +
                    hash +
                    '&requesttime=' +
                    requesttime,
            )

            console.log(result.data)

            setStats([
                {
                    id: 1,
                    name: 'Jouw kamers bekeken (30 dagen)',
                    stat: result.data.room_viewed + ' keer',
                    icon: eyeIcon,
                    // change: '122',
                    // changeType: 'increase'
                },
                {
                    id: 2,
                    name: 'Doorverwezen naar beschikbaarheid (30 dagen)',
                    stat: result.data.tapped_booking_button + ' keer',
                    icon: shareIcon,
                    // change: '5.4%',
                    // changeType: 'increase'
                },
                {
                    id: 3,
                    name: 'Als favoriete kamer opgeslagen',
                    stat: result.data.marked_as_favorite + ' keer',
                    icon: starIcon,
                    // change: '3.2%',
                    // changeType: 'increase'
                },
                {
                    id: 4,
                    name: 'Als gespeeld gemarkeerd',
                    stat: result.data.marked_as_played + ' keer',
                    icon: checkIcon,
                    // change: '3.2%',
                    // changeType: 'increase'
                },
            ])
        } else {
            setStats([])
        }
    }

    return [
        <OnBoarding />,
        <div className="mt-5 flex max-md:block max-md:w-full max-md:overflow-hidden gap-5">
            <div className={'flex-1 max-md:w-full'}>
                <Stats></Stats>
            </div>
            <div className={'flex-1 max-md:w-full'}>
                <Reviews></Reviews>
            </div>
        </div>,
    ]

    function Stats() {
        if (stats == null) {
            return (
                <div
                    className={'rounded min-h-[30vh] bg-gray-500 animate-pulse'}
                ></div>
            )
        }

        return (
            <div className={''}>
                {stats.map((item) => (
                    <div
                        key={item.value}
                        className="mb-4 relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow sm:px-6 sm:pt-6"
                    >
                        <div className="absolute rounded-md bg-orange-500 p-3">
                            <item.icon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                            />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                            {item.name}
                        </p>
                        <dd className="ml-16 flex items-baseline pb-0 sm:pb-0">
                            <p className="text-2xl font-semibold text-gray-900 m-0 p-0">
                                {item.stat}
                            </p>
                            <p
                                className={classNames(
                                    item.changeType === 'increase'
                                        ? 'text-green-600'
                                        : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold',
                                )}
                            >
                                {/*{item.changeType === 'increase' ? (*/}
                                {/*    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500"*/}
                                {/*                 aria-hidden="true"/>*/}
                                {/*) : (*/}
                                {/*    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500"*/}
                                {/*                   aria-hidden="true"/>*/}
                                {/*)}*/}

                                <span className="sr-only">
                                    {' '}
                                    {item.changeType === 'increase'
                                        ? 'Increased'
                                        : 'Decreased'}{' '}
                                    by{' '}
                                </span>
                                {item.change}
                            </p>
                        </dd>
                    </div>
                ))}
            </div>
        )
    }

    function Reviews() {
        if (reviews == null) {
            return <Loading />
        }

        return (
            <div className="max-lg:w-full h-[calc(100vh-160px)] max-lg:h-auto max-lg:overflow-hidden rounded-lg bg-white overflow-hidden  shadow">
                <h2 className={'fw-bold text-md px-5 py-2'}>Reviews</h2>
                <div className={'p-5 overflow-scroll h-full pb-20 box-border'}>
                    <ul role="list" className="-mb-8">
                        {reviews.length === 0 ? (
                            <div className={'text-center italic opacity-60'}>
                                Er zijn nog geen reviews geplaatst
                            </div>
                        ) : (
                            ''
                        )}
                        {reviews.map((activityItem, activityItemIdx) => (
                            <li>
                                <div className="relative pb-8">
                                    {activityItemIdx !== reviews.length - 1 ? (
                                        <span
                                            className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                                            aria-hidden="true"
                                        />
                                    ) : null}
                                    <div className="relative flex items-start space-x-3">
                                        {activityItem.type === 'comment' ? (
                                            <>
                                                <div className="relative">
                                                    {activityItem.imageUrl ==
                                                        null ||
                                                    activityItem.imageUrl ===
                                                        '' ? (
                                                        <div
                                                            className={
                                                                'flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white'
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faMessage}
                                                                className={
                                                                    'text-black'
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            className={
                                                                'flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white'
                                                            }
                                                            src={
                                                                activityItem.imageUrl
                                                            }
                                                            alt={''}
                                                        />
                                                    )}

                                                    <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                                        {/*<ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>*/}
                                                    </span>
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <div
                                                        className={
                                                            'flex justify-between'
                                                        }
                                                    >
                                                        <div>
                                                            <div className="text-sm">
                                                                <a
                                                                    href={
                                                                        activityItem
                                                                            .person
                                                                            .href
                                                                    }
                                                                    className="font-medium text-gray-900"
                                                                >
                                                                    {
                                                                        activityItem
                                                                            .person
                                                                            .name
                                                                    }
                                                                </a>
                                                            </div>
                                                            <p className="mt-0.5 text-sm text-gray-500">
                                                                Gaf{' '}
                                                                {
                                                                    activityItem.date
                                                                }{' '}
                                                                een review
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                'bg-[#EC6906] font-bold text-xl text-white bg-black block w-9 h-9 text-center rounded-3xl p-1 '
                                                            }
                                                        >
                                                            {activityItem.value}
                                                        </div>
                                                    </div>

                                                    <div className="mt-2 text-sm text-gray-700">
                                                        <p>
                                                            {activityItem.comment.replaceAll(
                                                                '\\',
                                                                '',
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : activityItem.type ===
                                          'assignment' ? (
                                            <>
                                                <div>
                                                    <div className="relative px-1">
                                                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                                            <UserCircleIcon
                                                                className="h-5 w-5 text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="min-w-0 flex-1 py-1.5">
                                                    <div className="text-sm text-gray-500">
                                                        <a
                                                            href={
                                                                activityItem
                                                                    .person.href
                                                            }
                                                            className="font-medium text-gray-900"
                                                        >
                                                            {
                                                                activityItem
                                                                    .person.name
                                                            }
                                                        </a>{' '}
                                                        assigned{' '}
                                                        <a
                                                            href={
                                                                activityItem
                                                                    .assigned
                                                                    .href
                                                            }
                                                            className="font-medium text-gray-900"
                                                        >
                                                            {
                                                                activityItem
                                                                    .assigned
                                                                    .name
                                                            }
                                                        </a>{' '}
                                                        <span className="whitespace-nowrap">
                                                            {activityItem.date}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : activityItem.type === 'tags' ? (
                                            <>
                                                <div>
                                                    <div className="relative px-1">
                                                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                                            <TagIcon
                                                                className="h-5 w-5 text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="min-w-0 flex-1 py-0">
                                                    <div className="text-sm leading-8 text-gray-500">
                                                        <span className="mr-0.5">
                                                            <a
                                                                href={
                                                                    activityItem
                                                                        .person
                                                                        .href
                                                                }
                                                                className="font-medium text-gray-900"
                                                            >
                                                                {
                                                                    activityItem
                                                                        .person
                                                                        .name
                                                                }
                                                            </a>{' '}
                                                            added tags
                                                        </span>{' '}
                                                        <span className="mr-0.5">
                                                            {activityItem.tags.map(
                                                                (tag) => (
                                                                    <Fragment
                                                                        key={
                                                                            tag.name
                                                                        }
                                                                    >
                                                                        <a
                                                                            href={
                                                                                tag.href
                                                                            }
                                                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                                        >
                                                                            <svg
                                                                                className={classNames(
                                                                                    tag.color,
                                                                                    'h-1.5 w-1.5',
                                                                                )}
                                                                                viewBox="0 0 6 6"
                                                                                aria-hidden="true"
                                                                            >
                                                                                <circle
                                                                                    cx={
                                                                                        3
                                                                                    }
                                                                                    cy={
                                                                                        3
                                                                                    }
                                                                                    r={
                                                                                        3
                                                                                    }
                                                                                />
                                                                            </svg>
                                                                            {
                                                                                tag.name
                                                                            }
                                                                        </a>{' '}
                                                                    </Fragment>
                                                                ),
                                                            )}
                                                        </span>
                                                        <span className="whitespace-nowrap">
                                                            {activityItem.date}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default Dashboard
