import { useEffect, useState } from 'react'
import axios from 'axios'
import Loading from './loading'
import checkIfAdmin from '../functions/login/checkifadmin'

function CompanyList() {
    let [companies, setCompanies] = useState([])
    let [loadingCompanies, setLoadingCompanies] = useState(false)
    const [countryCode, setCountryCode] = useState('BE')
    const [admin, setAdmin] = useState(null)

    useEffect(() => {
        if (admin === null) {
            checkIfAdmin().then((response) => {
                setAdmin(response)
                if (response === true) {
                    loadCompanies()
                }
            })
        }
    }, [])

    function openCompany(id) {
        window.location.href = '/location/' + id
    }

    async function loadCompanies() {
        if (companies.length === 0 && !loadingCompanies) {
            // await setLoadingCompanies(true);
            await axios
                .get('https://api.escaped.online/GET/COMPANIES/LIST')
                .then((response) => {
                    setCompanies(response.data)
                    setLoadingCompanies(false)
                })
        }
    }

    async function filterOnCountryCode() {
        let filteredCompanies = []
        // filter companies if countrCode = countryCode
        new Promise((resolve, reject) => {
            companies.forEach((company) => {
                if (company.countryCode.toString() === countryCode.toString()) {
                    filteredCompanies.push(company)
                }
            })
        }).then(setCompanies(filteredCompanies))
    }

    function CompanyListItem(props) {
        console.log(props.company)
        let returnValue = []
        returnValue.push(
            <td className="pl-4">
                <status class={props.company.status}></status>
            </td>,
        )

        // loop through props.company
        for (const [key, value] of Object.entries(props.company)) {
            // if key is not id, hash or salt
            if (
                key !== 'ID' &&
                key !== 'hash' &&
                key !== 'status' &&
                key !== 'countryCode' &&
                key !== 'zip' &&
                key !== 'city' &&
                key !== 'countryCode' &&
                key !== 'last_active'
            ) {
                switch (key) {
                    case 'street':
                        // add key and value to returnValue
                        returnValue.push(
                            <td
                                onClick={() => openCompany(props.company.ID)}
                                key={props.company.email}
                                className="whitespace-nowrap max-w-[20vw] cursor-pointer text-ellipsis overflow-hidden py-4 pl-4 pr-1 text-sm font-medium text-gray-900 "
                                title={
                                    value +
                                    ' ' +
                                    props.company.zip +
                                    ' ' +
                                    props.company.city
                                }
                            >
                                {value +
                                    ' ' +
                                    props.company.zip +
                                    ' ' +
                                    props.company.city}
                            </td>,
                        )
                        break

                    case 'num_rooms':
                        returnValue.push(
                            <td
                                onClick={() => openCompany(props.company.ID)}
                                key={props.company.email}
                                className="whitespace-nowrap max-w-[20vw] text-ellipsis cursor-pointer overflow-hidden py-4 pl-4 pr-1 text-sm font-medium text-gray-900 "
                                title={value}
                            >
                                <span className="circle">{value}</span>
                            </td>,
                        )
                        break

                    default:
                        returnValue.push(
                            <td
                                onClick={() => openCompany(props.company.ID)}
                                key={props.company.email}
                                className="whitespace-nowrap max-w-[20vw] text-ellipsis cursor-pointer overflow-hidden py-4 pl-4 pr-1 text-sm font-medium text-gray-900 "
                                title={value}
                            >
                                {value}
                            </td>,
                        )
                        break
                }
            }
        }

        returnValue.push(
            <td
                onClick={(event) => {
                    duplicateCompany(event, props.company.ID)
                }}
                className="whitespace-nowrap max-w-[20vw] text-ellipsis cursor-pointer overflow-hidden py-4 pl-4 pr-1 text-sm font-medium"
            >
                <span
                    className={
                        'inline-block bg-black text-white rounded-3xl w-6 h-6 pt-0.5 text-center font-bold cursor-pointer'
                    }
                >
                    D
                </span>
            </td>,
        )

        return returnValue
    }

    let returnValue = []
    if (admin) {
        returnValue.push(<Loading></Loading>)
    } else {
        returnValue.push(
            <p className={'text-center'}>
                Je hebt niet de juist rechten om deze pagina te bekijken
            </p>,
        )
    }
    if (companies.length > 0) {
        returnValue = []

        let tableData = companies.map((company) => {
            return (
                <tr
                    className="hover:bg-gray-100 bg-white"
                    status={company.status}
                >
                    <CompanyListItem company={company} />
                </tr>
            )
        })

        returnValue.push(
            <div className="">
                <div className="overflow-x-auto ">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className=" w-[100%] table-auto divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        ></th>
                                        <th
                                            scope="col"
                                            className="px- py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Naam
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Adres
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Website
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Telefoon
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Email
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Rooms
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-0 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {tableData}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>,
        )
    }

    return returnValue
}

function duplicateCompany(event, ID) {
    if (window.confirm('Are you sure you want to copy this company?')) {
        event.preventDefault()
        axios
            .post('https://api.escaped.online/POST/COPY_COMPANY', { ID: ID })
            .then((response) => {
                // refresh page
                window.location.reload()
            })
    }
    event.preventDefault()
}

export default CompanyList
