import React, { useEffect, useState } from 'react'
import InputElement from '././inputElement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCopy } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import ImageUpload from './imageUpload'
import { Audio } from 'react-loader-spinner'
import Flag from 'react-world-flags'

export default function Room(props) {
    let [showPopup, setShowPopup] = useState(false)
    let [roomMeta, setRoomMeta] = useState(false)

    useEffect(() => {
        if (!roomMeta && showPopup) {
            const params = new URLSearchParams([['ID', props.room.ID]])
            axios
                .get('https://api.escaped.online/GET/ROOM_META', { params })
                .then((response) => {
                    setRoomMeta(response.data)
                })
        }
    })

    function openPopup() {
        if (showPopup) {
            // clear meta
            setRoomMeta(false)
        }
        setShowPopup(!showPopup)
    }

    function removeRoom() {
        if (window.confirm('Are you sure you want to delete this room?')) {
            axios
                .post('https://api.escaped.online/POST/DELETE_ROOM', {
                    ID: props.room.ID,
                })
                .then((response) => {
                    //remove clicked element
                    document.getElementById(props.room.ID).remove()
                })
        }
    }

    function copyRoom() {
        if (window.confirm('Are you sure you want to copy this room?')) {
            axios
                .post('https://api.escaped.online/POST/COPY_ROOM', {
                    ID: props.room.ID,
                })
                .then((response) => {
                    // refresh page
                    window.location.reload()
                })
        }
    }

    function copyRoom() {
        if (window.confirm('Are you sure you want to copy this room?')) {
            axios
                .post('https://api.escaped.online/POST/COPY_ROOM', {
                    ID: props.room.ID,
                })
                .then((response) => {
                    // refresh page
                    window.location.reload()
                })
        }
    }

    return (
        <>
            <roomRule
                status={props.status}
                id={props.room.ID}
                title={props.room.status + ': ' + props.room.name}
            >
                <delete onClick={removeRoom}>
                    <FontAwesomeIcon icon={faTrash} title={'delete'} />
                </delete>
                <copy onClick={copyRoom}>
                    <FontAwesomeIcon icon={faCopy} title={'copy'} />
                </copy>
                <status></status>
                <title onClick={openPopup}>{props.title}</title>
            </roomRule>
            {showPopup && <Popup id={props.id} room={props.room} />}
        </>
    )

    function copyUrl() {
        // get focussed input element
        let input = document.activeElement
        // paste clipboard contents into input element if it's a url
        navigator.clipboard.readText().then((clipText) => {
            if (clipText.includes('http')) {
                input.value = clipText
            }
        })
    }

    function Popup(props) {
        let [priceType, setPriceType] = useState(roomMeta.priceType ?? null)
        let [variablePrice, setVariablePrice] = useState(
            roomMeta.variablePrice ?? null,
        )
        let [bookingSystem, setBookingSystem] = useState(
            roomMeta.bookingSystem ?? null,
        )
        let [minPlayers, setMinPlayers] = useState(roomMeta.minPlayers ?? null)
        let [maxPlayers, setMaxPlayers] = useState(roomMeta.maxPlayers ?? null)
        let [bookingSystemApi, setBookingSystemApi] = useState(
            roomMeta.bookingSystemApi ?? null,
        )
        let [bookingSystemSecret, setBookingSystemSecret] = useState(
            roomMeta.bookingSystemSecret ?? null,
        )
        let [language, setLanguage] = useState(roomMeta.language ?? 'NL')

        function switchLanguage(languageNew) {
            setLanguage(languageNew)
        }

        function translate() {
            axios
                .post('https://api.escaped.online/POST/TRANSLATE_ROOM', {
                    ID: props.id,
                })
                .then((response) => {
                    // refresh page
                    window.location.reload()
                })
        }

        if (!roomMeta) {
            return (
                <popup className={'max-md:!w-full'}>
                    <backdrop
                        onClick={openPopup}
                        className={'max-md:!w-full'}
                    ></backdrop>
                    <inner className={'max-md:!w-[95vw]'}>
                        <Audio
                            height="30"
                            width="30"
                            radius="9"
                            color="orange"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                        />
                    </inner>
                </popup>
            )
        }

        return (
            <popup className={'max-md:!w-full'}>
                <backdrop
                    onClick={openPopup}
                    className={'max-md:!w-full'}
                ></backdrop>
                <inner className={'max-md:!w-[95vw]'}>
                    <InputElement
                        type={'singleToggle'}
                        dataType={'room'}
                        room={props.room}
                        value={props.room.status ?? []}
                        label={'status'}
                        variable={'status'}
                        options={['draft', 'checked', 'confirmed', 'published']}
                    />

                    <ImageUpload
                        type={'image'}
                        width={3}
                        height={2}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.image}
                        label={'Room Image'}
                        variable={'image'}
                    />

                    <InputElement
                        dataType={'room'}
                        room={props.room}
                        value={roomMeta.name ?? props.room.name}
                        label={'Room Name'}
                        variable={'name'}
                    />

                    <InputElement
                        type={'date'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.open_since ?? '1970-01-01'}
                        label={'Open since'}
                        variable={'open_since'}
                    />

                    <InputElement
                        type={'date'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.closed_on ?? '1970-01-01'}
                        label={'Closed on'}
                        variable={'closed_on'}
                    />

                    {/*<div className={"languageSwitcher"}>*/}
                    {/*    <a onClick={()=>{switchLanguage("NL")}} className={language === "EN" ? "opacity-30 hover:opacity-100" : ""}><Flag code={ "NL" } /></a>*/}
                    {/*    <a onClick={()=>{switchLanguage("EN")}} className={language === "NL" ? "opacity-30 hover:opacity-100" : ""}><Flag code={ "GB" }/></a>*/}
                    {/*</div>*/}

                    <div
                        rel={'NL'}
                        className={language === 'NL' ? '' : 'hidden'}
                    >
                        <InputElement
                            dataType={'room_meta'}
                            room={props.room}
                            value={roomMeta.short_description}
                            label={'Short Description'}
                            variable={'short_description'}
                            type={'textArea'}
                        />

                        <InputElement
                            dataType={'room_meta'}
                            room={props.room}
                            value={roomMeta.full_description}
                            label={'Full Description'}
                            variable={'full_description'}
                            type={'textArea'}
                        />
                    </div>

                    <div
                        rel={'EN'}
                        className={language === 'EN' ? '' : 'hidden'}
                    >
                        <div
                            noClick={() => {
                                translate(props.id)
                            }}
                            className={'translate'}
                        >
                            <a>Translate from Dutch</a>
                        </div>
                        <InputElement
                            dataType={'room_meta'}
                            room={props.room}
                            value={roomMeta.short_description_en}
                            label={'Short Desc EN'}
                            variable={'short_description_en'}
                            type={'textArea'}
                        />

                        <InputElement
                            dataType={'room_meta'}
                            room={props.room}
                            value={roomMeta.full_description_en}
                            label={'Full Desc EN'}
                            variable={'full_description_en'}
                            type={'textArea'}
                        />
                    </div>

                    <InputElement
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.trailer}
                        label={'Trailer URL'}
                        variable={'trailer'}
                        type={'text'}
                    />

                    <InputElement
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.owner_rating}
                        label={'Owner Rating'}
                        variable={'owner_rating'}
                    />
                    <InputElement
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.escaped_rating}
                        label={'Escaped Rating'}
                        variable={'escaped_rating'}
                    />

                    <InputElement
                        updateState={setMinPlayers}
                        dataType={'room_meta'}
                        room={props.room}
                        label={'Minimum Players'}
                        type={'select'}
                        minValue={1}
                        maxValue={100}
                        variable={'minPlayers'}
                        class={'third'}
                        value={parseInt(minPlayers) ?? ''}
                    />
                    <InputElement
                        updateState={setMaxPlayers}
                        dataType={'room_meta'}
                        room={props.room}
                        type={'select'}
                        minValue={1}
                        maxValue={100}
                        variable={'maxPlayers'}
                        class={'third'}
                        value={parseInt(maxPlayers) ?? ''}
                    />

                    <InputElement
                        label={'Adviced Players'}
                        dataType={'room_meta'}
                        room={props.room}
                        type={'select'}
                        minValue={minPlayers}
                        maxValue={maxPlayers}
                        variable={'recommendedplayers'}
                        value={roomMeta.recommendedplayers ?? ''}
                    />

                    <InputElement
                        label={'Minimum Age'}
                        dataType={'room_meta'}
                        room={props.room}
                        type={'select'}
                        minValue={1}
                        maxValue={21}
                        variable={'minAge'}
                        value={parseInt(roomMeta.minAge) ?? ''}
                    />

                    <InputElement
                        label={'Play Time'}
                        dataType={'room_meta'}
                        room={props.room}
                        type={'select'}
                        minValue={1}
                        maxValue={360}
                        variable={'playTime'}
                        value={parseInt(roomMeta.playTime) ?? ''}
                    />

                    <InputElement
                        label={'Difficulty'}
                        dataType={'room_meta'}
                        room={props.room}
                        type={'select'}
                        minValue={1}
                        maxValue={10}
                        variable={'difficulty'}
                        value={parseInt(roomMeta.difficulty) ?? ''}
                    />

                    <InputElement
                        updateState={setPriceType}
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={priceType}
                        label={'Price Type'}
                        variable={'priceType'}
                        options={['group', 'user']}
                    />

                    <InputElement
                        updateState={setVariablePrice}
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={variablePrice}
                        label={'Variable Price'}
                        variable={'variablePrice'}
                        options={['true', 'false']}
                    />

                    {priceType === 'group' && (
                        <InputElement
                            dataType={'room_meta'}
                            room={props.room}
                            value={
                                isNaN(parseInt(roomMeta.price))
                                    ? 0
                                    : parseInt(roomMeta.price)
                            }
                            label={'Price'}
                            variable={'price'}
                            meta={roomMeta}
                            subValueOptions={
                                variablePrice === 'true'
                                    ? [
                                          'mon',
                                          'tue',
                                          'wed',
                                          'thu',
                                          'fri',
                                          'sat',
                                          'sun',
                                      ]
                                    : null
                            }
                            class={variablePrice === 'true' ? 'day' : ''}
                        />
                    )}

                    {priceType === 'user' && (
                        <InputElement
                            labelAddition={'players'}
                            dataType={'room_meta'}
                            warningIfNoMinMax={
                                'Voer eerst het minimum en maximum aantal gebruikers in'
                            }
                            minValue={minPlayers}
                            maxValue={maxPlayers}
                            subValueOptions={
                                variablePrice === 'true'
                                    ? [
                                          'mon',
                                          'tue',
                                          'wed',
                                          'thu',
                                          'fri',
                                          'sat',
                                          'sun',
                                      ]
                                    : null
                            }
                            inputForEveryValue={true}
                            labelForEveryValue={true}
                            class={variablePrice === 'true' ? 'day' : ''}
                            room={props.room}
                            meta={roomMeta}
                            value={parseInt(roomMeta.price)}
                            label={'Price'}
                            variable={'price'}
                        />
                    )}

                    <InputElement
                        type={'select'}
                        minValue={1}
                        maxValue={100}
                        dataType={'room_meta'}
                        room={props.room}
                        value={parseInt(roomMeta.escapeRate)}
                        label={'Escape rate'}
                        variable={'escapeRate'}
                    />

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.wheelchairs}
                        options={['true', 'false']}
                        label={'Wheelchairs'}
                        variable={'wheelchairs'}
                    />

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.toilet}
                        options={['true', 'false']}
                        label={'Toilet'}
                        variable={'toilet'}
                    />

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.airco}
                        options={['true', 'false']}
                        label={'Air conditioning'}
                        variable={'airco'}
                    />

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.cardPayments}
                        options={['true', 'false']}
                        label={'Pin payments'}
                        variable={'cardPayments'}
                    />

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.pregnants}
                        options={['true', 'false']}
                        label={'Suitable pregnants'}
                        variable={'pregnants'}
                    />

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.epileptics}
                        options={['true', 'false']}
                        label={'Suitable Epileptics'}
                        variable={'epileptics'}
                    />

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.drinks}
                        options={['true', 'false']}
                        label={'Drinks opportunity'}
                        variable={'drinks'}
                    />

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.parking}
                        options={['true', 'false']}
                        label={'Free parking'}
                        variable={'parking'}
                    />

                    <InputElement
                        type={'toggle'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.languages}
                        label={'Languages'}
                        variable={'languages'}
                        options={['dutch', 'english', 'german', 'french']}
                    />

                    <InputElement
                        onFocus={copyUrl}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.bookUrl}
                        label={'Book URL'}
                        variable={'bookUrl'}
                    />

                    <InputElement
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.calendarics}
                        label={'Calendar (.ics)'}
                        variable={'calendarics'}
                    />

                    <InputElement
                        updateState={setBookingSystem}
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.bookingSystem}
                        options={['onlineafspraken.nl', 'other']}
                        label={'Boekingsysteem'}
                        variable={'bookingSystem'}
                    />

                    {bookingSystem === 'onlineafspraken.nl' && [
                        <InputElement
                            updateState={setBookingSystemApi}
                            dataType={'room_meta'}
                            room={props.room}
                            value={bookingSystemApi}
                            label={'API key'}
                            variable={'bookingSystemApi'}
                        />,
                        <InputElement
                            updateState={setBookingSystemSecret}
                            dataType={'room_meta'}
                            room={props.room}
                            value={bookingSystemSecret}
                            label={'API secret'}
                            variable={'bookingSystemSecret'}
                        />,
                        <OnlineAfsprakenAgendas
                            room={props.room}
                            roomMeta={roomMeta}
                            bookingSystemSecret={bookingSystemSecret}
                            bookingSystemApi={bookingSystemApi}
                        />,
                        <OnlineAfsprakenAppointmentTypes
                            room={props.room}
                            roomMeta={roomMeta}
                            bookingSystemSecret={bookingSystemSecret}
                            bookingSystemApi={bookingSystemApi}
                        />,
                    ]}

                    {bookingSystem === 'other' && (
                        <InputElement
                            dataType={'room_meta'}
                            room={props.room}
                            value={roomMeta.bookingSystemCustom}
                            label={'Naam boekingsysteem'}
                            variable={'bookingSystemCustom'}
                        />
                    )}

                    <InputElement
                        type={'select'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.roomType}
                        options={[
                            'EscapeRoom',
                            'Outdoor',
                            'Virtual Reality',
                            'Home Escape',
                        ]}
                        label={'Object Type'}
                        variable={'roomType'}
                    />

                    <InputElement
                        type={'toggle'}
                        dataType={'room_meta'}
                        room={props.room}
                        value={roomMeta.categories ?? []}
                        label={'Categories'}
                        variable={'categories'}
                        options={[
                            'horror',
                            'humor',
                            'crime',
                            'history',
                            'physical',
                            'thriller',
                            'sciencefiction',
                            'love',
                            'experience',
                            'fantasy',
                            'traditional',
                            'actor',
                            'storytelling',
                            'kids',
                            'outdoor',
                            'mysterie',
                            'avontuur',
                            'misdaad',
                            'VR',
                            'Op lokatie',
                            'detective',
                            'battle',
                        ]}
                    />
                </inner>
            </popup>
        )
    }
}

export function OnlineAfsprakenAgendas(props) {
    let [agendas, setAgendas] = useState(false)
    let [agendaNames, setAgendaNames] = useState(false)

    if (props.bookingSystemSecret && props.bookingSystemApi && !agendas) {
        // GET AGENDAS FOR THIS ROOM
        const params = new URLSearchParams([['roomID', props.room.ID]])
        let agendaArray = []
        let agendaNamesArray = []

        axios
            .get(
                'https://api.escaped.online/GET/ONLINEAFSPRAKEN_NL/AGENDAS?roomID=' +
                    props.room.ID.toString(),
            )
            .then((response) => {
                let agendasData = response.data
                for (let i = 0; i < agendasData.length; i++) {
                    agendaArray.push(agendasData[i].Agenda[0]['Id'][0])
                    agendaNamesArray.push(agendasData[i].Agenda[0]['Name'][0])
                }
                setAgendas(agendaArray)
                setAgendaNames(agendaNamesArray)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if (props.bookingSystemSecret && props.bookingSystemApi && agendas) {
        return (
            <InputElement
                type={'select'}
                dataType={'room_meta'}
                room={props.room}
                value={props.roomMeta.calendarID}
                options={agendas}
                optionLabels={agendaNames}
                label={'Agenda'}
                variable={'calendarID'}
            />
        )
    } else {
        return (
            <div className={'warning'}>
                Enter API key and secret to select an Agenda
            </div>
        )
    }
}

export function OnlineAfsprakenAppointmentTypes(props) {
    let [appointmentTypes, setAppointmentTypes] = useState(false)
    let [appointmentTypeNames, setAppointmentTypeNames] = useState(false)

    if (
        props.bookingSystemSecret &&
        props.bookingSystemApi &&
        !appointmentTypes
    ) {
        // GET AGENDAS FOR THIS ROOM
        const params = new URLSearchParams([['roomID', props.room.ID]])
        let appointmentTypesArray = []
        let AppointmentTypeNamesArray = []

        axios
            .get(
                'https://api.escaped.online/GET/ONLINEAFSPRAKEN_NL/APPOINTMENT_TYPES?roomID=' +
                    props.room.ID.toString(),
            )
            .then((response) => {
                let appointmentTypesData = response.data
                for (let i = 0; i < appointmentTypesData.length; i++) {
                    AppointmentTypeNamesArray.push(
                        appointmentTypesData[i].AppointmentType[0]['Name'][0],
                    )
                    appointmentTypesArray.push(
                        appointmentTypesData[i].AppointmentType[0]['Id'][0],
                    )
                }
                setAppointmentTypes(appointmentTypesArray)
                setAppointmentTypeNames(AppointmentTypeNamesArray)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    if (
        props.bookingSystemSecret &&
        props.bookingSystemApi &&
        appointmentTypes
    ) {
        return (
            <InputElement
                type={'select'}
                dataType={'room_meta'}
                room={props.room}
                value={props.roomMeta.appointmentTypeID}
                options={appointmentTypes}
                optionLabels={appointmentTypeNames}
                label={'Appointment Type'}
                variable={'appointmentTypeID'}
            />
        )
    } else {
        return <></>
    }
}
