import showMessage from '../showmessage'
import CryptoJS from 'crypto-js'
import axios from 'axios'

async function sendReviewInvitation(email, message, playedRoom) {
    let companyID = (
        document.cookie
            .split('; ')
            .find((row) => row.startsWith('companyID')) ?? ''
    ).split('=')[1]
    let key = (
        document.cookie.split('; ').find((row) => row.startsWith('key')) ?? ''
    ).split('=')[1]
    let secret = (
        document.cookie.split('; ').find((row) => row.startsWith('secret')) ??
        ''
    ).split('=')[1]

    let requesttime = Math.floor(Date.now())
    // convert to utf8 bytes
    var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
    hmac.update(requesttime.toString() + key.toString())
    var hash = hmac.finalize()
    // convert to base64
    hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

    const result = await axios
        .put('https://api.escaped.online/CONSOLE/REVIEW_INVITE', {
            key: key,
            hash: hash,
            requesttime: requesttime,
            companyID: companyID,
            user_email: email,
            message: message,
            room_id: playedRoom,
        })
        .then((response) => {
            console.log(response)
            // if response.data has error = true, show error message
            if (response.data.error) {
                return false
            } else {
                return true
            }
        })
        .catch((error) => {
            console.log(error)
            return false
        })

    return result
}

export default sendReviewInvitation
