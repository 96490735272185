import React from 'react'

function Back(props) {
    // chevron left icon and text
    return (
        <div
            className={
                'ml-[240px] bg-gray-200 py-3 px-5 hover:bg-orange-100 cursor-pointer'
            }
            onClick={() => {
                window.location.href = props['url']
            }}
        >
            <div className={'icon inline-block align-middle'}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-chevron-left"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#000"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="15 6 9 12 15 18" />
                </svg>
            </div>
            <div className={'text inline-block align-middle'}>Ga terug</div>
        </div>
    )
}

export default Back
