import CryptoJS from 'crypto-js'
import axios from 'axios'

export async function loadInvitationLink(companyid) {
    let adminID = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminID'))
        .split('=')[1]
    let adminKey = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminKey'))
        .split('=')[1]
    let adminSecret = document.cookie
        .split('; ')
        .find((row) => row.startsWith('adminSecret'))
        .split('=')[1]

    let requesttime = Math.floor(Date.now())
    // convert to utf8 bytes
    var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, adminSecret)
    hmac.update(requesttime.toString() + adminKey.toString())
    var hash = hmac.finalize()
    // convert to base64
    hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

    return axios
        .get(
            'https://api.escaped.online/CONSOLE/INVITATION/' +
                companyid +
                '?adminid=' +
                adminID +
                '&key=' +
                adminKey +
                '&hash=' +
                hash +
                '&requesttime=' +
                requesttime,
        )
        .then((response) => {
            if (response.status === 200) {
                return (
                    'https://console.escaped.online/login/' +
                    companyid +
                    '/' +
                    response.data.key +
                    '/' +
                    response.data.secret
                )
            } else {
                return false
            }
        })
}
