import React from 'react'

function SettingsPopup(props) {
    return (
        <div
            className={
                'w-full h-full bg-[rgba(0,0,0,0.7)] fixed top-0 left-0 z-[99999999]'
            }
        >
            <div
                className={
                    'w-[80vw] h-[80vh] mx-[10vw] my-[10vh] rounded items-center shadow-xl bg-white fixed top-0 left-0 z-[9999999] p-5'
                }
            >
                test
            </div>
        </div>
    )
}

export default SettingsPopup
