import * as React from 'react'

export default function Contact() {
    return (
        <div className={'text-center'}>
            <h1 className={'mb-5'}>Contact</h1>
            <p>Heb je vragen of opmerkingen? Neem dan contact met ons op.</p>
            <p>
                Je kunt ons bereiken via: +31 (0)30 833 01 02 of gebruik het
                chat venster rechtsonder
            </p>

            <div className={'mt-5 flex align-top gap-5'}>
                <div className={'min-h-[20px] w-1/3 max-lg:hidden'}></div>
                <div className={'box-border p-3 overflow-hidden block w-1/3'}>
                    <div
                        className={
                            'rounded-[200px] w-full overflow-hidden object-cover shadow shadow-lg mb-5'
                        }
                    >
                        <img
                            src={
                                'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'
                            }
                        />
                    </div>
                    <p>Rob Pronk</p>
                    <em className={'opacity-50'}>Klantsupport & Marketing</em>
                </div>
                <div className={'box-border p-3 overflow-hidden block w-1/3'}>
                    <div
                        className={
                            'rounded-[200px] w-full overflow-hidden object-cover shadow shadow-lg mb-5'
                        }
                    >
                        <img
                            src={
                                'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png'
                            }
                        />
                    </div>
                    <p>Jeanine Hoogeveen</p>
                    <em className={'opacity-50'}>Klantsupport & Content</em>
                </div>
                <div className={'box-border p-3 overflow-hidden block w-1/3'}>
                    <div
                        className={
                            'rounded-[200px] w-full overflow-hidden object-cover shadow shadow-lg mb-5'
                        }
                    >
                        <img
                            src={
                                'https://course-flow.com/wp-content/uploads/2023/04/Screenshot-2019-05-14-at-08.35.55-3.png'
                            }
                        />
                    </div>
                    <p>Jerry Tieben</p>
                    <em className={'opacity-50'}>Technische Support</em>
                </div>
                <div className={'min-h-[20px] w-1/3 max-lg:hidden'}></div>
            </div>
        </div>
    )
}
