import checkIfAdmin from '../functions/login/checkifadmin'
import React, { useEffect, useState } from 'react'
import Loading from './loading'
import { Link } from 'react-router-dom'

export default function Logos() {
    const [admin, setAdmin] = useState(false)
    const [logos, setLogos] = useState([])
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState(null)

    useEffect(() => {
        checkIfAdmin().then((response) => {
            setAdmin(response)
        })
    })

    useEffect(() => {
        if (admin) {
            fetch('https://api.escaped.online/GET/LOGOS')
                .then((response) => response.json())
                .then((data) => setLogos(data))
                .then(setLoading(false))
                .catch((error) => {
                    setMessage(
                        "Er is iets fout gegaan bij het ophalen van de logo's. Probeer het later opnieuw.",
                    )
                    setLoading(false)
                })
        }
    }, [admin])

    if (!admin) {
        return 'You are not an admin.'
    }

    return [
        <h1>Location Logo's</h1>,
        <div className={'grid grid-cols-5 gap-1'}>
            {loading ? <Loading /> : ''}
            {message !== null ? (
                <p className={'text-center mt-5 italic opacity-60'}>
                    {message}
                </p>
            ) : (
                logos.map((logo, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                'flex flex-row bg-white bg-opacity-30 p-2'
                            }
                        >
                            <Link to={'/location/' + logo.company_id}>
                                <img
                                    src={logo.meta_value.small}
                                    alt={logo.company_id}
                                    className={' object-contain'}
                                    loading={'lazy'}
                                />
                            </Link>
                        </div>
                    )
                })
            )}
        </div>,
    ]
}
