import checkIfLoggedin from '../login/checkifloggedin'
import CryptoJS from 'crypto-js'
import axios from 'axios'

async function loadReviews() {
    if (await checkIfLoggedin()) {
        let companyid = document.cookie
            .split('; ')
            .find((row) => row.startsWith('companyID'))
            .split('=')[1]
        let key = document.cookie
            .split('; ')
            .find((row) => row.startsWith('key'))
            .split('=')[1]
        let secret = document.cookie
            .split('; ')
            .find((row) => row.startsWith('secret'))
            .split('=')[1]

        let requesttime = Math.floor(Date.now())
        // convert to utf8 bytes
        var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret)
        hmac.update(requesttime.toString() + key.toString())
        var hash = hmac.finalize()
        // convert to base64
        hash = encodeURIComponent(hash.toString(CryptoJS.enc.Base64))

        const result = await axios.get(
            'https://api.escaped.online/CONSOLE/COMPANY_REVIEWS/?companyID=' +
                companyid +
                '&key=' +
                key +
                '&hash=' +
                hash +
                '&requesttime=' +
                requesttime,
        )

        let allReviews = []
        if (result.data.length > 0) {
            for (let i = 0; i < result.data.length; i++) {
                const data = result.data[i]

                const timeAgo = () => {
                    // get total seconds between timestamp (data.datetime) and now
                    let seconds = Math.floor(
                        (new Date() - new Date(data.datetime)) / 1000,
                    )

                    let interval = seconds / 31536000

                    if (interval > 1) {
                        return Math.floor(interval) + ' jaar geleden'
                    }
                    interval = seconds / 2592000
                    if (interval > 1) {
                        return Math.floor(interval) + ' maand geleden'
                    }
                    interval = seconds / 86400
                    if (interval > 1) {
                        return Math.floor(interval) + ' dagen geleden'
                    }
                    interval = seconds / 3600
                    if (interval > 1) {
                        return Math.floor(interval) + ' uur geleden'
                    }
                    interval = seconds / 60
                    if (interval > 1) {
                        return Math.floor(interval) + ' minuten geleden'
                    }
                    return Math.floor(seconds) + ' seconds'
                }

                const avatar =
                    data.avatar != null
                        ? JSON.parse(data.avatar.replaceAll('\\', ''))['small']
                        : null

                const review = {
                    id: data.ID,
                    value: data.value,
                    type: 'comment',
                    person: { name: data.nickname ?? 'Anoniem', href: '#' },
                    imageUrl: avatar,
                    comment: (
                        data.content ?? 'Reviewer heeft geen tekst geschreven'
                    ).replaceAll('\\n', '\n'),
                    // calculate minutes, hours or days ago
                    date: timeAgo().toString(),
                    status: data.status,
                }
                allReviews.push(review)
            }
            return allReviews
        } else {
            return []
        }
    } else {
        return []
    }
}

export default loadReviews
