import { useEffect, useState } from 'react'
import axios from 'axios'
import { FaPlus } from 'react-icons/fa'
import Loading from './loading'
import checkIfAdmin from '../functions/login/checkifadmin'
import { openai } from 'chatgpt'
import { loadInvitationLink } from '../functions/companyLoginLink'

function RoomList(props) {
    let [rooms, setRooms] = useState([])
    const [roomID, setRoomID] = useState(0)
    const [companyHash, setCompanyHash] = useState(props.companyHash ?? null)
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState(null)
    const [admin, setAdmin] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [filteredRooms, setFilteredRooms] = useState([])

    useEffect(() => {
        if (admin == null) {
            checkIfAdmin().then(async (response) => {
                setAdmin(response)
                if (response === true) {
                    console.log(process.env.API_URL ?? 'http://localhost:8080')
                    axios
                        .get('https://api.escaped.online/GET/ROOMS')
                        .then((response) => {
                            // set rooms where company_id = companyHash
                            if (companyHash !== null) {
                                rooms = response.data.filter(
                                    (room) =>
                                        room.company_id.toString() ===
                                        companyHash.toString(),
                                )
                                setRooms(rooms)
                                setFilteredRooms(rooms)
                            } else {
                                setRooms(response.data)
                                setFilteredRooms(response.data)
                            }
                        })
                        .catch((error) => {
                            setLoading(false)
                            setMessage(
                                'Er is iets fout gegaan bij het ophalen van de kamers. Probeer het later opnieuw.',
                            )
                        })
                }
            })
        }
    }, [])

    const Search = () => {
        return (
            <div className="search mb-10" id={'searchfield'}>
                <input
                    type="text"
                    defaultValue={searchValue}
                    placeholder="Zoek een kamer"
                    className={
                        'p-3 rounded-xl w-8/12 shadow-inner shadow-gray-600 outline-none'
                    }
                    onChange={(event) => {
                        FilterRooms(event.target.value)
                    }}
                />
            </div>
        )
    }

    return (
        <div className="rooms">
            {Search()}
            <Rooms admin={admin} rooms={filteredRooms}></Rooms>
        </div>
    )

    function Room(props) {
        async function switchToCompany() {
            const loginUrl = await loadInvitationLink(props.room.company_id)
            window.location.href = loginUrl
        }

        if (props.room === null) {
        } else {
            let image =
                props.room.image != null
                    ? JSON.parse(props.room.image.replaceAll(/\\/g, ''))
                    : ''

            return (
                <div className={'room relative'}>
                    <div
                        onClick={openRoom}
                        className={'image relative'}
                        style={{
                            backgroundImage: "url('" + image['small'] + "')",
                        }}
                    >
                        <div
                            className={
                                'title absolute bottom-0 left-0 w-full bg-black text-white bg-opacity-40 backdrop-blur'
                            }
                        >
                            {props.room.name ?? ''}
                        </div>
                        {props.room.availability ? (
                            <div
                                className={
                                    'availability absolute top-3 right-3 bg-primary-1000 shadow-md text-white px-2 py-1 z-50 text-xs rounded-md'
                                }
                            >
                                Live ICS
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    <div className={'px-2 py-2 text-left w-full mb-10 text-sm'}>
                        <p className={'text-ellipsis line-clamp-4 opacity-60'}>
                            {props.room.description.replaceAll('\\n', ' ')}
                        </p>
                    </div>
                    <div
                        className={'absolute bottom-0.5 w-full justify-between'}
                    >
                        <a
                            onClick={switchToCompany}
                            className={
                                ' px-2 text-sm hover:underline opacity-30 hover:opacity-100'
                            }
                        >
                            Overschakelen
                        </a>
                        <a
                            onClick={openRoom}
                            className={
                                ' px-2 text-sm hover:underline opacity-30 hover:opacity-100'
                            }
                        >
                            Bewerken
                        </a>
                    </div>
                    {props.room.averageRating != null &&
                    props.room.averageRating !== '0' ? (
                        <div
                            className={
                                'absolute top-0 left-0 bg-black bg-opacity-60 backdrop-blur text-white p-2 px-3 z-50 font-bold text-lg rounded-br'
                            }
                        >
                            {props.room.averageRating ?? ''}
                        </div>
                    ) : (
                        <></>
                    )}
                    {/*<div onClick={()=>calculateOnlineRating(props.room.name)} className={"opacity-60 absolute top-0 right-0 bg-white text-black font-bold p-3 z-50 text-lg rounded-bl hover:opacity-100"}>{props.room.averageRatingOnline  ?? '?'}</div>*/}
                </div>
            )
        }

        function openRoom() {
            setRoomID(props.room.ID)
            // setOpenSidebar(true);
            window.open('/location/' + props.room.company_id)
        }

        async function calculateOnlineRating(roomname) {
            // ask the chatbot for the rating of the room
            let response = await axios
                .post(
                    'https://api.openai.com/v1/completions',
                    {
                        prompt:
                            'Zoek op bing naar de escape room ' +
                            roomname +
                            ' en zoek naar gemiddelde beoordeling op websites zoals escapetalk  escaperoomsnederland en tripadvisor. Geef de cijfers per bron, de url van de bron, het aantal beoordelingen en een gemiddelde terug in JSON format',
                        max_tokens: 150,
                        temperature: 0.9,
                        model: 'gpt-3.5-turbo-instruct',
                    },
                    {
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                'Bearer ' +
                                process.env.REACT_APP_OPENAI_API_KEY,
                        },
                    },
                )
                .then((response) => {
                    alert(response.data.choices[0].text)
                })
        }
    }

    function FilterRooms(value) {
        if (value === '') {
            setFilteredRooms(rooms)
        } else {
            setSearchValue(value)
            setFilteredRooms(
                rooms.filter((room) =>
                    room.name.toLowerCase().includes(value.toLowerCase()),
                ),
            )
        }
        // set focus on search input
        document.getElementById('searchfield').focus()
    }

    function Rooms(props) {
        let rooms = props.rooms
        let returnValue = []
        if (rooms.length > 0) {
            rooms.map((room) => {
                returnValue.push(
                    <Room admin={props.admin ?? false} room={room} />,
                )
            })
        } else if (admin) {
            returnValue.push(loading ? <Loading /> : '<p>' + message + '</p>')
        } else {
            returnValue.push(
                <p>Je hebt niet de juist rechten om deze pagina te bekijken</p>,
            )
        }

        if (companyHash != null) {
            returnValue.push(<Room room={null} />)
        }

        return returnValue
    }
}

export default RoomList
